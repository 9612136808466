import { SvgProps } from '@@types/CommonTypes';

import { getSvgSize } from '@utils/helpers';

export const IconClients = ({ size }: SvgProps) => (
    <svg {...getSvgSize(24, 24, size)} fill="none">
        <path
            d="M19.867 19.44v.533h.533v-.533h-.533Zm-.534-1.6v1.6H20.4v-1.6h-1.067Zm.534.837h-2.069v1.296h2.069v-1.296Zm-2.667-2.97a2.133 2.133 0 0 1 2.133 2.133H20.4a3.2 3.2 0 0 0-3.2-3.2v1.067Zm-.533-3.2a1.6 1.6 0 0 1-1.6-1.6H14a2.667 2.667 0 0 0 2.667 2.666v-1.066Zm1.6-1.6a1.6 1.6 0 0 1-1.6 1.6v1.066a2.667 2.667 0 0 0 2.666-2.666h-1.066Zm-1.6-1.6a1.6 1.6 0 0 1 1.6 1.6h1.066a2.667 2.667 0 0 0-2.666-2.667v1.067Zm0-1.067A2.667 2.667 0 0 0 14 10.907h1.067a1.6 1.6 0 0 1 1.6-1.6V8.24Z"
            fill="#fff"
        />
        <path
            d="M20.4 19.973h-2.602v-1.296h1.535v-.837a2.133 2.133 0 0 0-2.133-2.133V14.64a3.2 3.2 0 0 1 3.2 3.2v2.133Z"
            fill="#fff"
        />
        <path
            d="M19.867 19.44v.533m0-.533h.533m-.533 0h-.534m.534 0v-.764m0 1.297h.533v-.533m-.533.533h-2.069v-1.296m2.069 1.296v-1.296m.533.763h-1.067m1.067 0v-1.6m-1.067 0v1.6m0-1.6H20.4m-1.067 0a2.133 2.133 0 0 0-2.133-2.133V14.64a3.2 3.2 0 0 1 3.2 3.2m-1.067 0v.836m0 .764v-.764m.534 0h-2.069m2.069 0h-.534m-1.535 0h1.535m-2.666-6.17a1.6 1.6 0 0 1-1.6-1.6m1.6 1.6v1.067m0-1.066a1.6 1.6 0 0 0 1.6-1.6m-3.2 0H14m1.067 0a1.6 1.6 0 0 1 1.6-1.6M14 10.907a2.667 2.667 0 0 0 2.667 2.666M14 10.907a2.667 2.667 0 0 1 2.667-2.667m0 5.333a2.667 2.667 0 0 0 2.666-2.666m-1.066 0h1.066m-1.066 0a1.6 1.6 0 0 0-1.6-1.6m2.666 1.6a2.667 2.667 0 0 0-2.666-2.667m0 1.067V8.24"
            stroke="#13161B"
            strokeWidth={0.05}
        />
        <path
            d="M16.317 19.35H3.651c.021-1.184.329-2.718 1.11-4.015.897-1.492 2.423-2.685 4.968-2.685 2.55 0 4.178 1.2 5.193 2.712.866 1.29 1.282 2.809 1.395 3.988Z"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth={1.3}
        />
        <circle cx={10.016} cy={7.5} r={2.85} stroke="#fff" strokeWidth={1.3} />
    </svg>
);
