import { SortingRule } from 'react-table';

import {
    Button,
    DefaultManualTable,
    SelectionSearchFilter,
} from '@speeki/global-ui-components';

import { TagsTableEmptyState } from '@components/molecules/TagsTableEmptyState/TagsTableEmptyState';
import { GroupTagTableRow } from '@components/organisms/TagsTable/TagsTable.settings';
import {
    TagFilters,
    useTagsTable,
} from '@components/organisms/TagsTable/useTagsTable';

import {
    GroupTagRowSortFields,
    GroupTagRowSortInput,
} from '@graphql/generated/graphql';

import { ChildTagDeleteModal } from '@domains/settings/organisms/ChildTagDeleteModal/ChildTagDeleteModal';
import { ChildTagEditModal } from '@domains/settings/organisms/ChildTagEditModal/ChildTagEditModal';
import { GroupTagDeleteModal } from '@domains/settings/organisms/GroupTagModal/GroupTagDeleteModal/GroupTagDeleteModal';
import { GroupTagEditModal } from '@domains/settings/organisms/GroupTagModal/GroupTagEditModal/GroupTagEditModal';
import { NewTagAddModal } from '@domains/settings/organisms/NewTagAddModal/NewTagAddModal';

import {
    ActionWrapper,
    TagsSearchField,
    TagsSearchFilterWrapper,
    TagsTableWrapper,
} from './TagsTable.styled';

export interface TagsFields {
    label: string;
    name: keyof TagFilters;
    options: { key: string; label: string }[];
}

type SortKeys = Exclude<
    keyof GroupTagTableRow,
    'id' | 'colorPrimary' | 'colorSecondary'
>;

const parsedSortKeys: Record<SortKeys, GroupTagRowSortFields> = {
    groupTag: 'GROUP_TAG_NAME',
    riskArea: 'RISK_AREA_NAME',
    tag: 'TAG_NAME',
};

const getQuerySorting = (
    sort: SortingRule<GroupTagTableRow>[],
): GroupTagRowSortInput[] =>
    sort.map((item) => ({
        direction: item.desc ? 'DESC' : 'ASC',
        field: parsedSortKeys[item.id as SortKeys],
    }));

export const TagsTable = () => {
    const {
        called,
        debouncedSetFiltersSearchPhrase,
        filters,
        filtersData,
        filtersLoading,
        getGroupTagsData,
        groupTagDeleteModal,
        groupTagEditModal,
        groupTagsRowsData,
        groupTagsRowsLoading,
        headerData,
        newTagAddModal,
        selectedCategory,
        setFilterSearchPhrase,
        setFilters,
        setQueryOptionsFilters,
        setSelectedCategory,
        setTableSearchPhrase,
        setTableSearchValue,
        tableSearchPhrase,
        tableSearchValue,
        tagDeleteModal,
        tagEditModal,
        transformedData,
    } = useTagsTable();

    return (
        <>
            <TagsSearchFilterWrapper>
                <TagsSearchField
                    onChange={setTableSearchValue}
                    onReset={() => {
                        setTableSearchPhrase('');
                        setTableSearchValue('');
                    }}
                    onSearch={setTableSearchPhrase}
                    placeholder={'Search...'}
                    sizeType="default"
                    testId="cNGARnD"
                    value={tableSearchValue}
                    variant={'secondary'}
                />
                <ActionWrapper>
                    <SelectionSearchFilter
                        fields={filtersData}
                        filters={filters}
                        loading={filtersLoading}
                        onInputChange={(value) =>
                            debouncedSetFiltersSearchPhrase(value)
                        }
                        selectedCategory={selectedCategory}
                        setFilters={setFilters}
                        setQueryOptionsFilters={setQueryOptionsFilters}
                        setSearchPhrase={setFilterSearchPhrase}
                        setSelectedCategory={setSelectedCategory}
                        testId="ANv_CwP"
                    />
                    <Button
                        iconProps={{
                            icon: 'SvgAddNew24',
                            size: 24,
                            themeColors: {
                                default: {
                                    'color-1': 'secondary.default',
                                },
                            },
                        }}
                        minWidth={137}
                        onClick={() => newTagAddModal.setOpen(true)}
                        size={'small'}
                        testId="zmTkr23"
                        variant={'secondary'}
                    >
                        Add new
                    </Button>
                </ActionWrapper>
            </TagsSearchFilterWrapper>
            <TagsTableWrapper>
                <DefaultManualTable<GroupTagTableRow>
                    columns={headerData}
                    data={transformedData}
                    pagination={{
                        options: {
                            pageSelect: true,
                            totalCount:
                                groupTagsRowsData?.groupTagsRows.totalCount ||
                                0,
                        },
                    }}
                    tableHeaderRow={{
                        testId: 'rsAX_fv',
                    }}
                    tableState={{
                        emptyState: (
                            <TagsTableEmptyState
                                filters={{
                                    default: {
                                        ...filters,
                                    },
                                }}
                            />
                        ),
                        filters,
                        getQuerySorting,
                        getTableData: (props) =>
                            void getGroupTagsData({
                                variables: {
                                    input: {
                                        groupTags: filters.groupTagName,
                                        includeEmptySpeekiGroupTags: false,
                                        paging: props.paging,
                                        riskAreasUUIDs: filters.riskArea,
                                        search: props.searchPhrase,
                                        sorting:
                                            props.sorting as GroupTagRowSortInput[],
                                        standardTagsOnly: false,
                                        tags: filters.tags,
                                    },
                                },
                            }),
                        hiddenColumns: ['id'],
                        loading: groupTagsRowsLoading || !called,
                        searchPhrase: tableSearchPhrase,
                        skeletonVariant: 'inverted',
                    }}
                    testId="OoAYr2E"
                />
            </TagsTableWrapper>

            {newTagAddModal.open && <NewTagAddModal {...newTagAddModal} />}

            {groupTagEditModal.open && (
                <GroupTagEditModal
                    {...groupTagEditModal}
                    openWarningModal={() => groupTagDeleteModal.setOpen(true)}
                />
            )}
            {groupTagDeleteModal.open && (
                <GroupTagDeleteModal
                    {...groupTagDeleteModal}
                    closeEditModal={() => groupTagEditModal.setOpen(false)}
                />
            )}

            {tagEditModal.open && (
                <ChildTagEditModal
                    {...tagEditModal}
                    openWarningModal={() => tagDeleteModal.setOpen(true)}
                />
            )}
            {tagDeleteModal.open && (
                <ChildTagDeleteModal
                    {...tagDeleteModal}
                    closeEditModal={() => tagEditModal.setOpen(false)}
                />
            )}
        </>
    );
};
