import { ReactNode, useEffect } from 'react';

import { useFeatureFlagsStatus } from '@speeki/feature-flags';
import { StorageManager } from '@speeki/storage-manager';

import { AuthLayoutSkeleton } from '@components/templates/AuthLayout/AuthLayoutSkeleton';
import { MainLayoutSkeleton } from '@components/templates/MainLayout/MainLayoutSkeleton';

import { sentry } from '@services/Sentry';

interface AppLoadingProps {
    children: ReactNode;
}

/**
 * Add to this component only async stuff that is required for the proper working of the app.
 */
export const AppLoading = ({ children }: AppLoadingProps) => {
    const { flagsReady } = useFeatureFlagsStatus();

    const sessionToken = StorageManager.getValue('graphSessionToken');
    const refreshToken = StorageManager.getValue('graphRefreshToken');

    const loading = !flagsReady;
    const userSession = sessionToken && refreshToken;

    useEffect(() => {
        void sentry.setUser();
    }, []);

    if (loading)
        return userSession ? <MainLayoutSkeleton /> : <AuthLayoutSkeleton />;

    return <>{children}</>;
};
