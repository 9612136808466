import styled from 'styled-components';
import { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

import { DividedFieldRow as DividedFieldRowElement } from '@components/ions/Form.styled';

export const DividedFieldRow = styled(DividedFieldRowElement)(
    ({ theme: { palette } }) => css`
        border-bottom: 1px solid ${palette.neutral.dividers};
        padding-bottom: 24px;
    `,
);

export const TagFieldsWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const SwitchWrapper = styled.div<{ addNewTags: boolean }>(
    ({ addNewTags }) => css`
        margin-top: 16px;
        display: flex;
        gap: 16px;
        justify-content: flex-start;

        ${addNewTags &&
        css`
            margin-bottom: 32px;
        `}
    `,
);

export const SwitchText = styled(Typography.TextBody_14_20)<{
    disabled: boolean;
}>(
    ({ disabled, theme: { palette } }) => css`
        ${disabled &&
        css`
            color: ${palette.text.assistive};
        `}
    `,
);
