import { Button, Modal, ModalComponent } from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

interface DisableCustomStandardModalProps extends UseModal {
    onClose?: () => void;
    onConfirm: () => void;
}

export const DisableCustomStandardModal = ({
    onClose,
    onConfirm,
    open,
    setOpen,
}: DisableCustomStandardModalProps) => {
    const handleClose = () => {
        onClose?.();
        setOpen(false);
    };

    const handleRedirect = () => {
        onConfirm();
        handleClose();
    };

    return (
        <ModalComponent
            onClose={handleClose}
            open={open}
            preventCloseEsc
            preventCloseOutside
            width={648}
        >
            <Modal.Content>
                <Modal.TitleSecondary>{'Are you sure?'}</Modal.TitleSecondary>
                <Modal.SubTitle color="black">
                    Are you sure you want to disable this Standard? Please
                    inform the client about this change, as it will impact the
                    existing tags associated with this Standard.
                </Modal.SubTitle>
            </Modal.Content>
            <Modal.Footer>
                <Modal.ButtonsWrapper>
                    <Button
                        onClick={onClose}
                        size={'small'}
                        testId="wRN5xBf"
                        variant={'secondary'}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleRedirect}
                        size={'small'}
                        testId="DyanSgA"
                        variant={'primary'}
                    >
                        Confirm
                    </Button>
                </Modal.ButtonsWrapper>
            </Modal.Footer>
        </ModalComponent>
    );
};
