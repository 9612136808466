import { Column } from 'react-table';

import { TableCellText } from '@domains/settings/ions/Table.styled';

export interface BlockedDomainsCardTableRow {
    domain: {
        enabled: boolean;
        text: string;
    };
    id: string;
}

export const blockedDomainsHeaderData: Column<BlockedDomainsCardTableRow>[] = [
    {
        Cell: ({ value: { enabled, text } }) => (
            <TableCellText disabled={!enabled}>{text}</TableCellText>
        ),
        Header: 'Domain name',
        accessor: 'domain',
        disableSortBy: true,
    },
];
