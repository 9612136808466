import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import settings from './settings/reducer';

const settingsReducer = persistReducer({ key: 'settings', storage }, settings);

export const rootReducer = combineReducers({
    settings: settingsReducer,
});
