import { useLocation } from 'react-router-dom';

import { EmailSent } from '@components/svgs/EmailSent';
import { AuthLayout } from '@components/templates/AuthLayout/AuthLayout';

import {
    EmailSentImageWrapper,
    EmailSentPageWrapper,
    EmailSentText,
    EmailSentTitle,
} from './PasswordResetEmailPage.styled';

interface Location {
    email: string;
}
export const PasswordResetEmailPage = () => {
    const location = useLocation();
    const state = location.state as Location;

    return (
        <AuthLayout>
            <EmailSentPageWrapper>
                <EmailSentImageWrapper>
                    <EmailSent />
                </EmailSentImageWrapper>
                <EmailSentTitle>
                    Email has been sent to {state?.email ?? ''}
                </EmailSentTitle>
                <EmailSentText>
                    Please check your inbox and follow the instructions on the
                    email to activate your account.
                </EmailSentText>
                <EmailSentText>
                    Did you not receive an email? <br />
                    Check the junk mail folder or try again.
                </EmailSentText>
            </EmailSentPageWrapper>
        </AuthLayout>
    );
};
