import { useState } from 'react';

import { PaginatedSelectSelectFieldProps } from '@speeki/global-ui-components';

import {
    Esg_Subcategory,
    StandardSectorsDocument,
} from '@graphql/generated/graphql';

import { useLazyQuery } from '@apollo/client';
import { ERROR_MESSAGES } from '@shared/notifyMessages';

interface StandardSectorsSelectOptions {
    riskAreaName: Esg_Subcategory | undefined;
}

export const useStandardSectorsSelect = ({
    riskAreaName,
}: StandardSectorsSelectOptions) => {
    const [noResultsText, setResultsText] = useState<string | undefined>(
        undefined,
    );

    const [getSectors] = useLazyQuery(StandardSectorsDocument, {
        fetchPolicy: 'cache-and-network',
    });

    const loadOptions: PaginatedSelectSelectFieldProps['loadOptions'] = async (
        _filter,
        _,
        additional,
    ) => {
        if (!riskAreaName) return { hasMore: false, options: [] };

        const offset = additional?.offset ? additional.offset + 10 : 1;

        try {
            const { data, error } = await getSectors({
                variables: {
                    input: {
                        paging: {
                            limit: 10,
                            offset: offset ?? 1,
                        },
                        withUnits: true,
                    },
                    riskAreaName,
                },
            });

            !!error
                ? setResultsText(ERROR_MESSAGES.SELECT_FETCH)
                : setResultsText(undefined);

            const { nodes, pageInfo } = data?.standardSectors.sectors || {};

            const options =
                nodes?.map((node) => ({
                    label: node.name,
                    value: node.uuid,
                })) || [];

            return error
                ? { hasMore: false, options: [] }
                : {
                      additional: {
                          offset,
                      },
                      hasMore: pageInfo?.hasNextPage,
                      options,
                  };
        } catch {
            setResultsText(ERROR_MESSAGES.SELECT_FETCH);

            return {
                hasMore: false,
                options: [],
            };
        }
    };

    return {
        debounceTimeout: 200,
        loadOptions,
        noResultsText,
    };
};
