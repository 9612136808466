import { ElineNumbersTableRow } from '../ELineNumbersTable/ELineNumbersTable.settings';
import { useController, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
    Button,
    Modal,
    ModalComponent,
    PhoneNumberField,
    SelectField,
} from '@speeki/global-ui-components';
import { notify } from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import { DividedFieldRow, FormWrapper } from '@components/ions/Form.styled';

import {
    ClientELineNumbersDocument,
    ELineNumberUpsertDocument,
    TenantsCountry,
} from '@graphql/generated/graphql';

import { countriesOptions } from '@utils/dictionary';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_VALIDATION_MESSAGES } from '@shared/formValidationMessages';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';
import { requiredSelect } from '@shared/validations';

interface ElineNumbersUpsertModal extends UseModal {
    defaultValues?: ELineNumbersUpsertFormValues;
    editedItem?: ElineNumbersTableRow;
    setEditedItem: (item: ElineNumbersTableRow | undefined) => void;
}

const EMPTY_ITEM = {
    country: undefined,
    phone: {
        phoneNumber: undefined,
        prefix: undefined,
    },
};

export const ELineNumbersUpsertModal = ({
    defaultValues,
    editedItem,
    open,
    setEditedItem,
    setOpen,
}: ElineNumbersUpsertModal) => {
    const { clientId } = useParams<{ clientId: string }>();

    const {
        control,
        formState: { errors, isDirty, isValid },
        handleSubmit,
        reset,
    } = useForm<ELineNumbersUpsertFormValues>({
        defaultValues: defaultValues ?? EMPTY_ITEM,
        mode: 'onChange',
        resolver: yupResolver(ELineNumbersUpsertSchema),
    });

    const countryField = useController({
        control,
        name: 'country',
    });

    const phoneNumberField = useController({
        control,
        name: 'phone',
    });

    const handleClose = () => {
        editedItem && setEditedItem(undefined);
        reset();
        setOpen(false);
    };

    const [upsertNumber, { loading: upsertLoading }] = useMutation(
        ELineNumberUpsertDocument,
        {
            context: { notifyOnError: true },
            onCompleted: () => {
                notify(
                    SUCCESS_MESSAGES[
                        editedItem
                            ? 'ELINE_NUMBER_UPDATED'
                            : 'ELINE_NUMBER_ADDED'
                    ],
                ).SUCCESS();
                handleClose();
            },
            onError: () => {},
            refetchQueries: [ClientELineNumbersDocument],
        },
    );

    const onSubmit = (values: ELineNumbersUpsertFormValues) => {
        void upsertNumber({
            variables: {
                input: {
                    uuid: editedItem?.id,
                    assignedCountry: values.country?.value as TenantsCountry,
                    number: `+${values.phone.prefix}${values.phone.phoneNumber}`,
                    tenantUuid: clientId ?? '',
                },
            },
        });
    };

    const saveButtonDisabled =
        upsertLoading || !isValid || (!isDirty && !!defaultValues);

    const prefixValue = phoneNumberField.field.value.prefix;
    const numberValue = phoneNumberField.field.value.phoneNumber;

    const parsedCountriesOptions = countriesOptions.filter(
        (countries) => countries.value !== 'ALL',
    );

    const phoneNumberError =
        !!errors.phone?.phoneNumber && typeof numberValue !== 'undefined';
    const prefixError =
        !!errors.phone?.prefix && typeof prefixValue !== 'undefined';

    return (
        <ModalComponent onClose={handleClose} open={open} width={984}>
            <Modal.Wrapper>
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>
                            {defaultValues
                                ? 'Edit phone number details'
                                : 'Add new phone number'}
                        </Modal.Title>
                        <Modal.SubTitle>
                            {defaultValues
                                ? 'Edit phone number and country.'
                                : 'Add a new phone number and select a country.'}
                        </Modal.SubTitle>
                    </Modal.Header>
                    <FormWrapper>
                        <DividedFieldRow>
                            <PhoneNumberField
                                {...phoneNumberField.field}
                                error={{
                                    phoneNumber: phoneNumberError,
                                    prefix: prefixError,
                                }}
                                label={'Phone number'}
                                message={
                                    phoneNumberError || prefixError
                                        ? errors.phone?.prefix?.message ||
                                          errors.phone?.phoneNumber?.message
                                        : undefined
                                }
                                placeholder={{
                                    phoneNumber: 'Enter phone number',
                                    prefix: '000',
                                }}
                                testId="1E6pTyD"
                            />
                            <SelectField
                                {...countryField.field}
                                error={!!errors.country}
                                label={'Country'}
                                message={errors.country?.message}
                                options={parsedCountriesOptions}
                                placeholder={'Select country'}
                                testId="select_country"
                            />
                        </DividedFieldRow>
                    </FormWrapper>
                </Modal.Content>
                <Modal.Footer>
                    <Modal.ButtonsWrapper>
                        <Button
                            onClick={handleClose}
                            size={'small'}
                            testId="9gYsmR1"
                            variant={'secondary'}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={saveButtonDisabled}
                            isLoading={upsertLoading}
                            onClick={handleSubmit(onSubmit)}
                            size={'small'}
                            testId="1UW-P1W"
                            variant={'primary'}
                        >
                            Save
                        </Button>
                    </Modal.ButtonsWrapper>
                </Modal.Footer>
            </Modal.Wrapper>
        </ModalComponent>
    );
};

const ELineNumbersUpsertSchema = yup.object({
    country: requiredSelect,
    phone: yup
        .object({
            phoneNumber: yup
                .string()
                .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
            prefix: yup
                .string()
                .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
        })
        .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
});

export type ELineNumbersUpsertFormValues = yup.InferType<
    typeof ELineNumbersUpsertSchema
>;
