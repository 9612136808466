import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const ActionsWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 24px;
    justify-content: flex-end;
`;

export const TableCellText = styled(Typography.TextBody_16_24)<{
    disabled: boolean;
}>(
    ({ disabled, theme: { colors } }) => css`
        ${disabled &&
        css`
            color: ${colors.text_assistive};
        `}
    `,
);
