import { notify } from '@speeki/global-ui-components';

import { ApolloError, OperationVariables } from '@apollo/client';

interface BatchUpdateOptions<T extends OperationVariables> {
    errorCode: string;
    notification?: string | string[];
    onError?: (data: BatchUpdateErrors<T> | BatchUpdateErrorsV2<T>) => void;
}

interface BatchUpdateErrors<T extends OperationVariables> {
    data?: T;
    errors?: {
        code: string;
        message: string;
    }[];
}

interface BatchUpdateErrorItemV2 {
    extensions: {
        code: string;
    };
    message: string;
}

interface BatchUpdateErrorsV2<T extends OperationVariables> {
    data?: T;
    errors?: BatchUpdateErrorItemV2[];
}

/**
 * This is V2 of handleBatchError, because backend changed the data structure that is being returned when error occurs
 * @param errorCode - name of error the function looks for
 * @param notification - error notification string
 * @param onError - callback function that run while errorCode is found
 *
 * @returns
 * - `data` - data provided in graphql operation
 * - `errors` - all errors returned from operation
 */

export const handleBatchErrorV2 =
    <T extends OperationVariables>({
        errorCode,
        notification,
        onError,
    }: BatchUpdateOptions<T>) =>
    (error: ApolloError) => {
        error.graphQLErrors.forEach(({ extensions }) => {
            // @ts-expect-error FIXME
            extensions?.variables?.forEach((item: BatchUpdateErrorsV2<T>) => {
                item?.errors?.forEach((error) => {
                    const includesValidError =
                        error.extensions.code === errorCode;

                    if (includesValidError) {
                        notification && notify(notification).ERROR();
                        onError?.(item);
                    }
                });
            });
        });
        if (Array.isArray(notification))
            notification.forEach((item) => notify(item).ERROR());
    };
