import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const AuthPageWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const MainPageWrapper = styled.div`
    display: grid;
    grid-template-rows: 150px 1fr;
    grid-template-areas:
        'panel'
        'content';
    min-height: 100%;
`;

export const MainPagePanel = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        background: ${colors.white};
        display: flex;
        gap: 32px;
        grid-area: panel;
        justify-content: space-between;
        padding: 0 24px;
    `,
);

export const MainPageContent = styled.div`
    display: flex;
    flex-direction: column;
    grid-area: content;
    padding: 48px 24px 0;
`;

export const MainPageName = styled(Typography.TextH1_36_52)``;
