import styled, { css } from 'styled-components';

export const SIDEBAR_WIDTH = 210;

const MainLayoutWrapper = styled.div`
    display: grid;
    grid-template-columns: ${SIDEBAR_WIDTH}px 1fr;
    grid-template-rows: 60px 1fr;
    grid-template-areas:
        'sidebar header'
        'sidebar content';
    min-height: 100vh;
`;

const MainLayoutSidebar = styled.div(
    ({ theme: { colors } }) => css`
        background: ${colors.secondary};
        grid-area: sidebar;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 210px;
    `,
);

const MainLayoutHeader = styled.div`
    grid-area: header;
`;

const MainLayoutContent = styled.div`
    grid-area: content;
    padding-bottom: 120px;
`;

export const MainLayoutStyles = {
    Content: MainLayoutContent,
    Header: MainLayoutHeader,
    Sidebar: MainLayoutSidebar,
    Wrapper: MainLayoutWrapper,
};

/**
 * Skeletons
 */

const MainLayoutSidebarSkeleton = styled(MainLayoutSidebar)(
    ({ theme: { colors } }) => css`
        background: ${colors.white};
        display: flex;
        flex-direction: column;
        gap: 100px;
        padding: 16px 24px;
    `,
);

const MainLayoutHeaderSkeleton = styled(MainLayoutHeader)`
    display: flex;
    justify-content: flex-end;
    padding: 12px;
`;

const MainLayoutContentHeaderSkeleton = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 48px 24px;
`;

const MainLayoutContentSectionSkeleton = styled.div`
    display: flex;
    flex-direction: column;
    gap: 48px;
    justify-content: space-between;
    padding: 48px 24px;
`;

export const MainLayoutSkeletonStyles = {
    Content: MainLayoutContent,
    ContentHeader: MainLayoutContentHeaderSkeleton,
    ContentSection: MainLayoutContentSectionSkeleton,
    Header: MainLayoutHeaderSkeleton,
    Sidebar: MainLayoutSidebarSkeleton,
    Wrapper: MainLayoutWrapper,
};
