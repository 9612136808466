import styled, { css } from 'styled-components';

export const FormContentWrapper = styled.div`
    margin-top: 32px;
`;

export const PasswordConfirmSectionWrapper = styled.div<{ editUser: boolean }>(
    ({ editUser }) => css`
        display: grid;
        grid-template-columns: ${editUser ? '1fr 20px' : '1fr'};
        gap: 32px;
    `,
);
