import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { notify } from '@speeki/global-ui-components';
import { StorageManager } from '@speeki/storage-manager';

import {
    AuthMeDocument,
    PasswordResetDocument,
} from '@graphql/generated/graphql';

import { passwordValid } from '@utils/helpers';
import { useQueryParam } from '@utils/hooks/useQueryParam';

import { sentry } from '@services/Sentry';

import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_VALIDATION_MESSAGES } from '@shared/formValidationMessages';
import { INFO_MESSAGES, SUCCESS_MESSAGES } from '@shared/notifyMessages';
import { internalPaths } from '@shared/paths';

export const usePasswordResetConfirmForm = () => {
    const navigate = useNavigate();
    const code = useQueryParam('code');

    const [authMe] = useLazyQuery(AuthMeDocument, {
        context: {
            logoutOnError: true,
        },
        fetchPolicy: 'network-only',
        onCompleted: () => {
            notify(SUCCESS_MESSAGES.PASSWORD_CHANGE_SUCCESSFULLY).SUCCESS();
            notify(INFO_MESSAGES.LOGGED_IN).INFO();
            navigate(internalPaths.clients);
        },
    });

    const [passwordReset, { loading }] = useMutation(PasswordResetDocument, {
        context: { notifyOnError: true },
        onCompleted: ({
            passwordResetAdmin: { refreshToken, sessionToken },
        }) => {
            StorageManager.setValue('graphSessionToken', sessionToken);
            StorageManager.setValue('graphRefreshToken', refreshToken);
            void sentry.setUser();

            void authMe();
        },
    });

    const {
        control,
        formState: { dirtyFields, errors, isSubmitted, isSubmitting, isValid },
        handleSubmit: handleFormSubmit,
        register,
    } = useForm<ChangePasswordFormValues>({
        resolver: yupResolver(ChangePasswordSchema),
    });

    const newPasswordValue = useWatch({ control, name: 'newPassword' }) ?? '';
    const isDisabled = isSubmitting || (!isValid && isSubmitted);

    const handleSubmit = handleFormSubmit((data: ChangePasswordFormValues) => {
        if (isDisabled) return;

        void passwordReset({
            variables: {
                input: { newPassword: data.newPassword ?? '', token: code },
            },
        });
    });

    useEffect(() => {
        if (!code) navigate(internalPaths.login);
    }, [code]);

    return {
        dirtyFields,
        errors,
        handleSubmit,
        isDisabled,
        isSubmitted,
        isSubmitting,
        loading,
        newPasswordValue,
        register,
    };
};

const ChangePasswordSchema = yup.object({
    confirmNewPassword: yup
        .string()
        .oneOf(
            [yup.ref('newPassword')],
            FORM_VALIDATION_MESSAGES.PASSWORD_NOT_MATCH,
        ),
    newPassword: yup
        .string()
        .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY)
        .test('passwordValidation', '', passwordValid),
});

interface ChangePasswordFormValues
    extends yup.InferType<typeof ChangePasswordSchema> {}
