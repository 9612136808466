import { IconButton, IconButtonProps, IconName } from '@speeki/icons';

export interface TableActionButton
    extends Pick<IconButtonProps, 'onClick' | 'disabled'> {
    icon: IconName;
}

export const TableActionButton = ({ icon, ...props }: TableActionButton) => {
    return (
        <IconButton
            {...props}
            iconProps={{
                icon,
                size: 24,
                themeColors: {
                    default: {
                        'color-1': 'button.tertiary',
                    },
                    disabled: {
                        'color-1': 'button.tertiaryDisabled',
                    },
                    hover: {
                        'color-1': 'button.tertiaryHover',
                    },
                },
            }}
            testId="5lv1wFk"
        />
    );
};
