import styled from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const TableTitle = styled(Typography.TextH2_28_44)``;

export const HeaderWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;
