import Image from '@assets/svg/empty_search.svg';

import {
    EmptyStateDescriptionWrapper,
    EmptyStateTitleWrapper,
} from '@components/molecules/commonStyles.styled';

import { EmptyStateWrapper, ImageWrapper } from './TagsTableEmptyState.styled';

interface TagsTableEmptyStateProps {
    filters: {
        default?: {
            groupTagName: string[];
            riskArea: string[];
            tags: string[];
        };
    };
}

export const TagsTableEmptyState = ({ filters }: TagsTableEmptyStateProps) => {
    const isFilterApply = !!(
        filters.default && Object.values(filters.default).flat().length
    );

    if (isFilterApply)
        return (
            <EmptyStateWrapper>
                <EmptyStateTitleWrapper>
                    There are no items for you to see.
                </EmptyStateTitleWrapper>
                <EmptyStateDescriptionWrapper>
                    Please adjust your filter if you believe you should see one.
                </EmptyStateDescriptionWrapper>
            </EmptyStateWrapper>
        );

    return (
        <EmptyStateWrapper>
            <ImageWrapper src={Image as string} />
            <EmptyStateTitleWrapper>
                Sorry, no results found
            </EmptyStateTitleWrapper>
            <EmptyStateDescriptionWrapper>
                Make sure you have typed the search name correctly or change the
                query.
            </EmptyStateDescriptionWrapper>
        </EmptyStateWrapper>
    );
};
