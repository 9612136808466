import styled from 'styled-components';

import { MarkdownField as DefaultMarkdownField } from '@domains/settings/organisms/MarkdownField/MarkdownField';

export const MarkdownFormWrapper = styled.div`
    align-items: flex-start;
    display: grid;
    gap: 16px 32px;
    grid-template-columns: 1fr;
    width: 100%;
`;

export const MarkdownField = styled(DefaultMarkdownField)`
    grid-column: 1 / 3;
`;

export const FormButtonWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin-top: 16px;
`;
