import { IconButton } from '@speeki/icons';

import { FormButtonWrapper } from './FormDeleteButton.styled';

interface FormDeleteButtonProps {
    handleRemove: () => void;
}

export const FormDeleteButton = ({ handleRemove }: FormDeleteButtonProps) => {
    return (
        <FormButtonWrapper>
            <IconButton
                iconProps={{
                    icon: 'SvgCloseBig24',
                    size: 24,
                }}
                onClick={handleRemove}
                testId="ty61V4u"
                type="button"
            />
        </FormButtonWrapper>
    );
};
