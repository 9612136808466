import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const activeTabCss = css(
    ({ theme: { colors, fontWeight } }) => css`
        &.active {
            font-weight: ${fontWeight.bold};

            &:after {
                background: ${colors.primary};
                border-radius: 2px;
                bottom: -2px;
                content: '';
                height: 3px;
                left: 0;
                position: absolute;
                width: 100%;
            }

            &:hover {
                &:after {
                    background-color: ${colors.primary};
                }
            }
        }
    `,
);

const disabledTabCss = css(
    ({ theme: { colors } }) => css`
        color: ${colors.text_assistive};
        pointer-events: none;
    `,
);

export const BaseTab = styled(NavLink)<{ disabled?: boolean }>`
    ${({ disabled, theme: { colors, font, fontSize, fontWeight } }) => css`
        align-items: center;
        color: ${colors.text_primary};
        display: flex;
        font-family: ${font.secondary};
        font-size: ${fontSize[16]}px;
        font-weight: ${fontWeight.regular};
        justify-content: center;
        line-height: 1.75;
        min-width: 184px;
        padding-bottom: 10px;
        position: relative;
        text-decoration: none;
        user-select: none;

        &:hover {
            &:after {
                background: ${colors.dividers};
                border-radius: 2px;
                bottom: -2px;
                content: '';
                height: 3px;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }

        ${disabled ? disabledTabCss : activeTabCss}
    `};
`;
