import { useState } from 'react';

import {
    GroupTagSelectFieldProps,
    SelectFieldOption,
} from '@speeki/global-ui-components';

import {
    GroupTagConnectionInput,
    GroupTagsDocument,
    GroupTagsQuery,
} from '@graphql/generated/graphql';

import { useLazyQuery } from '@apollo/client';
import { ERROR_MESSAGES } from '@shared/notifyMessages';

interface GroupTagNameSelectOptions
    extends Partial<Pick<GroupTagConnectionInput, 'riskAreaUuids'>> {
    transformCallback?: (
        groupTags: GroupTagsQuery['groupTags']['nodes'],
    ) => SelectFieldOption[];
}

export const useGroupTagNameSelect = ({
    riskAreaUuids,
    transformCallback,
}: GroupTagNameSelectOptions) => {
    const [noResultsText, setResultsText] = useState<string | undefined>(
        undefined,
    );

    const [getTags] = useLazyQuery(GroupTagsDocument, {
        fetchPolicy: 'cache-and-network',
    });

    const loadOptions: GroupTagSelectFieldProps['loadOptions'] = async (
        filter,
        _,
        additional,
    ) => {
        const offset = additional?.offset ? additional.offset + 10 : 1;

        try {
            const { data, error } = await getTags({
                variables: {
                    input: {
                        name: filter,
                        paging: {
                            limit: 10,
                            offset: offset ?? 1,
                        },
                        riskAreaUuids,
                    },
                },
            });

            !!error
                ? setResultsText(ERROR_MESSAGES.SELECT_FETCH)
                : setResultsText(undefined);

            const { nodes, pageInfo } = data?.groupTags || {};

            const options = transformCallback
                ? transformCallback(nodes ?? [])
                : nodes?.map((node) => ({
                      label: node.name,
                      value: node.uuid,
                  })) || [];

            return error
                ? { hasMore: false, options: [] }
                : {
                      additional: {
                          offset,
                      },
                      hasMore: pageInfo?.hasNextPage,
                      options,
                  };
        } catch {
            setResultsText(ERROR_MESSAGES.SELECT_FETCH);

            return {
                hasMore: false,
                options: [],
            };
        }
    };

    return {
        debounceTimeout: 200,
        loadOptions,
        noResultsText,
    };
};
