import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { StorageManager } from '@speeki/storage-manager';

import { Circle } from '@components/svgs/Circle';
import { SpeekiLogo } from '@components/svgs/SpeekiLogo';
import { AuthLayoutSkeleton } from '@components/templates/AuthLayout/AuthLayoutSkeleton';

import { internalPaths } from '@shared/paths';

import { AuthLayoutStyles } from './AuthLayout.styled';

interface AuthLayoutProps {
    children: React.ReactNode;
}

export const AuthLayout = ({ children }: AuthLayoutProps) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const sessionToken = StorageManager.getValue('graphSessionToken');
    const refreshToken = StorageManager.getValue('graphRefreshToken');

    useEffect(() => {
        if (sessionToken && refreshToken) {
            navigate(internalPaths.clients);
        }

        setLoading(false);

        return () => {
            setLoading(false);
        };
    }, [sessionToken, refreshToken]);

    if (loading) return <AuthLayoutSkeleton />;

    return (
        <AuthLayoutStyles.Wrapper>
            <AuthLayoutStyles.ImageWrapper>
                <Circle />
            </AuthLayoutStyles.ImageWrapper>
            <AuthLayoutStyles.Header>
                <AuthLayoutStyles.LogoWrapper onClick={() => navigate('/')}>
                    <SpeekiLogo />
                </AuthLayoutStyles.LogoWrapper>
            </AuthLayoutStyles.Header>
            <AuthLayoutStyles.Content>{children}</AuthLayoutStyles.Content>
        </AuthLayoutStyles.Wrapper>
    );
};
