/* eslint-disable sort-keys-custom-order-fix/sort-keys-custom-order-fix */
import { RegExpKeys } from '@@types/CommonTypes';

export const RegExpManager: { [key in RegExpKeys]: RegExp } = {
    minLength: /^.{8,}$/,
    minNumber: /(?=.*?[0-9])/,
    minSymbol: /(?=.*?[#?!@$%^&*-])/,
    uppercase: /(?=.*?[A-Z])/,
    domainName: /^(?:\*\.)?[a-z0-9]+(?:[\-.][a-z0-9]+)*\.[a-z]{2,6}$/,
};

export const CUSTOM_HOOK_FORM_ERRORS = {
    DUPLICATE: 'DUPLICATE',
    NAME_ALREADY_EXISTS: 'NAME_ALREADY_EXISTS',
    REQUIRED: 'required',
};
