import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import { ClientTableRow, headerData } from './Table.settings';

import { COUNTRY_CODE_ORDERED } from '@speeki/dictionary';
import { DefaultManualTable, Table } from '@speeki/global-ui-components';
import { IconManager } from '@speeki/icons';

import { TableEmptyState } from '@components/molecules/CardTableEmptyState/TableEmptyState';
import { ClientsSearchForm } from '@components/organisms/ClientsSearchForm/ClientsSearchForm';

import {
    AdminTenantDataSortFields,
    AdminTenantDataSortInput,
    ClientsListDocument,
} from '@graphql/generated/graphql';

import { toCapitalize } from '@utils/helpers';

import { useLazyQuery } from '@apollo/client';
import { internalPaths } from '@shared/paths';

import { ClientsTableWrapper } from './ClientsTable.styled';

type SortKeys = Exclude<keyof ClientTableRow, 'id'>;

const parsedSortKeys: Record<SortKeys, AdminTenantDataSortFields> = {
    companyName: 'NAME',
    country: 'COUNTRY',
    licenses: 'STANDARD_LICENSE_COUNT',
    plan: 'PLAN',
    subscriptionEnd: 'SUBSCRIPTION_END_DATE',
    subscriptionStatus: 'STATUS',
};

const getQuerySorting = (
    sort: SortingRule<ClientTableRow>[],
): AdminTenantDataSortInput[] =>
    sort.map((item) => ({
        direction: item.desc ? 'DESC' : 'ASC',
        field: parsedSortKeys[item.id as SortKeys],
    }));

export const ClientsTable = () => {
    const navigate = useNavigate();
    const [searchPhrase, setSearchPhrase] = useState('');

    const [getData, { called, data, loading }] = useLazyQuery(
        ClientsListDocument,
        { fetchPolicy: 'cache-and-network' },
    );

    const transformedData: ClientTableRow[] =
        useMemo(
            () =>
                data?.clientsList?.edges?.map((client) => {
                    const countryCode = client.node.country;

                    return {
                        id: client.node.uuid,
                        companyName: client.node.name,
                        country: COUNTRY_CODE_ORDERED[countryCode],
                        licenses: client.node.subscription.standardLicenseCount,
                        plan: toCapitalize(
                            client.node.subscription.plan?.name || '',
                        ),
                        subscriptionEnd: dayjs(
                            client.node.subscription.end,
                        ).format('YYYY/MM/DD'),
                        subscriptionStatus: client.node.subscription.status,
                    };
                }),
            [data],
        ) || [];

    return (
        <>
            <ClientsSearchForm onSearch={setSearchPhrase} />
            <ClientsTableWrapper>
                <DefaultManualTable<ClientTableRow>
                    columns={headerData}
                    data={transformedData}
                    pagination={{
                        options: {
                            pageSelect: true,
                            totalCount: data?.clientsList.totalCount || 0,
                        },
                    }}
                    tableHeaderRow={{
                        additionalCell: (
                            <Table.HeaderCell width={'0%'}>
                                <Table.HeaderInner testId="I2VxPm-" />
                            </Table.HeaderCell>
                        ),
                        testId: 'XBPlkAr',
                    }}
                    tableRow={{
                        additionalCells: (
                            <td>
                                <IconManager
                                    icon={'SvgChevronRight24'}
                                    size={24}
                                />
                            </td>
                        ),
                        onRowClick: (props) =>
                            navigate(internalPaths.clientDetails(props.id)),
                        testId: 'dK48OAa',
                    }}
                    tableState={{
                        emptyState: <TableEmptyState />,
                        getQuerySorting,
                        getTableData: (props) =>
                            void getData({
                                variables: {
                                    name: props.searchPhrase,
                                    paging: props.paging,
                                    sorting:
                                        props.sorting as AdminTenantDataSortInput[],
                                },
                            }),
                        hiddenColumns: ['id'],
                        loading: loading || !called,
                        searchPhrase,
                        skeletonVariant: 'inverted',
                    }}
                    testId="tVH0_fG"
                />
            </ClientsTableWrapper>
        </>
    );
};
