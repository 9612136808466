import styled from 'styled-components';

import { Modal } from '@speeki/global-ui-components';

export const DeleteButtonWrapper = styled.div`
    min-width: 200px;
    display: flex;
    align-items: center;
`;

export const ModalTitleSecondary = styled(Modal.TitleSecondary)`
    margin-bottom: 16px;
`;

export const ModalHeader = styled(Modal.Header)`
    margin-bottom: 24px;
`;
