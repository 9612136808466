import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ESG_SUBCATEGORY } from '@speeki/dictionary';
import { SecondaryManualTable } from '@speeki/global-ui-components';

import { RiskAreasDocument } from '@graphql/generated/graphql';

import { toKebabCase } from '@utils/helpers';

import { useLazyQuery } from '@apollo/client';
import { EsgProfileSearchForm } from '@domains/settings/organisms/EsgProfileSearchForm/EsgProfileSearchForm';
import {
    Description,
    HeaderWrapper,
    ProfileCard,
    SearchWrapper,
    Title,
} from '@domains/settings/pages/EsgProfilePage/EsgProfilePage.styled';
import {
    EsgProfileTableRow,
    headerData,
} from '@domains/settings/pages/EsgProfilePage/EsgProfileTable.settings';
import { SettingsLayout } from '@domains/settings/templates/SettingsLayout/SettingsLayout';
import { internalPaths } from '@shared/paths';

export const EsgProfilePage = () => {
    const [searchPhrase, setSearchPhrase] = useState('');

    const navigate = useNavigate();

    const [getData, { called, data, loading }] = useLazyQuery(
        RiskAreasDocument,
        {
            fetchPolicy: 'cache-and-network',
        },
    );

    const transformedData: EsgProfileTableRow[] =
        useMemo(
            () =>
                data?.riskAreas
                    ?.filter((riskArea) => !riskArea.isShared)
                    ?.map((riskArea) => ({
                        id: riskArea.uuid,
                        label: ESG_SUBCATEGORY[riskArea.name ?? ''],
                        riskArea: riskArea.name,
                    })),
            [data],
        ) || [];

    return (
        <SettingsLayout>
            <ProfileCard>
                <HeaderWrapper>
                    <Title>ESG Profile</Title>
                    <Description>
                        You can change the name of each topic and edit questions
                        represented in ESG profiles.
                    </Description>
                </HeaderWrapper>
                <SearchWrapper>
                    <EsgProfileSearchForm onSearch={setSearchPhrase} />
                </SearchWrapper>
                <SecondaryManualTable
                    columns={headerData}
                    data={transformedData}
                    tableHeaderRow={{ hidden: true, testId: '0Su6JA_' }}
                    tableRow={{
                        onRowClick: (data) =>
                            navigate(
                                internalPaths.esgProfileTopic(
                                    toKebabCase(data.riskArea),
                                ),
                            ),
                        showRightArrow: true,
                        testId: 'QK_S4pm',
                    }}
                    tableState={{
                        getTableData: (props) =>
                            void getData({
                                variables: {
                                    input: { nameSearch: props.searchPhrase },
                                },
                            }),
                        hiddenColumns: ['riskArea'],
                        loading: loading || !called,
                        searchPhrase,
                    }}
                    testId="Yl7aewn"
                />
            </ProfileCard>
        </SettingsLayout>
    );
};
