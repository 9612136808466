import styled, { css } from 'styled-components';

import { TableEmptyStateProps } from './TableEmptyState';

export const EmptyStateWrapper = styled.div<
    Pick<TableEmptyStateProps, 'marginTop' | 'paddingBottom' | 'paddingTop'>
>(
    ({ marginTop, paddingBottom, paddingTop, theme: { colors } }) => css`
        align-content: center;
        border-top: 1px solid ${colors.dividers};
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: ${marginTop};
        padding-bottom: ${paddingBottom};
        padding-top: ${paddingTop};
        width: 100%;
    `,
);
