import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { notify } from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import {
    GroupTagDocument,
    GroupTagUpsertDocument,
} from '@graphql/generated/graphql';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { editedGroupTagIdVar } from '@boot/reactiveVariables';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_VALIDATION_MESSAGES } from '@shared/formValidationMessages';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';
import { useRiskAreasSelect } from '@shared/selects/useRiskAreasSelect';
import { requiredSelectV2 } from '@shared/validations';

export const useGroupTagEditModal = (setOpen: UseModal['setOpen']) => {
    const {
        control,
        formState: { errors, isDirty, isValid },
        handleSubmit: handleFormSubmit,
        register,
        reset,
    } = useForm<GroupTagEditFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(GroupTagEditSchema),
    });

    const { field: riskAreaField } = useController({
        control,
        name: 'riskArea',
    });

    const editedGroupTagId = useReactiveVar(editedGroupTagIdVar);
    const { data: groupTagData } = useQuery(GroupTagDocument, {
        fetchPolicy: 'cache-and-network',
        variables: {
            uuid: editedGroupTagId,
        },
    });

    const [groupTagUpsert, { loading: upsertLoading }] = useMutation(
        GroupTagUpsertDocument,
        {
            context: {
                notifyOnError: true,
            },
            onError: () => {},
        },
    );

    const handleClose = () => setOpen(false);

    const handleSubmit = handleFormSubmit((data) => {
        if (!groupTagData) return;

        void groupTagUpsert({
            onCompleted: () => {
                notify(SUCCESS_MESSAGES.GROUP_TAG_UPDATE).SUCCESS();
                handleClose();
            },
            variables: {
                input: {
                    groupTagUuid: groupTagData.groupTag.uuid,
                    name: data.groupTagName,
                    riskAreaUuid: data.riskArea.value,
                },
            },
        });
    });

    const riskAreasSelect = useRiskAreasSelect();

    useEffect(() => {
        if (!groupTagData) return;

        const {
            groupTag: {
                name: groupTagName,
                riskArea: { label: riskAreaLabel, uuid: riskAreaId },
            },
        } = groupTagData;

        reset({
            groupTagName,
            riskArea: {
                label: riskAreaLabel,
                value: riskAreaId,
            },
        });
    }, [groupTagData]);

    return {
        errors,
        handleClose,
        handleSubmit,
        isDirty,
        isValid,
        register,
        riskAreaField,
        riskAreasSelect,
        upsertLoading,
    };
};

const GroupTagEditSchema = yup.object({
    groupTagName: yup
        .string()
        .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
    riskArea: requiredSelectV2,
});

type GroupTagEditFormValues = yup.InferType<typeof GroupTagEditSchema>;
