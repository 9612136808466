import { adminUsersHeaderData } from './AdminUsersCard.settings';
import { useAdminUsersCard } from './useAdminUsersCard';

import {
    AddButton,
    Card,
    CardTableEmptyState,
    SecondaryManualTable,
    Switch,
    TableCell,
} from '@speeki/global-ui-components';
import { useModal } from '@speeki/react-hooks';

import { TableActionButton } from '@components/atoms/TableActionButton/TableActionButton';

import { ActionsWrapper } from '@domains/settings/ions/Table.styled';
import { AdminUsersDeactivationModal } from '@domains/settings/organisms/AdminUsers/AdminUsersDeactivationModal/AdminUsersDeactivationModal';
import { AdminUsersUpsertModal } from '@domains/settings/organisms/AdminUsers/AdminUsersUpsertModal/AdminUsersUpsertModal';

export const AdminUsersCard = () => {
    const upsertUserModal = useModal();

    const deactivateUserModal = useModal();
    const handleOpenDeactivateModal = (id: string) => {
        setEditedUuid(id);
        deactivateUserModal.setOpen(true);
    };

    const {
        adminUsersField,
        editedUserData,
        getTableData,
        handleUserStatusChange,
        loading,
        noData,
        searchPhrase,
        setEditedUuid,
        setSearchPhrase,
        tableData,
        totalCount,
        upsertLoading,
        userStatus,
    } = useAdminUsersCard(() => deactivateUserModal.setOpen(false));

    return (
        <>
            <Card
                header={{
                    actionButton: (
                        <AddButton
                            disabled={loading}
                            onClick={() => upsertUserModal.setOpen(true)}
                            testId="9RUsU6J"
                        />
                    ),
                    description:
                        'Easily add, edit, or deactivate admin users to manage their access.',
                    title: 'Admin users',
                }}
                isEmpty={noData}
                onSearch={setSearchPhrase}
            >
                <SecondaryManualTable
                    columns={adminUsersHeaderData}
                    data={tableData}
                    pagination={{
                        options: { totalCount: totalCount },
                    }}
                    tableRow={{
                        additionalCells: ({ id }) => {
                            const currentUser =
                                adminUsersField.field.value.find(
                                    ({ uuid: userId }) => userId === id,
                                );

                            return (
                                <TableCell
                                    key={'actions'}
                                    paddingRight={'0'}
                                    testId="0kSNjv3"
                                    width={'1%'}
                                >
                                    <ActionsWrapper>
                                        <TableActionButton
                                            disabled={!currentUser?.enabled}
                                            icon={'SvgEdit24'}
                                            onClick={() => {
                                                setEditedUuid(id);
                                                upsertUserModal.setOpen(true);
                                            }}
                                        />
                                        <Switch
                                            checked={!!currentUser?.enabled}
                                            onChange={() =>
                                                handleOpenDeactivateModal(id)
                                            }
                                            testId="A_XxPrL"
                                        />
                                    </ActionsWrapper>
                                </TableCell>
                            );
                        },
                        testId: 'VmXWB65',
                    }}
                    tableState={{
                        emptySearchState: <CardTableEmptyState />,
                        getTableData,
                        loading,
                        searchPhrase,
                    }}
                    testId="S1BZQ3i"
                />
            </Card>

            {deactivateUserModal.open && (
                <AdminUsersDeactivationModal
                    {...deactivateUserModal}
                    loading={upsertLoading}
                    onCancel={() => setEditedUuid(undefined)}
                    onConfirm={handleUserStatusChange}
                    userStatus={userStatus}
                />
            )}

            {upsertUserModal.open && (
                <AdminUsersUpsertModal
                    {...upsertUserModal}
                    editedUserData={editedUserData}
                    onCancel={() => setEditedUuid(undefined)}
                />
            )}
        </>
    );
};
