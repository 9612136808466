import { ReactNode } from 'react';

import {
    CardInnerWrapper,
    DetailedActionDescription,
    DetailedActionTitle,
    DetailedActionWrapper,
    DetailedContentWrapper,
    DetailedInfoWrapper,
} from '@domains/clients/molecules/DetailedAction/DetailedAction.styled';

interface DetailedActionProps {
    additionalInfoComponent?: ReactNode;
    children?: ReactNode;
    className?: string;
    description: string;
    title: string;
}

export const DetailedAction = ({
    additionalInfoComponent,
    children,
    className,
    description,
    title,
}: DetailedActionProps) => {
    return (
        <DetailedActionWrapper className={className}>
            <DetailedInfoWrapper>
                <DetailedActionTitle>{title}</DetailedActionTitle>
                <DetailedActionDescription>
                    {description}
                </DetailedActionDescription>
                {additionalInfoComponent}
            </DetailedInfoWrapper>
            <DetailedContentWrapper>
                <CardInnerWrapper>{children}</CardInnerWrapper>
            </DetailedContentWrapper>
        </DetailedActionWrapper>
    );
};
