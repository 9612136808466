import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Sidebar } from './Sidebar/Sidebar';

import { TopBar } from '@speeki/global-ui-components';

import { MainLayoutSkeleton } from '@components/templates/MainLayout/MainLayoutSkeleton';

import { AuthMeDocument } from '@graphql/generated/graphql';

import { useLazyQuery } from '@apollo/client';
import { logout } from '@shared/logout';
import { internalPaths } from '@shared/paths';

import { MainLayoutStyles } from './MainLayout.styled';

interface MainLayoutProps {
    children: React.ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
    const [authMe, { called, data, loading }] = useLazyQuery(AuthMeDocument, {
        context: {
            logoutOnError: true,
        },
    });
    const navigate = useNavigate();

    useEffect(() => {
        void authMe();
    }, []);

    if (loading && !called) return <MainLayoutSkeleton />;

    return (
        <MainLayoutStyles.Wrapper>
            <MainLayoutStyles.Sidebar>
                <Sidebar />
            </MainLayoutStyles.Sidebar>
            <MainLayoutStyles.Header>
                <TopBar
                    logout={() => logout()}
                    onSettingsClick={() =>
                        navigate(internalPaths.tagManagement)
                    }
                    profileName={data?.user.email ?? ''}
                />
            </MainLayoutStyles.Header>
            <MainLayoutStyles.Content>{children}</MainLayoutStyles.Content>
        </MainLayoutStyles.Wrapper>
    );
};
