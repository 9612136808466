import { useController, useFormContext } from 'react-hook-form';

import {
    DateField,
    NumberField,
    SelectField,
    TextField,
} from '@speeki/global-ui-components';

import { DividedFieldRow, FormWrapper } from '@components/ions/Form.styled';

import { planOptions } from '@utils/dictionary';

import { SubscriptionInformationFormValues } from '@domains/clients/organisms/SubscriptionInformationModal/SubscriptionInformationModal.tsx';

export const SubscriptionInformationForm = () => {
    const {
        control,
        formState: { errors },
        register,
        watch,
    } = useFormContext<SubscriptionInformationFormValues>();

    const { field: planSelect } = useController({
        control,
        name: 'plan',
    });

    const { field: subscriptionStartDate } = useController({
        control,
        name: 'subscriptionStart',
    });

    const { field: subscriptionEndDate } = useController({
        control,
        name: 'subscriptionEnd',
    });

    const currentStartDate = watch('subscriptionStart');

    return (
        <FormWrapper>
            <DividedFieldRow>
                <SelectField
                    {...planSelect}
                    error={!!errors.plan}
                    id={'plan'}
                    isClearable={false}
                    label={'Plan'}
                    message={errors.plan?.value?.message}
                    options={planOptions}
                    placeholder={'Select country'}
                    testId="8b0PlcB"
                />
                <TextField
                    {...register('subscriberEmail')}
                    error={!!errors.subscriberEmail}
                    id={'subscriberEmail'}
                    label={'Subscriber’s email'}
                    message={errors.subscriberEmail?.message}
                    placeholder={'Enter subscriber email address'}
                    testId="qI-EsKa"
                />
            </DividedFieldRow>
            <DividedFieldRow>
                <NumberField
                    {...register('standardLicenses')}
                    error={!!errors.standardLicenses}
                    id={'standardLicenses'}
                    label={'Number of standard licenses'}
                    message={errors.standardLicenses?.message}
                    placeholder={'Enter number of standard licenses'}
                    testId="v1sQAWx"
                />
                <NumberField
                    {...register('limitedLicenses')}
                    error={!!errors.limitedLicenses}
                    id={'limitedLicenses'}
                    label={'Number of limited licenses'}
                    message={errors.limitedLicenses?.message}
                    placeholder={'Enter number of limited licenses'}
                    testId="F9GOLZK"
                />
            </DividedFieldRow>
            <DividedFieldRow>
                <DateField
                    {...subscriptionStartDate}
                    error={!!errors.subscriptionStart}
                    id={'subscription-start'}
                    label={'Subscription start date'}
                    message={errors.subscriptionStart?.message}
                    placeholder={'Select date'}
                    testId="OPsbQ-N"
                />
                <DateField
                    {...subscriptionEndDate}
                    disabled={!currentStartDate}
                    error={!!errors.subscriptionEnd}
                    id={'subscription-end'}
                    label={'Subscription end date'}
                    message={errors.subscriptionEnd?.message}
                    placeholder={'Select date'}
                    testId="K5hVflO"
                />
            </DividedFieldRow>
        </FormWrapper>
    );
};
