import styled, { css } from 'styled-components';

export const TagsListContent = styled.div(
    ({ theme: { palette } }) => css`
        border-top: 1px solid ${palette.neutral.dividers};
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 24px;
    `,
);

export const TagsListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;
