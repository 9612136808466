import { SvgProps } from '@@types/CommonTypes';

import { getSvgSize } from '@utils/helpers';

export const IconMegaphoneWhite = ({ size }: SvgProps) => (
    <svg {...getSvgSize(24, 24, size)} fill="none">
        <g clipPath="url(#icon-megaphone-a)">
            <g clipPath="url(#icon-megaphone-b)">
                <path
                    d="M5.145 13.8 7.9 20h1.637a1.835 1.835 0 0 0 1.676-2.579L9.693 14h1.64a3.337 3.337 0 0 1 3.334 3.333H16V4h-1.333a3.337 3.337 0 0 1-3.334 3.333H6a2 2 0 0 0-2 2V12a2 2 0 0 0 1.145 1.8Zm4.85 4.162a.5.5 0 0 1-.458.705h-.77L6.693 14h1.54l1.762 3.962ZM5.333 9.333A.667.667 0 0 1 6 8.667h5.333a4.649 4.649 0 0 0 3.334-1.408v6.815a4.648 4.648 0 0 0-3.334-1.407H6A.667.667 0 0 1 5.333 12V9.333Zm13.529 5.138-1.525-1.525.942-.943 1.526 1.526-.943.942Zm-.557-5.166-.943-.943 1.5-1.5.943.943-1.5 1.5ZM18 10h2v1.333h-2V10Z"
                    fill="#fff"
                />
            </g>
        </g>
        <defs>
            <clipPath id="icon-megaphone-a">
                <path d="M0 0h24v24H0z" fill="#fff" />
            </clipPath>
            <clipPath id="icon-megaphone-b">
                <path
                    d="M0 0h16v16H0z"
                    fill="#fff"
                    transform="translate(4 4)"
                />
            </clipPath>
        </defs>
    </svg>
);
