import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
    Button,
    Modal,
    ModalComponent,
    notify,
} from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import { PrimaryContactForm } from '@components/organisms/PrimaryContactForm/PrimaryContactForm';

import { UpdateTenantPrimaryContactDocument } from '@graphql/generated/graphql';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';

interface PrimaryContactModalProps extends UseModal {
    defaultData: {
        email: string;
        firstName: string;
        id: string;
        lastName: string;
    };
    tenantUuid: string;
}

export const PrimaryContactModal = ({
    defaultData,
    open,
    setOpen,
    tenantUuid,
}: PrimaryContactModalProps) => {
    const methods = useForm<PrimaryContactFormValues>({
        defaultValues: {
            email: defaultData?.email,
            firstName: defaultData?.firstName,
            lastName: defaultData?.lastName,
        },
        mode: 'onChange',
        resolver: yupResolver(PrimaryContactSchema),
    });

    const {
        formState: { isSubmitted, isSubmitting, isValid },
        handleSubmit,
        reset,
    } = methods;

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    const [updateTenantPrimaryContact, { loading }] = useMutation(
        UpdateTenantPrimaryContactDocument,
        {
            context: {
                notifyOnError: true,
            },
            onCompleted: () => {
                handleClose?.();
                notify(
                    SUCCESS_MESSAGES.TENANT_PRIMARY_CONTACT_INFORMATION_UPDATED,
                ).SUCCESS();
            },
            onError: () => {},
        },
    );

    const onSubmit = (data: PrimaryContactFormValues) => {
        void updateTenantPrimaryContact({
            variables: {
                input: {
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    tenantUuid,
                },
            },
        });
    };

    return (
        <ModalComponent onClose={handleClose} open={open}>
            <Modal.Wrapper>
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>Primary contact</Modal.Title>
                        <Modal.SubTitle>
                            Edit tenant’s primary contact to get in touch in
                            incident of need.
                        </Modal.SubTitle>
                    </Modal.Header>
                    <FormProvider {...methods}>
                        <PrimaryContactForm />
                    </FormProvider>
                </Modal.Content>
                <Modal.Footer>
                    <Modal.ButtonsWrapper>
                        <Button
                            onClick={handleClose}
                            size={'small'}
                            testId="EgsrjVG"
                            variant={'secondary'}
                        >
                            cancel
                        </Button>
                        <Button
                            disabled={!isValid && isSubmitted}
                            isLoading={loading || isSubmitting}
                            onClick={handleSubmit(onSubmit)}
                            size={'small'}
                            testId="DXltdBb"
                            variant={'primary'}
                        >
                            save
                        </Button>
                    </Modal.ButtonsWrapper>
                </Modal.Footer>
            </Modal.Wrapper>
        </ModalComponent>
    );
};

const PrimaryContactSchema = yup.object({
    email: yup
        .string()
        .email('Invalid address email')
        .required('This field cannot be empty'),
    firstName: yup.string().required('This field cannot be empty'),
    lastName: yup.string().required('This field cannot be empty'),
});

export type PrimaryContactFormValues = yup.InferType<
    typeof PrimaryContactSchema
>;
