import { useNavigate } from 'react-router-dom';

import { Button } from '@speeki/global-ui-components';

import {
    MainPageContent,
    MainPageName,
    MainPagePanel,
    MainPageWrapper,
} from '@components/ions/Pages.styled';
import { ClientsTable } from '@components/organisms/ClientsTable/ClientsTable';
import { MainLayout } from '@components/templates/MainLayout/MainLayout';

import { internalPaths } from '@shared/paths';

export const ClientsPage = () => {
    const navigate = useNavigate();

    return (
        <MainLayout>
            <MainPageWrapper>
                <MainPagePanel>
                    <MainPageName>Clients</MainPageName>
                    <Button
                        onClick={() => navigate(internalPaths.validationClient)}
                        testId="lzSPCKl"
                        variant={'primary'}
                    >
                        Add new client
                    </Button>
                </MainPagePanel>
                <MainPageContent>
                    <ClientsTable />
                </MainPageContent>
            </MainPageWrapper>
        </MainLayout>
    );
};
