import '@speeki/frontend-sentry/instrument';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import 'regenerator-runtime/runtime';

import { AppInitializer } from './App/AppInitializer';

import { reportWebVitals } from '@boot/reportWebVitals';

ReactDOM.createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <AppInitializer />
    </StrictMode>,
);

void reportWebVitals();
