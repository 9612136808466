import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { TextField } from '@speeki/global-ui-components';
import { useDebouncedCallback } from '@speeki/react-hooks';

import { FieldsDivided, FormWrapper } from '@components/ions/Form.styled';

import { ClientByNameDocument } from '@graphql/generated/graphql';

import { useLazyQuery } from '@apollo/client';
import { SubsidiarySiteSchemaFormValues } from '@domains/clients/organisms/SubsidiarySiteModal/SubsidiarySiteModal.tsx';

interface SubsidiarySiteFormProps {
    subsidiaryName?: string;
}

export const SubsidiarySiteForm = ({
    subsidiaryName,
}: SubsidiarySiteFormProps) => {
    const { clientId } = useParams<{ clientId: string }>();

    const {
        formState: { errors },
        register,
        setError,
    } = useFormContext<SubsidiarySiteSchemaFormValues>();

    const [getClientByName, { loading: clientByNameLoading }] = useLazyQuery(
        ClientByNameDocument,
        { fetchPolicy: 'cache-and-network' },
    );
    const getClientByNameDebounce = useDebouncedCallback(getClientByName, 100);

    return (
        <FormWrapper>
            <FieldsDivided gridTemplateColumns={'2fr 1fr'}>
                <TextField
                    {...register('subsidiaryName')}
                    error={!!errors.subsidiaryName}
                    id={'subsidiary-name'}
                    label={'Entity name *'}
                    loading={clientByNameLoading}
                    message={errors.subsidiaryName?.message}
                    onChange={(event) => {
                        void getClientByNameDebounce({
                            onCompleted: ({ client }) => {
                                client && subsidiaryName !== client.name
                                    ? setError('subsidiaryName', {
                                          message:
                                              'Site name is already taken. Please choose another one.',
                                          type: 'custom',
                                      })
                                    : undefined;
                            },
                            variables: {
                                name: event.target.value,
                                tenantUuid: clientId ?? '',
                            },
                        });
                        void register('subsidiaryName').onChange(event);
                    }}
                    placeholder={'Enter entity name'}
                    testId="AGGzj_X"
                />
            </FieldsDivided>
        </FormWrapper>
    );
};
