import { ScrollNavigation } from '@speeki/global-ui-components';

import { AdminUsersCard } from '@domains/settings/organisms/AdminUsers/AdminUsersCard/AdminUsersCard';
import { BlockedDomainsCard } from '@domains/settings/organisms/BlockedDomains/BlockedDomainsCard/BlockedDomainsCard';
import { SettingsLayout } from '@domains/settings/templates/SettingsLayout/SettingsLayout';

export const OtherPage = () => {
    const cardComponents = [
        {
            component: <AdminUsersCard />,
            name: 'Admin users',
            show: true,
        },
        {
            component: <BlockedDomainsCard />,
            name: 'Blocked domains',
            show: true,
        },
    ];
    return (
        <SettingsLayout>
            <ScrollNavigation cards={cardComponents} variant="speekiAdmin" />
        </SettingsLayout>
    );
};
