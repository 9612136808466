import { Button, Modal, ModalComponent } from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

interface ElineNumberDeleteModalProps extends UseModal {
    onConfirm: () => void;
}

export const ELineNumberDeleteModal = ({
    onConfirm,
    open,
    setOpen,
}: ElineNumberDeleteModalProps) => {
    const onClose = () => setOpen(false);

    return (
        <ModalComponent
            onClose={onClose}
            open={open}
            preventCloseEsc
            preventCloseOutside
            width={648}
        >
            <Modal.Content>
                <Modal.TitleSecondary>Are you sure?</Modal.TitleSecondary>
                <Modal.SubTitle color="black">
                    Are you sure you want to remove this phone number? The data
                    associated with this number will be lost and your tenants
                    will no longer be able to use it.
                </Modal.SubTitle>
            </Modal.Content>
            <Modal.Footer>
                <Modal.ButtonsWrapper>
                    <Button
                        onClick={onClose}
                        size={'small'}
                        testId="YNJbWUR"
                        variant={'secondary'}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onConfirm}
                        size={'small'}
                        testId="Deqya-K"
                        variant={'primary'}
                    >
                        Remove
                    </Button>
                </Modal.ButtonsWrapper>
            </Modal.Footer>
        </ModalComponent>
    );
};
