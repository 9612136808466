/* eslint-disable sort-keys-custom-order-fix/sort-keys-custom-order-fix */
import {
    offsetPaginationV2,
    relayStylePaginationV2,
} from '@speeki/apollo-utils';
import { ESG_SUBCATEGORY } from '@speeki/dictionary';

import { Esg_Subcategory } from '@graphql/generated/graphql';

import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';

export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                assistantSlots: {
                    keyArgs: ['input', ['paging', 'sorting']],
                },
                clientSubsidiarySites: relayStylePaginationV2([
                    'input',
                    ['name', 'tenantUuid'],
                ]),
                clients: relayStylePaginationV2(['input', ['sorting', 'name']]),
                groupTags: offsetPaginationV2([
                    'input',
                    ['riskAreaUuids', 'sorting', 'name'],
                ]),
                groupTagsRows: offsetPaginationV2([
                    'input',
                    [
                        'groupTags',
                        'riskAreasUUIDs',
                        'search',
                        'sorting',
                        'tags',
                    ],
                ]),
                guidelines: offsetPaginationV2([
                    'input',
                    ['sorting', 'paging', 'search'],
                ]),
                adminUsers: offsetPaginationV2([
                    'input',
                    ['freeTextQuery', 'paging', 'sorting'],
                ]),
                bannedEmails: offsetPaginationV2([
                    'input',
                    ['freeTextQuery', 'paging', 'sorting'],
                ]),
            },
        },
        AssistantSlot: {
            keyFields: ['slotId', 'actionId'],
        },
        ClientUser: {
            fields: {
                fullName(_, { readField }) {
                    const firstName = readField<string>('firstName');
                    const lastName = readField<string>('lastName');
                    return `${firstName} ${lastName}`;
                },
            },
        },
        RiskArea: {
            fields: {
                label(_, { readField }) {
                    const name = readField<Esg_Subcategory>('name');

                    return !!name ? ESG_SUBCATEGORY[name] : '';
                },
                sectors: offsetPaginationV2(['input', ['paging']]),
            },
        },
    },
    dataIdFromObject: (responseObject) => {
        if (responseObject.uuid && typeof responseObject.uuid === 'string') {
            return `${responseObject.__typename}:${responseObject.uuid}`;
        }

        return defaultDataIdFromObject(responseObject);
    },
});
