import { useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import {
    Button,
    SecondaryManualTable,
    TableCell,
} from '@speeki/global-ui-components';
import { IconButton } from '@speeki/icons';

import {
    GuidelineSortFields,
    GuidelineSortInput,
} from '@graphql/generated/graphql';

import { GuidelineTableEmptyState } from '@domains/settings/molecules/GuidelineTableEmptyState/GuidelineTableEmptyState';
import {
    GuidelineAndGlossaryCard,
    GuidelineAndGlossaryDescription,
    GuidelineAndGlossaryTitle,
} from '@domains/settings/pages/GuidelineAndGlossaryPage.styled';
import { GuidelineTableRow } from '@domains/settings/pages/GuidelinePage/GuidelineTablePage/GuidelineTablePage.settings';
import {
    ActionsWrapper,
    GuidelineSearchField,
    HeaderWrapper,
} from '@domains/settings/pages/GuidelinePage/GuidelineTablePage/GuidelineTablePage.styled';
import { useGuidelineTablePage } from '@domains/settings/pages/GuidelinePage/GuidelineTablePage/useGuidelineTablePage';
import { SettingsLayout } from '@domains/settings/templates/SettingsLayout/SettingsLayout';
import { internalPaths } from '@shared/paths';

type SortKeys = Exclude<keyof GuidelineTableRow, 'id'>;

const parsedSortKeys: Record<SortKeys, GuidelineSortFields> = {
    category: 'CATEGORY',
    order: 'ORDER',
    standard: 'STANDARD_NAME',
    subcategory: 'NAME',
};
const getQuerySorting = (
    sort: SortingRule<GuidelineTableRow>[],
): GuidelineSortInput[] =>
    sort.map((item) => ({
        direction: item.desc ? 'DESC' : 'ASC',
        field: parsedSortKeys[item.id as SortKeys],
    }));

export const GuidelineTablePage = () => {
    const navigate = useNavigate();
    const {
        called,
        debouncedSearchPhrase,
        getGuidelineData,
        headerData,
        loading,
        searchPhrase,
        setSearchPhrase,
        totalCount,
        transformedData,
    } = useGuidelineTablePage();

    return (
        <SettingsLayout>
            <GuidelineAndGlossaryCard>
                <HeaderWrapper>
                    <div>
                        <GuidelineAndGlossaryTitle>
                            Guidelines
                        </GuidelineAndGlossaryTitle>
                        <GuidelineAndGlossaryDescription>
                            Below is a list of all terms that currently exist in
                            the system
                        </GuidelineAndGlossaryDescription>
                    </div>

                    <Button
                        iconProps={{
                            icon: 'SvgAddNew24',
                            size: 24,
                            themeColors: {
                                default: {
                                    'color-1': 'secondary.default',
                                },
                            },
                        }}
                        minWidth={137}
                        onClick={() => navigate(internalPaths.guidelineAdd)}
                        size={'small'}
                        testId="5A2psgY"
                        variant={'secondary'}
                    >
                        Add new
                    </Button>
                </HeaderWrapper>
                <GuidelineSearchField
                    onChange={setSearchPhrase}
                    onReset={() => {
                        setSearchPhrase('');
                    }}
                    onSearch={setSearchPhrase}
                    placeholder={'Search...'}
                    sizeType={'small'}
                    testId="Io8ZpaR"
                    value={searchPhrase}
                />
                <SecondaryManualTable<GuidelineTableRow>
                    columns={headerData}
                    data={transformedData}
                    pagination={{
                        options: {
                            pageSelect: true,
                            totalCount: totalCount || 0,
                        },
                    }}
                    tableHeaderRow={{
                        testId: 'TMiuzam',
                    }}
                    tableRow={{
                        additionalCells: ({ id }) => (
                            <TableCell
                                key={'actions'}
                                paddingRight={'0'}
                                testId="hP2vzZF"
                                width={24}
                            >
                                <ActionsWrapper>
                                    <IconButton
                                        iconProps={{
                                            icon: 'SvgEdit24',
                                            size: 24,
                                            themeColors: {
                                                default: {
                                                    'color-1':
                                                        'button.tertiary',
                                                },
                                                disabled: {
                                                    'color-1':
                                                        'button.tertiaryDisabled',
                                                },
                                                hover: {
                                                    'color-1':
                                                        'button.tertiaryHover',
                                                },
                                            },
                                        }}
                                        onClick={() =>
                                            navigate(
                                                internalPaths.guidelineEdit(id),
                                            )
                                        }
                                        testId="rm16878"
                                    />
                                </ActionsWrapper>
                            </TableCell>
                        ),
                        testId: '7yurzPq',
                    }}
                    tableState={{
                        emptyState: (
                            <GuidelineTableEmptyState
                                search={debouncedSearchPhrase}
                            />
                        ),
                        getQuerySorting,
                        getTableData: (props) =>
                            void getGuidelineData({
                                variables: {
                                    input: {
                                        paging: props.paging,
                                        search: props.searchPhrase,
                                        sorting:
                                            props.sorting as GuidelineSortInput[],
                                    },
                                },
                            }),
                        hiddenColumns: ['id'],
                        loading: loading || !called,
                        searchPhrase: debouncedSearchPhrase,
                    }}
                    testId="Vj_cTu4"
                />
            </GuidelineAndGlossaryCard>
        </SettingsLayout>
    );
};
