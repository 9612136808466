/* eslint-disable sort-keys-custom-order-fix/sort-keys-custom-order-fix */
export const internalPaths = {
    // auth
    passwordResetConfirm: '/password-reset/confirm',
    passwordResetEmail: '/password-reset/email-sent',
    login: '/',
    microservices: '/microservices',
    passwordReset: '/password-reset',

    // clients
    client: (clientId?: string) => `/clients/${clientId}`,
    clientDetails: (clientId?: string) => `/clients/${clientId}/details`,
    clientMicroservices: (clientId?: string) =>
        `/clients/${clientId}/microservices`,
    validationClient: '/clients/validation',
    addClient: '/clients/add',
    clients: '/clients',
    clientSubsidiarySites: (clientId?: string) =>
        `/clients/${clientId}/subsidiary-sites`,
    guidelineEdit: (guidelineId?: string) =>
        `/settings/guideline/${guidelineId}/edit`,

    //messaging
    messaging: '/incidents',

    // release note
    releaseNote: '/release-note',

    //settings
    esgProfile: '/settings/esg-profile',
    esgProfileTopic: (topic: string) => `/settings/esg-profile/${topic}`,
    esgActivityEdit: (topic: string, questionId: string) =>
        `/settings/esg-profile/${topic}/${questionId}`,
    tagManagement: '/settings/tag-management',
    other: '/settings/other',
    glossary: '/settings/glossary',
    guideline: '/settings/guideline',
    guidelineAdd: '/settings/guideline/add',

    //survey
    survey: '/survey',

    //pulseSurveys
    pulseSurveys: '/pulse-surveys',

    //declareAndDisclose
    declareAndDisclose: '/declare-and-disclosures',

    // others
    all: '*',
};
