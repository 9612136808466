import {
    Button,
    Modal,
    ModalComponent,
    notify,
    Tag,
    Typography,
} from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import {
    GroupTagDocument,
    GroupTagsDeleteDocument,
    GroupTagsRowsDocument,
} from '@graphql/generated/graphql';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { editedGroupTagIdVar } from '@boot/reactiveVariables';
import {
    ModalHeader,
    ModalTitleSecondary,
} from '@domains/clients/ions/Modal.styled';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';

import { TagsListContent, TagsListWrapper } from './GroupTagDeleteModal.styled';

const TAGS_MODAL_DISPLAY_LIMIT = 8;

interface GroupTagDeleteModalProps extends UseModal {
    closeEditModal: () => void;
}

export const GroupTagDeleteModal = ({
    closeEditModal,
    open,
    setOpen,
}: GroupTagDeleteModalProps) => {
    const editedGroupTagId = useReactiveVar(editedGroupTagIdVar);
    const { data } = useQuery(GroupTagDocument, {
        fetchPolicy: 'cache-and-network',
        variables: {
            uuid: editedGroupTagId,
            tagsInput: {
                paging: {
                    limit: TAGS_MODAL_DISPLAY_LIMIT,
                    offset: 1,
                },
            },
        },
    });

    const [groupTagDelete, { loading }] = useMutation(GroupTagsDeleteDocument, {
        context: {
            notifyOnError: true,
        },
        onError: () => {},
        refetchQueries: [GroupTagsRowsDocument],
    });

    const totalCount = data?.groupTag.tags?.totalCount ?? 0;
    const tagsCount = totalCount - TAGS_MODAL_DISPLAY_LIMIT;

    const handleClose = () => setOpen(false);

    const handleSubmit = () => {
        void groupTagDelete({
            onCompleted: () => {
                notify(SUCCESS_MESSAGES.GROUP_TAG_DELETE).INFO();
                handleClose();
                closeEditModal();
            },
            variables: {
                input: {
                    uuidsToDelete: [editedGroupTagId],
                },
            },
        });
    };

    return (
        <ModalComponent
            onClose={handleClose}
            open={open}
            preventCloseEsc
            preventCloseOutside
            width={648}
        >
            <Modal.Content>
                <ModalHeader>
                    <ModalTitleSecondary center>
                        Are you sure?
                    </ModalTitleSecondary>
                    <Modal.Description center color="black">
                        Are you sure you want to delete this group tag?
                        <br />
                        Please remember that if you delete this group tag, all
                        its tags will be removed too.
                    </Modal.Description>
                </ModalHeader>
                <TagsListContent>
                    <Typography.TextBody_14_20 fontWeight="bold">
                        Tags under this group
                    </Typography.TextBody_14_20>
                    <TagsListWrapper>
                        {data?.groupTag.tags.nodes.map(
                            ({ color, name, uuid }, index) => (
                                <Tag
                                    key={uuid}
                                    tagColor={color}
                                    tagName={name}
                                    testId={`tag.${index}.${name}`}
                                />
                            ),
                        )}
                    </TagsListWrapper>
                    {totalCount > TAGS_MODAL_DISPLAY_LIMIT && (
                        <Typography.TextBody_14_20>
                            +{tagsCount} more tag
                            {totalCount === TAGS_MODAL_DISPLAY_LIMIT + 1
                                ? ''
                                : 's'}{' '}
                            under this group
                        </Typography.TextBody_14_20>
                    )}
                </TagsListContent>
            </Modal.Content>
            <Modal.Footer>
                <Modal.ButtonsWrapper>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        testId="EbzGmgv"
                        variant={'secondary'}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        isLoading={loading}
                        onClick={handleSubmit}
                        testId="_Y91lIJ"
                        variant={'primary'}
                    >
                        Delete
                    </Button>
                </Modal.ButtonsWrapper>
            </Modal.Footer>
        </ModalComponent>
    );
};
