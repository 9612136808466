import { internalPaths } from './paths';

import { StorageManager } from '@speeki/storage-manager';

import { sentry } from '@services/Sentry';

import { client } from '@boot/apolloClient';

export const logout = (afterLogout?: () => void) => {
    StorageManager.removeValue('graphSessionToken');
    StorageManager.removeValue('graphRefreshToken');
    sentry.clearScope();
    void client.clearStore();
    afterLogout?.();

    const defaultPath = location.origin + internalPaths.login;

    if (location.href !== defaultPath)
        location.href = location.origin + internalPaths.login;
};
