import { Column } from 'react-table';

import { Esg_Subcategory } from '@graphql/generated/graphql';

export interface EsgProfileTableRow {
    id: string;
    label: string;
    riskArea: Esg_Subcategory;
}

export const headerData: Column<EsgProfileTableRow>[] = [
    { Header: 'Topic', accessor: 'label' },
];
