import { useMemo, useState } from 'react';

import { useDebounce } from '@speeki/react-hooks';

import { GuidelinesDocument } from '@graphql/generated/graphql';

import { useLazyQuery } from '@apollo/client';
import {
    GuidelineTableRow,
    headerData,
} from '@domains/settings/pages/GuidelinePage/GuidelineTablePage/GuidelineTablePage.settings';

export const useGuidelineTablePage = () => {
    const [searchPhrase, setSearchPhrase] = useState('');
    const [getGuidelineData, { called, data, loading }] = useLazyQuery(
        GuidelinesDocument,
        { fetchPolicy: 'cache-and-network' },
    );

    const transformedData: GuidelineTableRow[] =
        useMemo(
            () =>
                data?.guidelines.nodes.map((item) => {
                    return {
                        id: item.uuid,
                        category: item.categoryName,
                        order: item.order,
                        standard: item.standard.name,
                        subcategory: item.name,
                    };
                }),
            [data],
        ) || [];

    const debouncedSearchPhrase = useDebounce(searchPhrase, 500);

    return {
        called,
        debouncedSearchPhrase,
        getGuidelineData,
        headerData,
        loading,
        searchPhrase,
        setSearchPhrase,
        totalCount: data?.guidelines.totalCount,
        transformedData,
    };
};
