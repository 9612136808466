import styled, { css } from 'styled-components';

import { TextButton, Typography } from '@speeki/global-ui-components';
import { IconManager } from '@speeki/icons';

import { MainPagePanel as MainPagePanelElement } from '@components/ions/Pages.styled';

export const MainPagePanel = styled(MainPagePanelElement)`
    align-items: flex-start;
    flex-direction: column;
    gap: unset;
`;

export const PanelHeader = styled.div`
    align-items: center;
    display: flex;
    gap: 24px;
    margin-top: 32px;
    width: 100%;
`;

export const PanelActions = styled.div`
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 0 20px;
`;

export const DemoLabel = styled.div(
    ({ theme: { palette, radius } }) => css`
        background-color: ${palette.neutral.gray};
        border-radius: ${radius[8]}px;
        padding: 2px 16px;
    `,
);

export const DeactivateButton = styled(TextButton)(
    ({ theme: { palette } }) => css`
        color: ${palette.semantic.error};

        &:hover {
            color: ${palette.semantic.errorHover};
        }
    `,
);

export const DeletionInfoBox = styled.div(
    ({ theme: { palette } }) => css`
        align-items: center;
        background: ${palette.semantic.errorBg};
        display: flex;
        justify-content: center;
        padding: 8px 24px;
        margin-bottom: 18px;
    `,
);

export const DeletionText = styled(Typography.TextBody_14_20)(
    ({ theme: { palette } }) => css`
        color: ${palette.text.secondary};
    `,
);
export const DeletionIcon = styled(IconManager)`
    margin-right: 8px;
`;
