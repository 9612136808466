import styled from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

import { AuthPageWrapper } from '@components/ions/Pages.styled';

export const EmailSentPageWrapper = styled(AuthPageWrapper)`
    max-width: 530px;
`;

export const EmailSentImageWrapper = styled.div`
    margin-bottom: 40px;
`;

export const EmailSentTitle = styled(Typography.TextH3_24_34)`
    margin-bottom: 16px;
    text-align: center;
`;

export const EmailSentText = styled(Typography.TextH4_20_28)`
    margin-bottom: 16px;
    text-align: center;
`;
