import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import {
    Button,
    notify,
    PasswordField,
    TextField,
} from '@speeki/global-ui-components';
import { StorageManager } from '@speeki/storage-manager';

import { ButtonRow, FieldRow } from '@components/ions/Form.styled';

import {
    AuthMeDocument,
    CreateAdminTokenDocument,
} from '@graphql/generated/graphql';

import { sentry } from '@services/Sentry';

import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ERROR_KEYS } from '@shared/errorKeys';
import { FORM_VALIDATION_MESSAGES } from '@shared/formValidationMessages';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '@shared/notifyMessages';
import { internalPaths } from '@shared/paths';

import { LoginFormWrapper } from './LoginForm.styled';

export const LoginForm = () => {
    const navigate = useNavigate();
    const {
        formState: { errors, isSubmitted, isSubmitting, isValid },
        handleSubmit,
        register,
    } = useForm<LoginFormValues>({
        mode: 'onChange',
        resolver: yupResolver(LoginFormSchema),
    });

    const [authMe] = useLazyQuery(AuthMeDocument, {
        context: {
            logoutOnError: true,
        },
        fetchPolicy: 'network-only',
        onCompleted: () => {
            notify(SUCCESS_MESSAGES.LOGIN_SUCCESSFULLY).SUCCESS();
            navigate(internalPaths.clients);
        },
    });

    const [createAdminToken, { loading }] = useMutation(
        CreateAdminTokenDocument,
        {
            onCompleted: ({ adminToken: { refreshToken, sessionToken } }) => {
                StorageManager.setValue('graphSessionToken', sessionToken);
                StorageManager.setValue('graphRefreshToken', refreshToken);
                void sentry.setUser();

                void authMe();
            },
            onError: (error) => {
                notify(
                    error.message === ERROR_KEYS.INVALID_EMAIL_OR_PASSWORD
                        ? ERROR_MESSAGES.INVALID_EMAIL_OR_PASSWORD
                        : error.message,
                ).ERROR();
            },
        },
    );

    const isDisabled = isSubmitting || (!isValid && isSubmitted);
    const isLoading = isSubmitting || loading;

    const onSubmit = ({ email, password }: LoginFormValues) => {
        if (isDisabled) return;

        void createAdminToken({
            variables: {
                adminCredentialsInput: {
                    email: email,
                    password: password,
                },
            },
        });
    };

    return (
        <LoginFormWrapper noValidate onSubmit={handleSubmit(onSubmit)}>
            <FieldRow>
                <TextField
                    {...register('email')}
                    className="htmlToImageIgnore"
                    error={!!errors.email}
                    id={'login-email'}
                    label={'Email address'}
                    message={errors.email?.message}
                    placeholder={'Enter business email address'}
                    testId="cMXMqG1"
                    type={'email'}
                />
            </FieldRow>
            <FieldRow>
                <PasswordField
                    {...register('password')}
                    className="htmlToImageIgnore"
                    clickableLabel={{
                        onClick: () => navigate(internalPaths.passwordReset),
                        text: 'Forgot password',
                    }}
                    error={!!errors.password}
                    id={'login-password'}
                    label={'Password'}
                    message={errors.password?.message}
                    placeholder={'Enter password'}
                    testId="MHZAYD4"
                />
            </FieldRow>
            <ButtonRow>
                <Button
                    disabled={isDisabled}
                    fullWidth
                    isLoading={isLoading}
                    testId="rz7lxSd"
                    type={'submit'}
                    variant={'primary'}
                >
                    Log in
                </Button>
            </ButtonRow>
        </LoginFormWrapper>
    );
};

const LoginFormSchema = yup.object({
    email: yup
        .string()
        .required(FORM_VALIDATION_MESSAGES.FIELD_REQUIRED)
        .email(FORM_VALIDATION_MESSAGES.INVALID_EMAIL),
    password: yup.string().required(FORM_VALIDATION_MESSAGES.FIELD_REQUIRED),
});

interface LoginFormValues extends yup.InferType<typeof LoginFormSchema> {}
