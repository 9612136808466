import {
    FRONTEND_COUNTRY_NAMES_ORDERED,
    subscriptionPlanIterable,
} from '@speeki/dictionary';

export const countriesOptions = Object.entries(
    FRONTEND_COUNTRY_NAMES_ORDERED,
).map((country) => ({
    key: country[0],
    label: country[1],
    value: country[0],
}));

export const planOptions = Object.entries(subscriptionPlanIterable).map(
    (plan) => ({
        key: plan[1].key,
        label: plan[1].value,
        value: plan[1].key,
    }),
);
