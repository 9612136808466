import { memo } from 'react';

import {
    Button,
    NumberField,
    PaginatedSelectField,
    SelectField,
    SelectFieldOption,
    TextField,
} from '@speeki/global-ui-components';

import {
    FormButtonWrapper,
    GuidelineFormWrapper,
    MarkdownField,
    MarkdownSkeleton,
} from '@domains/settings/organisms/GuidelineAddEditForm/GuidelineAddEditForm.styled';
import { useGuidelineAddEditForm } from '@domains/settings/organisms/GuidelineAddEditForm/useGuidelineAddEditForm';

export const GuidelineAddEditForm = memo(() => {
    const {
        fields,
        form,
        guidelineId,
        inputOptions,
        isDisabled,
        isEngageSelected,
        isIFRSSelected,
        loading,
        markdown,
        selectedStandard,
        setMarkdown,
        upsertLoading,
    } = useGuidelineAddEditForm();

    const { industryField, sectorField, standardsField } = fields;
    const { sectorOptions, standardSectorsSelect, standardsOptions } =
        inputOptions;
    const { errors, handleFormSubmit, handleReset, register } = form;

    return (
        <>
            <GuidelineFormWrapper>
                <TextField
                    {...register('category')}
                    error={!!errors.category}
                    label={'Category'}
                    message={errors.category?.message}
                    placeholder={'Enter a category name'}
                    testId="0m2TZPk"
                />

                <TextField
                    {...register('subcategory')}
                    error={!!errors.subcategory}
                    label={'Subcategory'}
                    message={errors.subcategory?.message}
                    placeholder={'Enter a subcategory name'}
                    testId="J6dWOb0"
                />

                <SelectField
                    {...standardsField.field}
                    closeMenuOnSelect
                    error={!!errors.standard}
                    label={'Standard'}
                    message={errors.standard?.value?.message}
                    onChange={(newValue) => {
                        standardsField.field.onChange(newValue);
                        sectorField.field.onChange(null);
                        industryField.field.onChange(null);
                    }}
                    options={standardsOptions}
                    placeholder={'Select standard'}
                    testId="fZflp4S"
                />

                <NumberField
                    {...register('order')}
                    error={!!errors.order}
                    label={'Order'}
                    message={errors.order?.message}
                    placeholder={'Set the order'}
                    testId="VLJRVyX"
                />

                {!isEngageSelected && (
                    <PaginatedSelectField
                        {...sectorField.field}
                        {...standardSectorsSelect}
                        closeMenuOnSelect
                        error={!!errors.sector}
                        isDisabled={!selectedStandard}
                        key={selectedStandard}
                        label={'Sector'}
                        message={errors.sector?.value?.message}
                        onChange={(newValue) => {
                            sectorField.field.onChange(newValue);
                            industryField.field.onChange(null);
                        }}
                        placeholder={'Select sector'}
                        testId="y2E5thP"
                        value={sectorField.field.value as SelectFieldOption}
                    />
                )}

                {isIFRSSelected && (
                    <SelectField
                        {...industryField.field}
                        closeMenuOnSelect
                        error={!!errors.industry}
                        isDisabled={!sectorField.field.value}
                        label={'Industry'}
                        message={
                            errors.industry?.message ||
                            errors.industry?.value?.message
                        }
                        options={sectorOptions}
                        placeholder={'Select industry'}
                        testId="eqAr0ER"
                        value={industryField.field.value as SelectFieldOption}
                    />
                )}

                {loading ? (
                    <MarkdownSkeleton />
                ) : (
                    <MarkdownField
                        {...register('description')}
                        error={!!errors.description}
                        inputClassName={'markdown-wrapper'}
                        label={'Markdown'}
                        markdown={markdown}
                        message={errors.description?.message}
                        placeholder={'Enter Markdown'}
                        setMarkdown={setMarkdown}
                        testId="UN_NZck"
                    />
                )}
            </GuidelineFormWrapper>
            <FormButtonWrapper>
                {guidelineId && (
                    <Button
                        disabled={isDisabled}
                        onClick={handleReset}
                        size={'small'}
                        testId="3fSC0F1"
                        variant={'secondary'}
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    disabled={isDisabled}
                    isLoading={upsertLoading}
                    onClick={handleFormSubmit}
                    size={'small'}
                    testId="3fSC0F1"
                    variant={'primary'}
                >
                    Save
                </Button>
            </FormButtonWrapper>
        </>
    );
});
