import { SlotsTableRow, useSlotsTable } from './useSlotsTable';

import { TablePagination } from '@speeki/global-ui-components';
import { IconButton } from '@speeki/icons';

import { Table } from '@components/atoms/Table/Table';

import {
    EditIcon,
    EmptyStateWrapper,
    NoSlotsDescription,
    NoSlotsText,
    SlotsTableInnerWrapper,
    SlotsTableWrapper,
    StyledTableBodyCell,
    StyledTableBodyRow,
    StyledTableHeaderCell,
    TableHeaderSpace,
} from './SlotsTable.styled';

interface SlotsTableProps {
    openModal: (data: SlotsTableRow) => void;
}

export const SlotsTable = ({ openModal }: SlotsTableProps) => {
    const {
        canNextPage,
        canPreviousPage,
        getTableBodyProps,
        getTableProps,
        gotoPage,
        headerGroups,
        loading,
        nextPage,
        pageCount,
        prepareRow,
        previousPage,
        rows,
        setPageSize,
        state: { pageIndex, pageSize },
        totalCount,
    } = useSlotsTable();

    return (
        <SlotsTableWrapper>
            <SlotsTableInnerWrapper>
                <Table.Wrapper {...getTableProps()}>
                    <Table.Header>
                        {headerGroups.map((headerGroup) => (
                            <Table.HeaderRow
                                {...headerGroup.getHeaderGroupProps()}
                            >
                                {headerGroup.headers.map((column) => (
                                    <StyledTableHeaderCell
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps(),
                                        )}
                                        width={column.width}
                                    >
                                        <Table.HeaderCellContent>
                                            {column.render('Header')}
                                            {column.canSort &&
                                                (column.isSorted ? (
                                                    <Table.SortIcon
                                                        icon={
                                                            column.isSortedDesc
                                                                ? 'SvgChevronUp24'
                                                                : 'SvgChevronDown24'
                                                        }
                                                        size={24}
                                                    />
                                                ) : (
                                                    <Table.SortIcon
                                                        icon={
                                                            'SvgChevronUpAndDown24'
                                                        }
                                                        size={24}
                                                        themeColors={{
                                                            default: {
                                                                'color-1':
                                                                    'text.assistive',
                                                            },
                                                        }}
                                                    />
                                                ))}
                                        </Table.HeaderCellContent>
                                    </StyledTableHeaderCell>
                                ))}
                                <StyledTableHeaderCell width={40} />
                            </Table.HeaderRow>
                        ))}
                        <TableHeaderSpace />
                    </Table.Header>
                    <Table.Body {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);

                            return (
                                <StyledTableBodyRow {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <StyledTableBodyCell
                                            {...cell.getCellProps()}
                                        >
                                            <Table.BodyCellContent
                                                forwardedAs={'div'}
                                            >
                                                {cell.render('Cell')}
                                            </Table.BodyCellContent>
                                        </StyledTableBodyCell>
                                    ))}
                                    <StyledTableBodyCell>
                                        <EditIcon>
                                            <IconButton
                                                iconProps={{
                                                    icon: 'SvgEdit24',
                                                    size: 24,
                                                    themeColors: {
                                                        default: {
                                                            'color-1':
                                                                'tertiary.default',
                                                        },
                                                    },
                                                }}
                                                onClick={() =>
                                                    openModal(
                                                        row.values as SlotsTableRow,
                                                    )
                                                }
                                                testId="M_g3VRs"
                                            />
                                        </EditIcon>
                                    </StyledTableBodyCell>
                                </StyledTableBodyRow>
                            );
                        })}
                    </Table.Body>
                </Table.Wrapper>
            </SlotsTableInnerWrapper>

            <TablePagination
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                gotoPage={gotoPage}
                nextPage={nextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageSize={pageSize}
                previousPage={previousPage}
                setPageSize={setPageSize}
                testId="XJHBdUm"
                totalCount={totalCount ?? 0}
            />

            {!loading && !totalCount && (
                <EmptyStateWrapper>
                    <NoSlotsText>Slots data not entered</NoSlotsText>
                    <NoSlotsDescription>
                        Add some data of IBM Watson bot responses.
                    </NoSlotsDescription>
                </EmptyStateWrapper>
            )}
        </SlotsTableWrapper>
    );
};
