import { useFormContext } from 'react-hook-form';

import { TextField } from '@speeki/global-ui-components';

import { WatsonCredentialsFormValues } from '@domains/clients/organisms/WatsonCredentialsModal/WatsonCredentialsModal.tsx';

import {
    DividedFieldRow,
    WatsonCredentialsFormWrapper,
} from './WatsonCredentialsForm.styled';

export const WatsonCredentialsForm = () => {
    const {
        formState: { errors },
        register,
    } = useFormContext<WatsonCredentialsFormValues>();

    return (
        <WatsonCredentialsFormWrapper>
            <DividedFieldRow>
                <TextField
                    {...register('apiKey')}
                    error={!!errors.apiKey}
                    id={'api-key'}
                    label={'Api key *'}
                    message={errors.apiKey?.message}
                    placeholder={'Enter api key'}
                    testId="Fa7WUC3"
                />
                <TextField
                    {...register('instanceUrl')}
                    error={!!errors.instanceUrl}
                    id={'instance-url'}
                    label={'Instance url *'}
                    message={errors.instanceUrl?.message}
                    placeholder={'Enter instance url'}
                    testId="LdPkOEV"
                />
            </DividedFieldRow>
            <DividedFieldRow>
                <TextField
                    {...register('assistantId')}
                    error={!!errors.assistantId}
                    id={'assistant-id'}
                    label={'Assistant ID *'}
                    message={errors.assistantId?.message}
                    placeholder={'Enter assistant ID'}
                    testId="XeC1ZOb"
                />
                <TextField
                    {...register('workspaceName')}
                    error={!!errors.workspaceName}
                    id={'workspace-name'}
                    label={'Workspace name'}
                    message={errors.workspaceName?.message}
                    placeholder={'Enter workspace name'}
                    testId="OQXXA1L"
                />
            </DividedFieldRow>
            <DividedFieldRow>
                <TextField
                    {...register('workspaceId')}
                    error={!!errors.workspaceId}
                    id={'workspace-id'}
                    label={'Workspace ID'}
                    message={errors.workspaceId?.message}
                    placeholder={'Enter workspace ID'}
                    testId="SNxVZGV"
                />
            </DividedFieldRow>
        </WatsonCredentialsFormWrapper>
    );
};
