import { Column } from 'react-table';

export interface GuidelineTableRow {
    category: string;
    id: string;
    order: number | null;
    standard: string;
    subcategory?: string | null;
}

export const headerData: Column<GuidelineTableRow>[] = [
    { Header: 'Standard', accessor: 'standard' },
    {
        Header: 'Category',
        accessor: 'category',
    },
    {
        Header: 'Subcategory',
        accessor: 'subcategory',
    },
    {
        Header: 'Order',
        accessor: 'order',
        width: 50,
    },
];
