import { EXPECTED_GRAPHQL_ERRORS } from '@speeki/dictionary';
import { __DEV__, resolveEnv } from '@speeki/frontend-utils';
import { PasswordRegExpManager } from '@speeki/utils';

import { ApolloError } from '@apollo/client';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function resolveAssetUrl(url: string): any {
    return __DEV__ ? url : resolveEnv('PUBLIC_URL') + url;
}

export const getSvgSize = (width: number, height: number, size?: number) => {
    const ratio = size ? Math.min(size / height, size / width) : 1;
    return {
        height: height * ratio,
        viewBox: `0 0 ${width} ${height}`,
        width: width * ratio,
    };
};

export const toCapitalize = (text: string) => {
    return text.charAt(0) + text.slice(1).toLowerCase();
};

export const toCamelCase = (text: string) =>
    text
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (_m, ch: string) => ch.toUpperCase());

export const toKebabCase = (text: string) =>
    text.toLowerCase().replaceAll('_', '-');

export const toSnakeUpperCase = (text: string) =>
    text
        .replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toUpperCase())
        .join('_');

export const sortArray = (a: string, b: string) => {
    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
};

export const getErrorCode = (error: ApolloError) =>
    error.graphQLErrors[0]?.extensions?.code as
        | EXPECTED_GRAPHQL_ERRORS
        | undefined;

export const getCountriesFromDict = (dict: Record<string, string>) => {
    const data = Object.keys(dict)
        .map((key) => ({
            label: dict[key],
            value: key,
        }))
        .filter(({ value }) => value !== 'ALL');

    return data;
};

export const passwordValid = (password?: string) =>
    !!(
        password &&
        PasswordRegExpManager.lowercase.test(password) &&
        PasswordRegExpManager.min_max_length.test(password) &&
        PasswordRegExpManager.min_number.test(password) &&
        PasswordRegExpManager.min_symbol.test(password) &&
        PasswordRegExpManager.uppercase.test(password)
    );
