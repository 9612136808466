import { useNavigate, useParams } from 'react-router-dom';

import { CardDataRow } from '@components/atoms/CardDataRow/CardDataRow';

import { ClientDetailsDocument } from '@graphql/generated/graphql';

import { countriesOptions } from '@utils/dictionary';

import { useQuery } from '@apollo/client';
import { Card } from '@domains/clients/ions/Card.styled';
import { internalPaths } from '@shared/paths';

interface CompanyInformationProps {
    openModal: () => void;
}

export const CompanyInformation = ({ openModal }: CompanyInformationProps) => {
    const navigate = useNavigate();
    const { clientId } = useParams<{ clientId: string }>();
    const { data } = useQuery(ClientDetailsDocument, {
        context: {
            notifyOnError: true,
        },
        onError: () => navigate(internalPaths.clients),
        variables: {
            disableTaskManager: false,
            tenantUuid: clientId || '',
        },
    });

    const currentCountry = countriesOptions.find(
        (country) => country.value === data?.clientDetails.country,
    );

    return (
        <>
            <Card.Header gap={'16px'} marginBottom={'16px'}>
                <Card.SectionName>Company information</Card.SectionName>
                <Card.EditButton
                    icon={'SvgEdit24'}
                    iconProps={{ onClick: openModal }}
                    size={24}
                    themeColors={{
                        default: { 'color-1': 'main.tertiary' },
                    }}
                />
            </Card.Header>
            <Card.CardData>
                <CardDataRow
                    name={'Company name:'}
                    value={data?.clientDetails.name}
                />

                <CardDataRow
                    name={'Region:'}
                    value={data?.clientDetails.region}
                />
                <CardDataRow name={'Country:'} value={currentCountry?.label} />
            </Card.CardData>
        </>
    );
};
