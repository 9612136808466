import styled from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

import {
    MainPagePanel as MainPagePanelElement,
    MainPageWrapper as MainPageWrapperElement,
} from '@components/ions/Pages.styled';

export const MainPageWrapper = styled(MainPageWrapperElement)`
    grid-template-rows: 184px 1fr;
`;

export const MainPagePanel = styled(MainPagePanelElement)`
    align-items: flex-start;
    flex-direction: column;
`;

export const SettingsName = styled(Typography.TextH1_36_52)`
    margin-top: 48px;
`;
