import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
    dataTypes,
    isLegacyTypes,
    SlotTypeOptions,
    slotTypes,
} from 'src/domains/messaging/pages/MessagingPage/useMessagingPage';

import {
    SelectField,
    TextareaField,
    TextField,
} from '@speeki/global-ui-components';

import { FieldRow } from '@components/ions/Form.styled';

import { SlotFormValues } from '@domains/messaging/organisms/SlotModal/SlotModal.tsx';

import { DividedFieldRow, SlotFormWrapper } from './SlotForm.styled';

interface SlotFormProps {
    isUpdate: boolean;
}

export const SlotForm = ({ isUpdate }: SlotFormProps) => {
    const {
        control,
        formState: { errors },
        register,
        setValue,
        watch,
    } = useFormContext<SlotFormValues>();

    const { field: isLegacySelect } = useController({
        control,
        name: 'isLegacy',
    });
    const { field: legacyTypeSelect } = useController({
        control,
        name: 'legacyType',
    });
    const { field: dataTypeSelect } = useController({
        control,
        name: 'dataType',
    });

    const isLegacyValue = watch('isLegacy');
    const legacyTypeValue = watch('legacyType');

    useEffect(() => {
        if (isLegacyValue?.value === false) {
            setValue(
                'legacyType',
                slotTypes.find(
                    (key) => key.value === 'NONE',
                ) as SlotTypeOptions,
                { shouldDirty: false, shouldValidate: true },
            );
        }
        if (
            isLegacyValue?.value === true &&
            legacyTypeValue?.value === 'NONE'
        ) {
            // @ts-expect-error TODO: Type problem
            setValue('legacyType', null);
        }
    }, [isLegacyValue]);

    return (
        <SlotFormWrapper>
            <DividedFieldRow>
                <TextField
                    {...register('slotId')}
                    disabled={isUpdate}
                    error={!!errors.slotId}
                    id={'slot-id'}
                    label={'Slot ID *'}
                    message={errors.slotId?.message}
                    placeholder={'Enter slot ID'}
                    testId="0nPM20U"
                />
                <TextField
                    {...register('actionId')}
                    disabled={isUpdate}
                    error={!!errors.actionId}
                    id={'action-id'}
                    label={'Action ID *'}
                    message={errors.actionId?.message}
                    placeholder={'Enter action ID'}
                    testId="X4UOyMb"
                />
            </DividedFieldRow>
            <FieldRow>
                <TextareaField
                    {...register('text')}
                    error={!!errors.text}
                    id={'text'}
                    label={'Text *'}
                    maxLength={500}
                    message={errors.text?.message}
                    placeholder={'Enter text'}
                    testId="rDEqTEu"
                />
            </FieldRow>
            <DividedFieldRow>
                <SelectField
                    {...isLegacySelect}
                    error={!!errors.isLegacy?.value?.message}
                    id={'isLegacy'}
                    isClearable={false}
                    label={'Legacy *'}
                    message={errors.isLegacy?.value?.message}
                    // @ts-expect-error TODO: Type problem
                    options={isLegacyTypes}
                    placeholder={'Select legacy'}
                    testId="nN6DB4T"
                />
                {/** @ts-expect-error value type is not proper because of optional select notRequired fields */}
                <SelectField
                    {...legacyTypeSelect}
                    error={!!errors.legacyType?.value?.message}
                    id={'legacy-type'}
                    isDisabled={!isLegacyValue?.value}
                    label={'Legacy type'}
                    message={errors.legacyType?.value?.message}
                    options={
                        !isLegacyValue?.value
                            ? slotTypes
                            : slotTypes.filter((key) => key.value !== 'NONE')
                    }
                    placeholder={'Select type'}
                    testId="W3-ETeB"
                />
                {/** @ts-expect-error value type is not proper because of optional select notRequired fields */}
                <SelectField
                    {...dataTypeSelect}
                    error={!!errors.dataType?.value?.message}
                    id={'data-type'}
                    label={'Slot data type'}
                    message={errors.dataType?.value?.message}
                    options={dataTypes}
                    placeholder={'Select data type'}
                    testId="M7R37Zm"
                />
            </DividedFieldRow>
        </SlotFormWrapper>
    );
};
