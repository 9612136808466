import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
    Button,
    Modal,
    ModalComponent,
    notify,
} from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import { SubscriptionInformationForm } from '@components/organisms/SubscriptionInformationForm/SubscriptionInformationForm';

import {
    Subscription_Plan,
    UpdateTenantSubscriptionDocument,
} from '@graphql/generated/graphql';

import { planOptions } from '@utils/dictionary';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';
import { requiredSelect } from '@shared/validations';

interface SubscriptionInformationModalProps extends UseModal {
    defaultData?: {
        id: string;
        limitedLicenses: number;
        plan: string;
        standardLicenses: number;
        subscriberEmail?: string | null;
        subscriptionEnd?: Date | null;
        subscriptionStart?: Date | null;
    } | null;
    tenantUuid: string;
}

export const SubscriptionInformationModal = ({
    defaultData,
    open,
    setOpen,
    tenantUuid,
}: SubscriptionInformationModalProps) => {
    const currentPlan = planOptions.find(
        (plan) => plan.key === defaultData?.plan,
    );

    const methods = useForm<SubscriptionInformationFormValues>({
        defaultValues: {
            limitedLicenses: defaultData?.limitedLicenses.toString(),
            plan: currentPlan,
            standardLicenses: defaultData?.standardLicenses.toString(),
            subscriberEmail: defaultData?.subscriberEmail || '',
            subscriptionEnd: defaultData?.subscriptionEnd
                ? dayjs(defaultData?.subscriptionEnd).toDate()
                : undefined,
            subscriptionStart: defaultData?.subscriptionStart
                ? dayjs(defaultData?.subscriptionStart).toDate()
                : undefined,
        },
        mode: 'onChange',
        resolver: yupResolver(SubscriptionInformationSchema),
    });

    const {
        formState: { isSubmitted, isSubmitting, isValid },
        handleSubmit,
        reset,
    } = methods;

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    const [updateTenantSubscription, { loading }] = useMutation(
        UpdateTenantSubscriptionDocument,
        {
            context: {
                notifyOnError: true,
            },
            onCompleted: () => {
                handleClose?.();
                notify(SUCCESS_MESSAGES.TENANT_SUBSCRIPTION_UPDATED).SUCCESS();
            },
            onError: () => {},
        },
    );

    const onSubmit = (data: SubscriptionInformationFormValues) => {
        void updateTenantSubscription({
            variables: {
                input: {
                    limitedLicenseCount: Number(data.limitedLicenses),
                    plan: data.plan.value as Subscription_Plan,
                    standardLicenseCount: Number(data.standardLicenses),
                    subscriberEmailAddress: data.subscriberEmail,
                    subscriptionEndDate: data.subscriptionEnd,
                    subscriptionStartDate: data.subscriptionStart,
                    tenantUuid,
                },
            },
        });
    };

    return (
        <ModalComponent onClose={handleClose} open={open}>
            <Modal.Wrapper>
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>Subscription information</Modal.Title>
                        <Modal.SubTitle>
                            Edit tenant’s subscription information.
                        </Modal.SubTitle>
                    </Modal.Header>
                    <FormProvider {...methods}>
                        <SubscriptionInformationForm />
                    </FormProvider>
                </Modal.Content>
                <Modal.Footer>
                    <Modal.ButtonsWrapper>
                        <Button
                            onClick={handleClose}
                            size={'small'}
                            testId="xN8TAZL"
                            variant={'secondary'}
                        >
                            cancel
                        </Button>
                        <Button
                            disabled={!isValid && isSubmitted}
                            isLoading={loading || isSubmitting}
                            onClick={handleSubmit(onSubmit)}
                            size={'small'}
                            testId="1RmqM5M"
                            variant={'primary'}
                        >
                            save
                        </Button>
                    </Modal.ButtonsWrapper>
                </Modal.Footer>
            </Modal.Wrapper>
        </ModalComponent>
    );
};

const SubscriptionInformationSchema = yup.object({
    limitedLicenses: yup.string().required('This field cannot be empty'),
    plan: requiredSelect,
    standardLicenses: yup.string().required('This field cannot be empty'),
    subscriberEmail: yup
        .string()
        .email('Invalid email address. The correct format is: name@example.com')
        .required('This field cannot be empty'),
    subscriptionEnd: yup
        .date()
        .required('This field cannot be empty')
        .when(
            'subscriptionStart',
            (started, yup) =>
                started &&
                yup.min(started, 'End time cannot be before start time'),
        ),
    subscriptionStart: yup
        .date()
        .required('This field cannot be empty')
        .max(new Date(), 'Future date not allowed'),
});

export type SubscriptionInformationFormValues = yup.InferType<
    typeof SubscriptionInformationSchema
>;
