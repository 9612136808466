import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';
import { IconManager } from '@speeki/icons';

const Wrapper = styled.table`
    border-collapse: separate;
    border-spacing: 0 4px;
    width: 100%;
`;

const Header = styled.thead``;

const HeaderRow = styled.tr``;

const HeaderCell = styled.th<{ width?: number | string }>`
    ${({ width }) => css`
        padding-right: 16px;
        text-align: left;
        ${width && `width: ${width}px;`}}

        &:first-child {
            padding-left: 24px;
        }
    `}
`;

const HeaderCellContent = styled(Typography.TextBody_14_20)(
    ({ theme: { font, fontWeight } }) => css`
        align-items: center;
        display: flex;
        font-family: ${font.secondary};
        font-weight: ${fontWeight.bold};
        user-select: none;
    `,
);

const Body = styled.tbody``;

const BodyRow = styled.tr(
    ({ theme: { boxShadow, colors } }) => css`
        background: ${colors.white};
        box-shadow: ${boxShadow.small};
        cursor: pointer;
        position: relative;

        &:hover {
            box-shadow: ${boxShadow.big};
            z-index: 9;
        }
    `,
);

const BodyCell = styled.td(
    ({ theme: { radius } }) => css`
        padding-bottom: 20px;
        padding-right: 16px;
        padding-top: 21px;

        &:first-child {
            border-radius: ${radius[4]}px 0 0 ${radius[4]}px;
            padding-left: 24px;
        }

        &:last-child {
            border-radius: 0 ${radius[4]}px ${radius[4]}px 0;
        }
    `,
);

const BodyCellContent = styled(Typography.TextBody_14_20)`
    display: flex;
`;

const SortIcon = styled(IconManager)`
    margin-left: 4px;
`;

export const Table = {
    Body,
    BodyCell,
    BodyCellContent,
    BodyRow,
    Header,
    HeaderCell,
    HeaderCellContent,
    HeaderRow,
    SortIcon,
    Wrapper,
};
