import { rgba } from 'polished';
import styled, { css, keyframes } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

const ModalFadeIn = keyframes`
    from{
        opacity: 0
    }
    to{
        opacity: 1
    }
`;

export const MarkdownSyntaxModalWrapper = styled.div(
    ({ theme: { palette } }) => css`
        align-items: center;
        animation: ${ModalFadeIn} 0.3s forwards;
        background-color: ${rgba(palette.secondary.default, 0.85)};
        display: flex;
        height: 100vh;
        justify-content: center;
        left: 0;
        margin: 0 auto;
        position: fixed;
        top: 0;
        width: 100vw;
        will-change: opacity;
        z-index: 999;
    `,
);

export const MarkdownSyntaxModalInner = styled.div(
    ({ theme: { palette } }) => css`
        background: ${palette.neutral.white};
        position: relative;
        width: 984px;
    `,
);

export const MarkdownSyntaxModalContent = styled.div`
    padding: 32px 42px;
`;

export const MarkdownSyntaxModalTitle = styled(Typography.TextH3_24_34)`
    margin-bottom: 8px;
`;

export const CloseButtonWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 24px;
`;

// SYNTAX
export const MarkdownSyntaxList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 368px;
    margin-top: 32px;
    overflow: auto;
`;

export const MarkdownSyntaxListItem = styled.div`
    align-items: flex-start;
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

export const ItalicTypography = styled(Typography.TextBody_16_24)`
    font-style: italic;
`;

export const StrikethroughTypography = styled(Typography.TextBody_16_24)`
    text-decoration: line-through;
`;

export const MarkdownLink = styled.a(
    ({ theme: { font, fontSize, palette } }) => css`
        color: ${palette.tertiary.default};
        font-family: ${font.primary};
        font-size: ${fontSize[16]}px;
        letter-spacing: -0.18px;
        text-decoration: none;
    `,
);

export const BlockquoteTypography = styled.blockquote(
    ({ theme: { palette } }) => css`
        border-left: 3px solid ${palette.primary.default};
        margin: 0;
        padding-left: 8px;
    `,
);

export const OrderedList = styled.ol(
    ({ theme: { font, fontSize, palette } }) => css`
        color: ${palette.text.primary};
        font-family: ${font.primary};
        margin: 0;
        padding-left: 18px;

        li {
            font-family: ${font.primary};
            font-size: ${fontSize[16]}px;
            line-height: 24px;
        }
    `,
);

export const UnorderedList = styled.ul(
    ({ theme: { font, fontSize, palette } }) => css`
        color: ${palette.text.primary};
        font-family: ${font.primary};
        margin: 0;
        padding-left: 18px;

        li {
            font-family: ${font.primary};
            font-size: ${fontSize[16]}px;
            line-height: 24px;
        }
    `,
);
