import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { notify } from '@speeki/global-ui-components';

import {
    BannedEmailUpsertDocument,
    BlockedDomainsListDocument,
} from '@graphql/generated/graphql';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegExpManager } from '@shared/constants';
import { FORM_VALIDATION_MESSAGES } from '@shared/formValidationMessages';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';

interface UseBlockedDomainsUpsertModalArgs {
    closeModal: () => void;
    values?: BlockedDomainsFormValues;
}

export const useBlockedDomainsUpsertModal = ({
    closeModal,
    values,
}: UseBlockedDomainsUpsertModalArgs) => {
    const [blockedDomainUpsert, { loading }] = useMutation(
        BannedEmailUpsertDocument,
        {
            context: {
                notifyOnError: true,
            },
            onCompleted: () => {
                notify(
                    SUCCESS_MESSAGES[
                        !!values ? 'BLOCKED_DOMAIN_EDIT' : 'BLOCKED_DOMAIN_ADD'
                    ],
                ).SUCCESS();
                closeModal();
            },
            onError: () => {},
            refetchQueries: !!values ? undefined : [BlockedDomainsListDocument],
        },
    );

    const {
        formState: { errors, isDirty, isValid },
        handleSubmit: handleFormSubmit,
        register,
    } = useForm<BlockedDomainsFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(BlockedDomainsFormSchema),
        values,
    });

    const handleSubmit = handleFormSubmit(({ domain, enabled, uuid }) => {
        void blockedDomainUpsert({
            variables: {
                input: {
                    uuid,
                    email: domain,
                    isActive: enabled ?? true,
                },
            },
        });
    });

    return {
        errors,
        handleSubmit,
        isDirty,
        isValid,
        loading,
        register,
    };
};

const BlockedDomainsFormSchema = yup.object({
    uuid: yup.string().optional(),
    domain: yup
        .string()
        .matches(RegExpManager.domainName, {
            message: FORM_VALIDATION_MESSAGES.INVALID_DOMAIN,
        })
        .trim()
        .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
    enabled: yup.boolean().optional(),
});

export type BlockedDomainsFormValues = yup.InferType<
    typeof BlockedDomainsFormSchema
>;
