import styled, { css } from 'styled-components';

export const ClientsTableWrapper = styled.div(
    ({ theme: { colors } }) => css`
        margin-top: 50px;

        tr {
            td:first-child {
                color: ${colors.tertiary};
            }
        }
    `,
);
