import { delay } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { notify } from '@speeki/global-ui-components';
import { isWysiwygEditorEmpty } from '@speeki/wysiwyg-editor';

import {
    ReleaseNoteDocument,
    ReleaseNoteQuery,
    ReleaseNotesUpdateDocument,
} from '@graphql/generated/graphql';

import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';

enum RELEASE_OPTIONS {
    NEW_FEATURE = 'NEW_FEATURE',
    UPDATE = 'UPDATE',
}

interface ReleaseOption {
    label: string;
    value: RELEASE_OPTIONS;
}

const releaseOptions: ReleaseOption[] = [
    {
        label: 'New feature',
        value: RELEASE_OPTIONS.NEW_FEATURE,
    },
    {
        label: 'Update',
        value: RELEASE_OPTIONS.UPDATE,
    },
];

export const useReleaseNotePage = () => {
    const [wysiwygLoading, setWysiwygLoading] = useState(true);
    const [isEdited, setIsEdited] = useState(false);
    const [rerender, setRerender] = useState(false);

    const titleRef = useRef<HTMLInputElement>(null);

    const [releaseNotesUpdate, { loading: updateLoading }] = useMutation(
        ReleaseNotesUpdateDocument,
        {
            context: {
                notifyOnError: true,
            },
            onError: () => {},
            update: (cache, { data }) => {
                const releaseNote = data?.releaseNoteUpsert;
                if (!releaseNote) return;

                cache.writeQuery<ReleaseNoteQuery>({
                    data: {
                        releaseNote,
                    },
                    query: ReleaseNoteDocument,
                });
            },
        },
    );

    const { data } = useQuery(ReleaseNoteDocument, {
        context: {
            notifyOnError: true,
        },
    });
    const releaseData = data?.releaseNote;
    const isPublished = !!releaseData?.isPublished;

    const {
        control,
        formState: { dirtyFields, errors, isValid },
        handleSubmit,
        register,
        reset,
    } = useForm<ReleaseNoteFormValues>({
        defaultValues: {
            wysiwyg: '<p><br></p>',
        },
        mode: 'onChange',
        resolver: yupResolver(ReleaseNoteSchema),
    });

    const isDirty = !!Object.keys(dirtyFields).length;

    const onSubmit = handleSubmit((data) => {
        void releaseNotesUpdate({
            onCompleted: () => {
                notify(SUCCESS_MESSAGES.RELEASE_NOTES_UPDATED).SUCCESS();
                data.version === '' && setRerender((prevValue) => !prevValue);
            },
            variables: {
                input: {
                    content: data.wysiwyg,
                    isNewFeature: data.type.some(
                        (option) =>
                            option.value === RELEASE_OPTIONS.NEW_FEATURE,
                    ),
                    isPublished: false,
                    isUpdate: data.type.some(
                        (option) => option.value === RELEASE_OPTIONS.UPDATE,
                    ),
                    title: data.title,
                    version: data.version ?? null,
                },
            },
        });
    });

    const changeStatus = () => {
        releaseData &&
            void releaseNotesUpdate({
                onCompleted: ({ releaseNoteUpsert: { isPublished } }) => {
                    isPublished &&
                        notify(
                            SUCCESS_MESSAGES.RELEASE_NOTES_PUBLISHED,
                        ).SUCCESS();
                },
                variables: {
                    input: {
                        content: releaseData.content,
                        isNewFeature: releaseData.isNewFeature,
                        isPublished: !isPublished,
                        isUpdate: releaseData.isUpdate,
                        title: releaseData.title,
                        version: releaseData.version,
                    },
                },
            });
    };

    const onReset = () => reset();

    useEffect(() => {
        isEdited && titleRef.current?.focus();
    }, [isEdited]);

    useEffect(() => {
        if (releaseData) {
            const type: ReleaseOption[] = [];
            if (releaseData.isNewFeature) type.push(releaseOptions[0]);
            if (releaseData.isUpdate) type.push(releaseOptions[1]);

            delay(() => {
                reset({
                    title: releaseData.title,
                    type,
                    version: releaseData.version ?? '',
                    wysiwyg: releaseData.content as string,
                });
                setWysiwygLoading(false);
            }, 10);
        }
    }, [releaseData, rerender]);

    return {
        changeStatus,
        control,
        errors,
        isDirty,
        isEdited,
        isPublished,
        isValid,
        onReset,
        onSubmit,
        register,
        releaseOptions,
        setIsEdited,
        titleRef,
        updateLoading,
        wysiwygLoading,
    };
};

const ReleaseNoteSchema = yup.object({
    title: yup.string().required('This field cannot be empty'),
    type: yup
        .array()
        .of(
            yup.object({
                label: yup.string().required(),
                value: yup.string().required(),
            }),
        )
        .required('This field cannot be empty')
        .min(1, 'This field cannot be empty'),
    version: yup.string().optional(),
    wysiwyg: yup
        .string()
        .required('This field cannot be empty')
        .test(
            'required',
            'This field cannot be empty',
            (value) => !!(value && !isWysiwygEditorEmpty(value)),
        ),
});

type ReleaseNoteFormValues = yup.InferType<typeof ReleaseNoteSchema>;
