import { Column } from 'react-table';

import { TableCellText } from '@domains/settings/ions/Table.styled';

interface RowElement {
    enabled: boolean;
    text: string;
}

export interface AdminUsersCardTableRow {
    email: RowElement;
    firstName: RowElement;
    id: string;
    lastName: RowElement;
    status: boolean;
}

export const adminUsersHeaderData: Column<AdminUsersCardTableRow>[] = [
    {
        Cell: ({ value: { enabled, text } }) => (
            <TableCellText disabled={!enabled}>{text}</TableCellText>
        ),
        Header: 'First name',
        accessor: 'firstName',
        disableSortBy: true,
    },
    {
        Cell: ({ value: { enabled, text } }) => (
            <TableCellText disabled={!enabled}>{text}</TableCellText>
        ),
        Header: 'Last name',
        accessor: 'lastName',
        disableSortBy: true,
    },
    {
        Cell: ({ value: { enabled, text } }) => (
            <TableCellText disabled={!enabled}>{text}</TableCellText>
        ),
        Header: 'Email address',
        accessor: 'email',
        disableSortBy: true,
    },
];
