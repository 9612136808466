import { SvgProps } from '@@types/CommonTypes';

import { getSvgSize } from '@utils/helpers';

export const EmailSent = ({ size }: SvgProps) => (
    <svg {...getSvgSize(89, 89, size)} fill="none">
        <circle cx={44.5} cy={44.5} r={41} stroke="#00EC7D" strokeWidth={7} />
        <path
            d="M39.051 59.46a8.078 8.078 0 0 1-5.75-2.383l-6.846-6.846a6.163 6.163 0 1 1 8.708-8.722l3.861 3.861L53.84 30.556a6.162 6.162 0 1 1 8.709 8.721l-17.8 17.8a8.078 8.078 0 0 1-5.697 2.383Z"
            fill="#00135D"
        />
    </svg>
);
