import { capitalize } from 'lodash-es';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Button, TextField, Typography } from '@speeki/global-ui-components';

import { DividedFieldRow } from '@components/ions/Form.styled';
import {
    MainPageContent,
    MainPagePanel,
    MainPageWrapper,
} from '@components/ions/Pages.styled';
import { ErrorBox } from '@components/molecules/ErrorBox/ErrorBox';
import { MainLayout } from '@components/templates/MainLayout/MainLayout';

import { ValidateClientSignUpDocument } from '@graphql/generated/graphql';

import { ApolloError, useLazyQuery } from '@apollo/client';
import { DetailedAction } from '@domains/clients/molecules/DetailedAction/DetailedAction';
import { CancelAddNewClientModal } from '@domains/clients/organisms/CancelAddNewClientModal/CancelAddNewClientModal';
import {
    NewClientButtonWrapper,
    NewClientTitleWrapper,
    SignUpInformation,
} from '@domains/clients/pages/AddNewClient/AddNewClient.styled';
import {
    DomainIcon,
    DomainLoader,
    VerificationLoader,
    VerificationText,
    VerificationTextWrapper,
    VerificationWrapper,
} from '@domains/clients/pages/AddNewClient/ValidateClientPage/ValidateClientPage.styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_VALIDATION_MESSAGES } from '@shared/formValidationMessages';
import { internalPaths } from '@shared/paths';

type ValidateClientStatus =
    | 'PENDING'
    | 'COMPANY_ALREADY_EXISTS'
    | 'INVALID_EMAIL'
    | 'ACCOUNT_ALREADY_EXISTS'
    | 'SUBSCRIPTION_EXPIRED'
    | 'SUCCESS';

type GraphValidateClientStatus = Exclude<
    ValidateClientStatus,
    'PENDING' | 'SUCCESS'
>;

const supportedErrorCodes: Exclude<
    ValidateClientStatus,
    'PENDING' | 'SUCCESS'
>[] = [
    'ACCOUNT_ALREADY_EXISTS',
    'COMPANY_ALREADY_EXISTS',
    'INVALID_EMAIL',
    'SUBSCRIPTION_EXPIRED',
];

interface Location {
    companyName: string;
    email: string;
}

export const ValidateClientPage = () => {
    const [validateClientStatus, setValidateClientStatus] = useState<
        ValidateClientStatus | undefined
    >();
    const location = useLocation();
    const state = location.state as Location;
    const navigate = useNavigate();

    const {
        formState: { errors, isDirty, isSubmitted },
        getValues,
        handleSubmit,
        register,
    } = useForm<ValidateClientFormValues>({
        defaultValues: {
            companyName: state?.companyName,
            email: state?.email,
        },
        mode: 'onTouched',
        resolver: yupResolver(ValidateClientSchema),
    });

    const shouldBlockRedirect = isDirty && !isSubmitted;

    const [getValidateClientSignUp, { error, loading }] = useLazyQuery(
        ValidateClientSignUpDocument,
        {
            context: {
                globalUrl: true,
                notifyOnError: true,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onError: () => {},
        },
    );

    const errorMessageDisplay = (error: ApolloError) => {
        switch (validateClientStatus) {
            case 'ACCOUNT_ALREADY_EXISTS':
                return 'The account with this email address already exists.';
            case 'COMPANY_ALREADY_EXISTS':
                return 'This company has registered before.';
            case 'INVALID_EMAIL':
                return 'The email you entered does not look like a valid business email.';
            case 'SUBSCRIPTION_EXPIRED':
                return 'This account has expired';
            default:
                return capitalize(error.message);
        }
    };

    return (
        <MainLayout>
            <MainPageWrapper>
                <MainPagePanel>
                    <NewClientTitleWrapper>
                        <Typography.TextH1_36_52>
                            Add new client
                        </Typography.TextH1_36_52>
                        <SignUpInformation>
                            1 of 2 | Client’s verification
                        </SignUpInformation>
                    </NewClientTitleWrapper>
                    <NewClientButtonWrapper>
                        <Button
                            disabled={loading}
                            isLoading={loading}
                            onClick={handleSubmit((data) => {
                                setValidateClientStatus('PENDING');

                                void getValidateClientSignUp({
                                    onCompleted: () => {
                                        const values = getValues();

                                        setTimeout(() => {
                                            navigate(internalPaths.addClient, {
                                                state: {
                                                    companyName:
                                                        values.companyName,
                                                    email: values.email,
                                                },
                                            });
                                        }, 1800);
                                        setValidateClientStatus('SUCCESS');
                                    },
                                    onError: (error) => {
                                        const errorCode = error.graphQLErrors[0]
                                            ?.extensions
                                            ?.code as GraphValidateClientStatus;
                                        if (
                                            supportedErrorCodes.includes(
                                                errorCode,
                                            )
                                        )
                                            setValidateClientStatus(errorCode);
                                    },
                                    variables: { input: data },
                                });
                            })}
                            testId="lzSPCKl"
                            variant={'primary'}
                        >
                            Next
                        </Button>
                    </NewClientButtonWrapper>
                </MainPagePanel>
                <MainPageContent>
                    <DetailedAction
                        description={
                            "Enter email address and company name of the client. When you click 'Next' to proceed to the next step, the system will verify the provided data."
                        }
                        title={'General information'}
                    >
                        {error && (
                            <ErrorBox>{errorMessageDisplay(error)}</ErrorBox>
                        )}
                        <DividedFieldRow>
                            <TextField
                                {...register('email')}
                                error={!!errors.email}
                                label={'Email address'}
                                message={errors.email?.message}
                                placeholder={'Enter business email address'}
                                testId="_AcLnY2"
                            />

                            <TextField
                                {...register('companyName')}
                                error={!!errors.companyName}
                                label={'Company name'}
                                message={errors.companyName?.message}
                                placeholder={'Enter company name'}
                                testId="qSoeQae"
                            />
                        </DividedFieldRow>
                    </DetailedAction>
                </MainPageContent>
            </MainPageWrapper>
            {(loading || validateClientStatus === 'SUCCESS') && (
                <VerificationLoader>
                    <VerificationWrapper>
                        {loading ? (
                            <DomainLoader color={'white'} size={116} />
                        ) : (
                            <DomainIcon
                                icon={'SvgTick24'}
                                size={125}
                                themeColors={{
                                    default: {
                                        'color-1': 'neutral.white',
                                    },
                                }}
                            />
                        )}

                        <VerificationTextWrapper>
                            <VerificationText>
                                {loading
                                    ? 'We are verifying client’s email address...'
                                    : 'Client’s email address has been successfully verified'}
                            </VerificationText>
                        </VerificationTextWrapper>
                    </VerificationWrapper>
                </VerificationLoader>
            )}
            <CancelAddNewClientModal when={shouldBlockRedirect} />
        </MainLayout>
    );
};

const ValidateClientSchema = yup.object({
    companyName: yup
        .string()
        .required(FORM_VALIDATION_MESSAGES.FIELD_REQUIRED)
        .min(
            3,
            FORM_VALIDATION_MESSAGES.FIELD_TOO_SHORT({
                fieldName: 'Company name',
                limitCharacter: 3,
            }),
        )
        .max(
            36,
            FORM_VALIDATION_MESSAGES.FIELD_TOO_LONG({
                fieldName: 'Company name',
                limitCharacter: 36,
            }),
        ),
    email: yup
        .string()
        .required(FORM_VALIDATION_MESSAGES.FIELD_REQUIRED)
        .email(FORM_VALIDATION_MESSAGES.INVALID_EMAIL)
        .max(
            64,
            FORM_VALIDATION_MESSAGES.FIELD_TOO_LONG({
                fieldName: 'Email address',
                limitCharacter: 64,
            }),
        ),
});

type ValidateClientFormValues = yup.InferType<typeof ValidateClientSchema>;
