import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { client } from 'src/boot/apolloClient';
import { persistor, store } from 'src/boot/configureStore';

import { AppLoading } from './AppLoading';

import { FeatureFlagsProvider } from '@speeki/feature-flags';
import { WebThemeProvider } from '@speeki/theme';

import { GlobalStyled } from '@components/ions/Global.styled';

import { router } from '@services/RouteService/router';

import { ApolloProvider } from '@apollo/client';

export const AppInitializer = () => {
    return (
        <FeatureFlagsProvider>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ApolloProvider client={client}>
                        <WebThemeProvider variant={'default'}>
                            <GlobalStyled />
                            <AppLoading>
                                <RouterProvider router={router} />
                            </AppLoading>
                        </WebThemeProvider>
                    </ApolloProvider>
                </PersistGate>
            </Provider>
        </FeatureFlagsProvider>
    );
};
