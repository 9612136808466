import styled, { css } from 'styled-components';

import { TextButton } from '@speeki/global-ui-components';

import { HeaderWrapper } from '@domains/settings/pages/GuidelineAndGlossaryPage.styled';

export const HeaderInfoWrapper = styled(HeaderWrapper)`
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
`;
export const HeaderDeleteButton = styled(TextButton)(
    ({ theme: { palette } }) => css`
        color: ${palette.semantic.error};

        &:hover {
            color: ${palette.semantic.errorHover};
        }
    `,
);
