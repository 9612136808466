import { Button, Modal, ModalComponent } from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

interface DisableSubsidiarySiteModalProps extends UseModal {
    disableModule?: boolean;
    onConfirm: () => void;
}

export const DisableSubsidiarySiteModal = ({
    disableModule,
    onConfirm,
    open,
    setOpen,
}: DisableSubsidiarySiteModalProps) => {
    const onClose = () => setOpen(false);

    return (
        <ModalComponent
            onClose={onClose}
            open={open}
            preventCloseEsc
            preventCloseOutside
            width={648}
        >
            <Modal.Content>
                <Modal.TitleSecondary>
                    {disableModule
                        ? 'Disable ‘Manage entities’ microservice'
                        : 'Disable entity'}
                </Modal.TitleSecondary>
                <Modal.SubTitle color="black">
                    {disableModule
                        ? 'You are about to disable the entities for this client. When disabling entities, you need to manually unlink the settings associated with each of the sites before disabling them. Please contact client to deassign users, business units prior to disabling the microservice. Are you sure you want to disable this microservice now?'
                        : 'When disabling a entity, you need to manually unlink the settings associated with this site before disabling it. Please contact client to deassign users, business units prior to disabling the entity. Are you sure you want to disable this site now?'}
                </Modal.SubTitle>
            </Modal.Content>
            <Modal.Footer>
                <Modal.ButtonsWrapper>
                    <Button
                        onClick={onClose}
                        size={'small'}
                        testId="X5RwcTX"
                        variant={'secondary'}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onConfirm}
                        size={'small'}
                        testId="4g_h7MW"
                        variant={'primary'}
                    >
                        Confirm
                    </Button>
                </Modal.ButtonsWrapper>
            </Modal.Footer>
        </ModalComponent>
    );
};
