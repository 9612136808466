import { createUploadLink } from 'apollo-upload-client';

import {
    createAuthLink,
    createErrorLink,
    createLogoutLink,
    createRefreshLink,
    CreateRefreshLink,
    createReplaceTokensLink,
} from '@speeki/apollo-utils';
import { TOKEN_EXPIRED } from '@speeki/dictionary';
import { createSentryLink } from '@speeki/frontend-sentry';
import { resolveEnv } from '@speeki/frontend-utils';
import { notify } from '@speeki/global-ui-components';
import { StorageManager } from '@speeki/storage-manager';

import { RefreshAdminTokenDocument } from '@graphql/generated/graphql';

import { sentry } from '@services/Sentry';

import { from } from '@apollo/client';
import { client } from '@boot/apolloClient';
import { logout } from '@shared/logout';

const headers = { 'Apollo-Require-Preflight': 'true' };

const directionalLink = createUploadLink({
    headers,
    uri: (operation) =>
        `${resolveEnv('REACT_APP_GRAPHQL')}?${operation.operationName}`,
});

const allowedErrors = [TOKEN_EXPIRED];

const errorLink = createErrorLink({
    allowedErrors,
    onNotify: (message) => notify(message).ERROR(),
});

const fetchNewSessionToken: CreateRefreshLink['fetchNewSessionToken'] = (
    refreshToken,
) =>
    client
        .mutate({
            mutation: RefreshAdminTokenDocument,
            variables: {
                refreshToken,
            },
        })
        .then((res) => {
            const data = res.data?.refreshAdminSession;

            data?.sessionToken &&
                StorageManager.setValue('graphSessionToken', data.sessionToken);
            data?.refreshToken &&
                StorageManager.setValue('graphRefreshToken', data.refreshToken);
        });

export const links = from([
    createRefreshLink({
        fetchNewSessionToken,
        getRefreshToken: () => StorageManager.getValue('graphRefreshToken'),
        onRefreshError: () => {
            logout();
        },
        onTokenExpiredNoRefresh: () => {
            logout();
        },
    }),
    createLogoutLink({
        onLogout: () => {
            logout();
        },
    }),
    createReplaceTokensLink({
        getRefreshToken: () => StorageManager.getValue('graphRefreshToken'),
        setRefreshToken: (token) =>
            StorageManager.setValue('graphRefreshToken', token),
        setSessionToken: (token) => {
            StorageManager.setValue('graphSessionToken', token);
            void sentry.setUser();
        },
    }),
    createAuthLink({
        getSessionToken: () => StorageManager.getValue('graphSessionToken'),
    }),
    errorLink,
    createSentryLink(sentry),
    //@ts-expect-error: lack of types
    directionalLink,
]);
