import { gql } from '@apollo/client';

export const localTypeDefs = gql`
    extend type ClientUser {
        fullName: String!
    }
    extend type RiskArea {
        label: String!
    }
`;
