import { SvgProps } from '@@types/CommonTypes';

import { getSvgSize } from '@utils/helpers';

export const IconMessagingWhite = ({ size }: SvgProps) => (
    <svg {...getSvgSize(24, 24, size)} fill="none">
        <path
            d="M4.016 20h7.988a8 8 0 1 0-7.988-8.503V20Zm7.988-14.667a6.667 6.667 0 1 1 0 13.334H5.349V11.56a6.679 6.679 0 0 1 6.655-6.228Z"
            fill="#FFFFFF"
        />
        <path
            d="M12.666 12.667h-4V14h4v-1.333ZM15.333 10H8.666v1.333h6.667V10Z"
            fill="#FFFFFF"
        />
    </svg>
);
