import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { notify } from '@speeki/global-ui-components';

import { RequestPasswordResetDocument } from '@graphql/generated/graphql';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_VALIDATION_MESSAGES } from '@shared/formValidationMessages';
import { INFO_MESSAGES } from '@shared/notifyMessages';
import { internalPaths } from '@shared/paths';

export const usePasswordResetForm = () => {
    const navigate = useNavigate();

    const [requestPasswordReset, { loading }] = useMutation(
        RequestPasswordResetDocument,
        {
            context: { notifyOnError: true },
            onCompleted: () => {
                notify(INFO_MESSAGES.EMAIL_SENT).INFO();
            },
        },
    );

    const {
        formState: { errors, isSubmitted, isSubmitting, isValid },
        handleSubmit: handleFormSubmit,
        register,
    } = useForm<ResetPasswordFormValues>({
        resolver: yupResolver(ResetPasswordSchema),
    });

    const handleSubmit = handleFormSubmit((data: ResetPasswordFormValues) => {
        void requestPasswordReset({
            onCompleted: () =>
                navigate(internalPaths.passwordResetEmail, {
                    state: { email: data.email },
                }),
            variables: { input: { email: data.email ?? '' } },
        });
    });

    return {
        errors,
        handleSubmit,
        isSubmitted,
        isSubmitting,
        isValid,
        loading,
        register,
    };
};

const ResetPasswordSchema = yup.object({
    email: yup
        .string()
        .required(FORM_VALIDATION_MESSAGES.FIELD_REQUIRED)
        .email(FORM_VALIDATION_MESSAGES.INVALID_EMAIL),
});

interface ResetPasswordFormValues
    extends yup.InferType<typeof ResetPasswordSchema> {}
