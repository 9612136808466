import { useState } from 'react';

import { Button } from '@speeki/global-ui-components';
import { useModal } from '@speeki/react-hooks';

import {
    MainPageContent,
    MainPageName,
    MainPagePanel,
    MainPageWrapper,
} from '@components/ions/Pages.styled';
import { SlotsTable } from '@components/organisms/SlotsTable/SlotsTable';
import { SlotsTableRow } from '@components/organisms/SlotsTable/useSlotsTable';
import { MainLayout } from '@components/templates/MainLayout/MainLayout';

import { SlotModal } from '@domains/messaging/organisms/SlotModal/SlotModal';

import { HeaderWrapper, TableTitle } from './MessagingPage.styled';

export const MessagingPage = () => {
    const slotModal = useModal();

    const [modalData, setModalData] = useState<SlotsTableRow | undefined>();

    const openModal = (data: SlotsTableRow) => {
        setModalData(data);
        slotModal.setOpen(true);
    };

    const onClose = () => setModalData(undefined);

    return (
        <MainLayout>
            <MainPageWrapper>
                <MainPagePanel>
                    <MainPageName>Incidents</MainPageName>
                </MainPagePanel>
                <MainPageContent>
                    <HeaderWrapper>
                        <TableTitle>Slots</TableTitle>
                        <Button
                            onClick={() => slotModal.setOpen(true)}
                            testId="01oI0R7"
                            variant={'primary'}
                        >
                            add new slot
                        </Button>
                    </HeaderWrapper>
                    <SlotsTable openModal={openModal} />
                </MainPageContent>
                {slotModal.open && (
                    <SlotModal
                        {...slotModal}
                        defaultValues={modalData}
                        onClose={onClose}
                    />
                )}
            </MainPageWrapper>
        </MainLayout>
    );
};
