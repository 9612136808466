import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

import { globalFonts } from '@components/ions/fonts.tsx';

export const GlobalStyled = createGlobalStyle(
    ({ theme: { colors, font } }) => css`
        ${normalize};
        ${globalFonts}

        * {
            box-sizing: border-box;
        }

        html {
            min-height: 100vh;
        }

        body {
            background: ${colors.violet_bg};
            color: ${colors.black};
            font-family: ${font.primary};
            margin: 0;
            min-height: 100vh;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        #root {
            min-height: 100vh;
        }

        code {
            font-family: 'Lato', source-code-pro, Menlo, Monaco, Consolas,
                'Courier New', monospace;
        }

        .pointerEventsEnable {
            pointer-events: all;
        }

        #tooltip {
            position: relative;
            z-index: 99999;
        }

        #modal {
            position: relative;
            z-index: 9999;
        }
    `,
);
