import {
    AdminUsersUpsertFormValues,
    useAdminUsersUpsertModal,
} from './useAdminUsersUpsertModal';

import {
    Button,
    InputCheck,
    Modal,
    ModalComponent,
    PasswordField,
    TextButton,
    TextField,
} from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import { FormDeleteButton } from '@components/atoms/FormDeleteButton/FormDeleteButton';
import {
    DividedFieldRow,
    FormWrapper,
    PasswordFieldWrapper,
} from '@components/ions/Form.styled';

import { ModalTitleSecondary } from '@domains/settings/ions/Modal.styled';
import { passwordRequirements } from '@shared/passwordRequirements';

import {
    FormContentWrapper,
    PasswordConfirmSectionWrapper,
} from './AdminUsersUpsertModal.styled';

interface AdminUsersUpsertModalProps extends UseModal {
    editedUserData?: AdminUsersUpsertFormValues;
    onCancel: () => void;
}

export const AdminUsersUpsertModal = ({
    editedUserData,
    onCancel,
    open,
    setOpen,
}: AdminUsersUpsertModalProps) => {
    const handleClose = () => {
        setOpen(false);
        onCancel();
    };

    const {
        dirtyFields,
        errors,
        handleDeletePasswordFields,
        handleSubmit,
        isDirty,
        isSubmitted,
        isValid,
        loading,
        passwordFieldValue,
        register,
        setShowPasswordFields,
        showPasswordFields,
    } = useAdminUsersUpsertModal({
        closeModal: handleClose,
        values: editedUserData,
    });

    return (
        <ModalComponent
            onClose={handleClose}
            open={open}
            preventCloseOutside
            width={984}
        >
            <Modal.Wrapper>
                <FormWrapper onSubmit={handleSubmit}>
                    <Modal.Content>
                        <ModalTitleSecondary>
                            {editedUserData
                                ? 'Edit admin user'
                                : 'Add new admin user'}
                        </ModalTitleSecondary>
                        {!editedUserData && (
                            <Modal.SubTitle>
                                Provide the first name, last name, email address
                                of the new admin user, and set a password for
                                their account.
                            </Modal.SubTitle>
                        )}
                        <FormContentWrapper>
                            <DividedFieldRow>
                                <TextField
                                    {...register(`firstName`)}
                                    error={!!errors.firstName}
                                    label={'First name'}
                                    message={errors.firstName?.message}
                                    placeholder={'Enter user’s first name'}
                                    testId="1PRWuXI"
                                />
                                <TextField
                                    {...register(`lastName`)}
                                    error={!!errors.lastName}
                                    label={'Last name'}
                                    message={errors.lastName?.message}
                                    placeholder={'Enter user’s last name'}
                                    testId="UjAcryo"
                                />
                            </DividedFieldRow>
                            <DividedFieldRow>
                                <TextField
                                    {...register(`email`)}
                                    error={!!errors.email}
                                    label={'Email address'}
                                    message={errors.email?.message}
                                    placeholder={'Enter user’s email address'}
                                    testId="Twxay-D"
                                />
                            </DividedFieldRow>
                            {!showPasswordFields && editedUserData && (
                                <TextButton
                                    iconProps={{
                                        icon: 'SvgPassword24',
                                        size: 24,
                                        themeColors: {
                                            default: {
                                                'color-1': 'tertiary.default',
                                            },
                                            hover: {
                                                'color-1': 'tertiary.hover',
                                            },
                                        },
                                    }}
                                    onClick={() => setShowPasswordFields(true)}
                                    testId="LqSPYgz"
                                >
                                    Set up new password
                                </TextButton>
                            )}
                            {(!editedUserData || showPasswordFields) && (
                                <DividedFieldRow>
                                    <PasswordFieldWrapper>
                                        <PasswordField
                                            {...register('password')}
                                            className="htmlToImageIgnore"
                                            error={!!errors.password}
                                            label={'Password'}
                                            message={errors.password?.message}
                                            placeholder={'Enter password'}
                                            testId="L2Nxi-a"
                                        />
                                        {!!passwordFieldValue && (
                                            <InputCheck
                                                isDirty={dirtyFields.password}
                                                isSubmitted={isSubmitted}
                                                passwordValue={
                                                    passwordFieldValue
                                                }
                                                requirements={
                                                    passwordRequirements
                                                }
                                                testId="p8LVHK2"
                                            />
                                        )}
                                    </PasswordFieldWrapper>
                                    <PasswordConfirmSectionWrapper
                                        editUser={!!editedUserData}
                                    >
                                        <PasswordField
                                            {...register('passwordConfirm')}
                                            className="htmlToImageIgnore"
                                            error={!!errors.passwordConfirm}
                                            label={'Confirm password'}
                                            message={
                                                errors.passwordConfirm?.message
                                            }
                                            placeholder={'Re-enter password'}
                                            testId="dGojTZl"
                                        />
                                        {editedUserData && (
                                            <FormDeleteButton
                                                handleRemove={
                                                    handleDeletePasswordFields
                                                }
                                            />
                                        )}
                                    </PasswordConfirmSectionWrapper>
                                </DividedFieldRow>
                            )}
                        </FormContentWrapper>
                    </Modal.Content>
                    <Modal.Footer>
                        <Modal.ButtonsWrapper>
                            <Button
                                disabled={loading}
                                onClick={handleClose}
                                size={'small'}
                                testId="56FA0rk"
                                variant={'secondary'}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={!isDirty || !isValid}
                                isLoading={loading}
                                size={'small'}
                                testId="d-LUB3s"
                                type={'submit'}
                                variant={'primary'}
                            >
                                Save
                            </Button>
                        </Modal.ButtonsWrapper>
                    </Modal.Footer>
                </FormWrapper>
            </Modal.Wrapper>
        </ModalComponent>
    );
};
