import { useNavigate, useParams } from 'react-router-dom';

import { notify } from '@speeki/global-ui-components';

import { GuidelineDeleteDocument } from '@graphql/generated/graphql';

import { useMutation } from '@apollo/client';
import { GuidelineAddEditForm } from '@domains/settings/organisms/GuidelineAddEditForm/GuidelineAddEditForm';
import {
    BackButton,
    GuidelineAndGlossaryCard,
    GuidelineAndGlossaryDescription,
    GuidelineAndGlossaryTitle,
} from '@domains/settings/pages/GuidelineAndGlossaryPage.styled';
import {
    HeaderDeleteButton,
    HeaderInfoWrapper,
} from '@domains/settings/pages/GuidelinePage/GuidelineEditPage/GuidelineEditPage.styled';
import { SettingsLayout } from '@domains/settings/templates/SettingsLayout/SettingsLayout';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';
import { internalPaths } from '@shared/paths';

export const GuidelineEditPage = () => {
    const navigate = useNavigate();
    const { guidelineId } = useParams<{ guidelineId: string }>();

    const [guidelineDelete] = useMutation(GuidelineDeleteDocument, {
        context: { notifyOnError: true },
        onError: () => {},
        update: (cache, { data }) => {
            data?.guidelineDelete.forEach((item) => {
                const normalizedId = cache.identify({
                    id: item,
                    __typename: 'Guideline',
                });
                cache.evict({ id: normalizedId });
            });
            cache.gc();
        },
    });

    const handleDelete = () => {
        void guidelineDelete({
            onCompleted: () => {
                navigate(internalPaths.guideline);
                notify(SUCCESS_MESSAGES.GUIDELINE_DELETE).SUCCESS();
            },
            variables: {
                input: {
                    uuidsToDelete: guidelineId ? [guidelineId] : [],
                },
            },
        });
    };

    return (
        <SettingsLayout>
            <GuidelineAndGlossaryCard>
                <HeaderInfoWrapper>
                    <div>
                        <BackButton
                            iconProps={{
                                icon: 'SvgArrowLeft24',
                                size: 24,
                                themeColors: {
                                    default: {
                                        'color-1': 'tertiary.default',
                                    },
                                },
                            }}
                            onClick={() => navigate(internalPaths.guideline)}
                            testId="DCtFimA"
                            type={'button'}
                        >
                            Back to Guidelines
                        </BackButton>
                        <GuidelineAndGlossaryTitle>
                            Edit a term
                        </GuidelineAndGlossaryTitle>
                        <GuidelineAndGlossaryDescription>
                            Please use the fields below to edit a term.
                        </GuidelineAndGlossaryDescription>
                    </div>
                    <HeaderDeleteButton
                        iconProps={{
                            icon: 'SvgDelete24',
                            size: 24,
                            themeColors: {
                                default: {
                                    'color-1': 'semantic.error',
                                },
                                hover: {
                                    'color-1': 'semantic.errorHover',
                                },
                            },
                        }}
                        onClick={handleDelete}
                        testId="DCtFimA"
                        type={'button'}
                    >
                        Delete
                    </HeaderDeleteButton>
                </HeaderInfoWrapper>
                <GuidelineAddEditForm />
            </GuidelineAndGlossaryCard>
        </SettingsLayout>
    );
};
