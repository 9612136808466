import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const SidebarWrapper = styled.div(
    ({ theme: { colors } }) => css`
        background: ${colors.secondary};
        grid-area: sidebar;
    `,
);

export const LogoWrapper = styled.div`
    justify-content: center;
    display: flex;
    margin-top: 16px;
`;

export const SidebarMenu = styled.div`
    display: flex;
    margin-top: 100px;
    flex-direction: column;
`;

export const IconWrapper = styled.div`
    margin-right: 12px;
`;

export const MenuItemText = styled(Typography.TextBody_14_24)(
    ({ theme: { colors, font, fontWeight } }) => css`
        color: ${colors.text_white};
        font-family: ${font.primary};
        font-weight: ${fontWeight.normal};
    `,
);

export const SidebarMenuItem = styled(NavLink)(
    ({ theme: { colors } }) => css`
        align-items: center;
        color: ${colors.text_white};
        display: flex;
        padding: 12px 0 12px 32px;
        position: relative;
        text-decoration: none;

        &:hover {
            background: ${colors.secondary_hover};
        }

        &.active {
            background: ${colors.secondary_hover};

            &:before {
                background: ${colors.primary};
                content: '';
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                width: 3px;
            }
        }
    `,
);
