import { useParams } from 'react-router-dom';

import { Button, Typography } from '@speeki/global-ui-components';
import { useModal } from '@speeki/react-hooks';

import { SubsidiarySitesTotalCountDocument } from '@graphql/generated/graphql';

import { useQuery } from '@apollo/client';
import { Card } from '@domains/clients/ions/Card.styled';
import { SubsidiarySiteModal } from '@domains/clients/organisms/SubsidiarySiteModal/SubsidiarySiteModal';
import { SubsidiaryTable } from '@domains/clients/organisms/SubsidiaryTable/SubsidiaryTable';

export const SubsidiaryTab = () => {
    const { clientId } = useParams<{ clientId: string }>();
    const addModal = useModal();

    const { data } = useQuery(SubsidiarySitesTotalCountDocument, {
        variables: {
            tenantId: clientId ?? '',
        },
    });

    return (
        <>
            <Card.Wrapper>
                <Card.InnerWrapper>
                    <Card.Header justifyContent={'space-between'}>
                        <Typography.TextH2_28_44>
                            {data?.subsidiary.totalCount === 0
                                ? 'Add entity'
                                : 'Entities'}
                        </Typography.TextH2_28_44>
                        <Button
                            iconProps={{
                                icon: 'SvgPlus',
                                size: 12,
                                themeColors: {
                                    default: { 'color-1': 'secondary.default' },
                                },
                            }}
                            onClick={() => addModal.setOpen(true)}
                            size={'small'}
                            testId="zYcAKHh"
                            variant={'secondary'}
                        >
                            Add new
                        </Button>
                    </Card.Header>
                    {!!data?.subsidiary.totalCount && (
                        <>
                            <Card.Row />
                            <SubsidiaryTable />
                        </>
                    )}
                </Card.InnerWrapper>
            </Card.Wrapper>

            {addModal.open && <SubsidiarySiteModal {...addModal} />}
        </>
    );
};
