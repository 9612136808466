import { useNavigate } from 'react-router-dom';

import { GuidelineAddEditForm } from '@domains/settings/organisms/GuidelineAddEditForm/GuidelineAddEditForm';
import {
    BackButton,
    GuidelineAndGlossaryCard,
    GuidelineAndGlossaryDescription,
    GuidelineAndGlossaryTitle,
    HeaderWrapper,
} from '@domains/settings/pages/GuidelineAndGlossaryPage.styled';
import { SettingsLayout } from '@domains/settings/templates/SettingsLayout/SettingsLayout';
import { internalPaths } from '@shared/paths';

export const GuidelineAddPage = () => {
    const navigate = useNavigate();

    return (
        <SettingsLayout>
            <GuidelineAndGlossaryCard>
                <HeaderWrapper>
                    <BackButton
                        iconProps={{
                            icon: 'SvgArrowLeft24',
                            size: 24,
                            themeColors: {
                                default: {
                                    'color-1': 'tertiary.default',
                                },
                            },
                        }}
                        onClick={() => navigate(internalPaths.guideline)}
                        testId="DCtFimA"
                        type={'button'}
                    >
                        Back to Guidelines
                    </BackButton>
                    <GuidelineAndGlossaryTitle>
                        Add a term
                    </GuidelineAndGlossaryTitle>
                    <GuidelineAndGlossaryDescription>
                        Please use the fields below to add a term.
                    </GuidelineAndGlossaryDescription>
                </HeaderWrapper>
                <GuidelineAddEditForm />
            </GuidelineAndGlossaryCard>
        </SettingsLayout>
    );
};
