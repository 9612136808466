import styled, { css } from 'styled-components';

export const PageNameWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
`;

export const AddTitleText = styled.span(
    ({ theme: { palette } }) => css`
        color: ${palette.text.assistive};
        cursor: pointer;
    `,
);

export const EditButtonWrapper = styled.div`
    padding: 0 18px;
`;

export const TitleInputWrapper = styled.div`
    flex-grow: 1;
    margin-left: 10px;
`;

export const WysiwygWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    width: 100%;
`;

export const SwitchWrapper = styled.div`
    flex-shrink: 0;
`;

export const ReleaseInfoWrapper = styled.div(
    ({ theme: { palette } }) => css`
        align-items: center;
        align-self: flex-start;
        background-color: ${palette.tertiary.background};
        display: flex;
        gap: 8px;
        height: 52px;
        padding: 0 24px;
    `,
);

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
`;
