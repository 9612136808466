import { Outlet, ScrollRestoration } from 'react-router-dom';

import { ToastNotification } from '@speeki/global-ui-components';

import { Wrapper } from './App.styled';

export const App = () => (
    <Wrapper>
        <ScrollRestoration />
        <Outlet />
        <ToastNotification />
    </Wrapper>
);
