import { PasswordRegExpManager } from '@speeki/utils';

interface InputRequirement {
    regexp: RegExp;
    title: string;
}

export const passwordRequirements: InputRequirement[] = [
    {
        regexp: PasswordRegExpManager.lowercase,
        title: 'At least 1 lower case',
    },
    {
        regexp: PasswordRegExpManager.min_max_length,
        title: 'Contain between 8 and 64 characters',
    },
    {
        regexp: PasswordRegExpManager.min_number,
        title: 'At least 1 number',
    },
    {
        regexp: PasswordRegExpManager.min_symbol,
        title: 'At least 1 special character',
    },
    {
        regexp: PasswordRegExpManager.uppercase,
        title: 'At least 1 upper case',
    },
];
