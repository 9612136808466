import { ReactNode } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { Tabs } from '@speeki/global-ui-components';

import { MainPageContent } from '@components/ions/Pages.styled';
import { MainLayout } from '@components/templates/MainLayout/MainLayout';

import {
    MainPagePanel,
    MainPageWrapper,
    SettingsName,
} from '@domains/settings/templates/SettingsLayout/SettingsLayout.styled';
import { internalPaths } from '@shared/paths';

interface SettingsLayoutProps {
    children: ReactNode;
}

export const SettingsLayout = ({ children }: SettingsLayoutProps) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const match = (path: string) => !!matchPath(path, pathname);

    return (
        <MainLayout>
            <MainPageWrapper>
                <MainPagePanel>
                    <SettingsName>Settings</SettingsName>
                    <Tabs
                        data={[
                            {
                                active: match(internalPaths.tagManagement),
                                name: 'Tag management',
                                onClick: () =>
                                    navigate(internalPaths.tagManagement),
                                testId: '6Mdwgbl',
                            },
                            {
                                active: match(`${internalPaths.esgProfile}/*`),
                                name: 'ESG profile',
                                onClick: () =>
                                    navigate(internalPaths.esgProfile),
                                testId: 'zBQc-9D',
                            },
                            {
                                active: match(`${internalPaths.guideline}/*`),
                                name: 'Guideline',
                                onClick: () =>
                                    navigate(internalPaths.guideline),
                                testId: 'YfDbGy4',
                            },
                            {
                                active: match(internalPaths.glossary),
                                name: 'Glossary',
                                onClick: () => navigate(internalPaths.glossary),
                                testId: '9JGFVab',
                            },
                            {
                                active: match(internalPaths.other),
                                name: 'Other',
                                onClick: () => navigate(internalPaths.other),
                                testId: 'PfzXg7P',
                            },
                        ]}
                        testId="QKdZXm5"
                    />
                </MainPagePanel>
                <MainPageContent>{children}</MainPageContent>
            </MainPageWrapper>
        </MainLayout>
    );
};
