import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
    Button,
    Modal,
    ModalComponent,
    notify,
} from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import { WatsonCredentialsForm } from '@components/organisms/WatsonCredentialsForm/WatsonCredentialsForm';

import {
    ClientDetailsDocument,
    UpdateTenantCredentialsDocument,
} from '@graphql/generated/graphql';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';

interface WatsonCredentialsModalProps extends UseModal {
    defaultData?: {
        apiKey: string;
        assistantId: string;
        instanceUrl: string;
        tenantUuid: string;
        uuid: string;
        workspaceId?: string | null;
        workspaceName?: string | null;
    } | null;
    tenantUuid: string;
}

export const WatsonCredentialsModal = ({
    defaultData,
    open,
    setOpen,
    tenantUuid,
}: WatsonCredentialsModalProps) => {
    const methods = useForm<WatsonCredentialsFormValues>({
        defaultValues: {
            apiKey: defaultData?.apiKey,
            assistantId: defaultData?.assistantId,
            instanceUrl: defaultData?.instanceUrl,
            workspaceId: defaultData?.workspaceId || '',
            workspaceName: defaultData?.workspaceName || '',
        },
        mode: 'onChange',
        resolver: yupResolver(WatsonCredentialsSchema),
    });

    const {
        formState: { isSubmitted, isSubmitting, isValid },
        handleSubmit,
        reset,
    } = methods;

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    const [updateTenantCredentials, { loading }] = useMutation(
        UpdateTenantCredentialsDocument,
        {
            context: {
                notifyOnError: true,
            },
            onCompleted: () => {
                handleClose();
                notify(
                    SUCCESS_MESSAGES.CREDENTIALS_SUCCESSFULLY_CHANGED,
                ).SUCCESS();
            },
            onError: () => {},
            refetchQueries: [
                { query: ClientDetailsDocument, variables: { tenantUuid } },
            ],
        },
    );

    const onSubmit = (data: WatsonCredentialsFormValues) => {
        void updateTenantCredentials({
            variables: {
                input: {
                    apiKey: data.apiKey,
                    assistantId: data.assistantId,
                    instanceUrl: data.instanceUrl,
                    tenantUuid,
                    workspaceId: data.workspaceId,
                    workspaceName: data.workspaceName,
                },
            },
        });
    };

    return (
        <ModalComponent onClose={handleClose} open={open}>
            <Modal.Wrapper>
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>Watson credentials</Modal.Title>
                        <Modal.SubTitle>
                            Edit tenant’s data to connect to an IBM Watson bot.
                        </Modal.SubTitle>
                    </Modal.Header>
                    <FormProvider {...methods}>
                        <WatsonCredentialsForm />
                    </FormProvider>
                </Modal.Content>
                <Modal.Footer>
                    <Modal.ButtonsWrapper>
                        <Button
                            onClick={handleClose}
                            size={'small'}
                            testId="4aPlrMQ"
                            variant={'secondary'}
                        >
                            cancel
                        </Button>
                        <Button
                            disabled={!isValid && isSubmitted}
                            isLoading={loading || isSubmitting}
                            onClick={handleSubmit(onSubmit)}
                            size={'small'}
                            testId="nykR2UR"
                            variant={'primary'}
                        >
                            save
                        </Button>
                    </Modal.ButtonsWrapper>
                </Modal.Footer>
            </Modal.Wrapper>
        </ModalComponent>
    );
};

const WatsonCredentialsSchema = yup.object({
    apiKey: yup.string().required('This field cannot be empty.'),
    assistantId: yup.string().required('This field cannot be empty.'),
    instanceUrl: yup.string().required('This field cannot be empty.'),
    workspaceId: yup.string(),
    workspaceName: yup.string(),
});

export type WatsonCredentialsFormValues = yup.InferType<
    typeof WatsonCredentialsSchema
>;
