import React, { InputHTMLAttributes, useState } from 'react';

import { Button, FieldWrapper, generateId } from '@speeki/global-ui-components';

import {
    ButtonsWrapper,
    TitleInputElement,
    TitleInputWrapper,
} from './TitleInput.styled';

interface TitleInputProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    onCancel: () => void;
    onChange: (value: string) => void;
    /**
     * Leave it empty and generate the id in the project with @speeki/frontend-utils helper.
     */
    testId: string;
    value?: string;
}

export const TitleInput = React.forwardRef<HTMLInputElement, TitleInputProps>(
    (
        {
            className,
            onCancel,
            onChange,
            testId,
            value,
            ...rest
        }: TitleInputProps,
        ref,
    ) => {
        const [inputValue, setInputValue] = useState(value || '');

        const inputId = rest.id || generateId(rest.name);

        return (
            <FieldWrapper className={className} data-testid={testId}>
                <TitleInputWrapper>
                    <TitleInputElement
                        {...rest}
                        data-testid={`${testId}-input`}
                        id={inputId}
                        onChange={(e) => setInputValue(e.target.value)}
                        ref={ref}
                        value={inputValue}
                    />
                    <ButtonsWrapper>
                        <Button
                            iconProps={{
                                icon: 'SvgClose24',
                                size: 24,
                            }}
                            onClick={onCancel}
                            testId="8sekmjU"
                            variant="secondary"
                        />
                        <Button
                            iconProps={{
                                icon: 'SvgTick24',
                                size: 24,
                            }}
                            onClick={() => onChange(inputValue)}
                            testId="iESBfgF"
                            variant="primary"
                        />
                    </ButtonsWrapper>
                </TitleInputWrapper>
            </FieldWrapper>
        );
    },
);
