import styled, { css } from 'styled-components';

export const TitleInputWrapper = styled.div(
    ({ theme: { palette } }) => css`
        display: flex;
        gap: 16px;
        position: relative;
        width: 100%;

        &::after {
            content: '';
            position: absolute;
            height: 2px;
            width: 100%;
            left: 0;
            background-color: ${palette.main.secondary};
            bottom: 0;
        }
    `,
);

export const TitleInputElement = styled.input(
    ({ theme: { fontSize, palette } }) => css`
        border: none;
        caret-color: ${palette.main.primary};
        color: ${palette.text.primary};
        font-size: ${fontSize[36]}px;
        letter-spacing: 0.015em;
        line-height: 52px;
        outline: none;
        padding: 0;
        width: 100%;
    `,
);

export const ButtonsWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
`;
