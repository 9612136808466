import { ReactNode } from 'react';

import { BaseTab } from '@components/ions/BaseTab.styled';

import { TabsContainer, TabsWrapper } from './Tabs.styled';

interface TabsProps<T> {
    additionalComponent?: ReactNode;
    tabComponent: (tab: T, index: number) => ReactNode;
    tabs: T[];
}

export const Tabs = <T extends object>({
    additionalComponent,
    tabComponent,
    tabs,
}: TabsProps<T>) => {
    return (
        <TabsContainer>
            <TabsWrapper>
                {tabs.map((tab, index) => tabComponent(tab, index))}
            </TabsWrapper>
            {additionalComponent && additionalComponent}
        </TabsContainer>
    );
};

export const Tab = BaseTab;
