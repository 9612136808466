import styled, { css, CSSProperties } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';
import { IconManager } from '@speeki/icons';

const Wrapper = styled.div(
    ({ theme: { boxShadow, colors, radius } }) => css`
        background-color: ${colors.white};
        border-radius: ${radius[8]}px;
        box-shadow: ${boxShadow.small};
    `,
);

const InnerWrapper = styled.div<{ padding?: CSSProperties['padding'] }>(
    ({ padding }) => css`
        padding: ${padding || '40px'};
    `,
);

// HEADER
const Header = styled.div<{
    gap?: CSSProperties['gap'];
    justifyContent?: CSSProperties['justifyContent'];
    marginBottom?: CSSProperties['marginBottom'];
}>(
    ({ gap, justifyContent, marginBottom }) => css`
        align-items: center;
        display: flex;
        gap: ${gap ?? 0};
        justify-content: ${justifyContent ?? 'unset'};
        margin-bottom: ${marginBottom ?? 0};
    `,
);

const AccordionWrapper = styled.div<{ isExpanded: boolean }>(
    ({ isExpanded }) => css`
        align-items: center;
        display: flex;
        gap: 16px;
        margin-bottom: ${isExpanded ? 32 : 0}px;
    `,
);

const CardName = styled(Typography.TextH2_28_44)(
    ({ theme: { colors, font } }) => css`
        color: ${colors.black};
        font-family: ${font.secondary};
        line-height: 1;
    `,
);

const SectionName = styled(Typography.TextH4_20_28)(
    ({ theme: { colors, font } }) => css`
        color: ${colors.black};
        font-family: ${font.secondary};
    `,
);

const EditButton = styled(IconManager)`
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
`;

// CONTENT
const CardData = styled.div`
    width: 100%;
`;

const Row = styled.div<{ small?: boolean }>(
    ({ small, theme: { colors } }) => css`
        border-bottom: 1px solid ${colors.dividers};
        margin: ${!!small ? 24 : 32}px 0;
    `,
);

// FOOTER
const Footer = styled.div<{ padding?: CSSProperties['padding'] }>(
    ({ padding, theme: { palette } }) => css`
        border-top: 1px solid ${palette.neutral.dividers};
        padding: ${padding || '24px 40px'};
    `,
);

export const Card = {
    AccordionWrapper,
    CardData,
    CardName,
    EditButton,
    Footer,
    Header,
    InnerWrapper,
    Row,
    SectionName,
    Wrapper,
};
