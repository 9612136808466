import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    ESG_QUESTION_ACTIVITY,
    ESG_STEP,
    ESG_SUBCATEGORY,
} from '@speeki/dictionary';
import { notify, TextButton } from '@speeki/global-ui-components';

import {
    EsgActivityEditPageDocument,
    QuestionUpdateDocument,
} from '@graphql/generated/graphql';

import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { MarkdownForm } from '@domains/settings/organisms/MarkdownForm/MarkdownForm';
import { SettingsLayout } from '@domains/settings/templates/SettingsLayout/SettingsLayout';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';
import { internalPaths } from '@shared/paths';

import {
    BackButtonWrapper,
    Description,
    HeaderWrapper,
    ProfileCard,
    Title,
} from './EsgActivityEditPage.styled';

export const EsgActivityEditPage = () => {
    const navigate = useNavigate();

    const { questionId, topic } = useParams<{
        questionId: string;
        topic: string;
    }>();

    const { data, loading } = useQuery(EsgActivityEditPageDocument, {
        variables: {
            uuid: questionId as string,
        },
    });
    const questionData = data?.question;

    const [questionUpdate, { loading: updateLoading }] = useMutation(
        QuestionUpdateDocument,
        {
            context: {
                notifyOnError: true,
            },
            onError: () => {},
        },
    );

    const handleUpdate = (question: string) => {
        void questionUpdate({
            onCompleted: () => {
                notify(SUCCESS_MESSAGES.ACTIVITY_UPDATE).SUCCESS();
                goBack();
            },
            variables: {
                input: {
                    questions: [
                        {
                            uuid: questionId as string,
                            question,
                        },
                    ],
                },
            },
        });
    };

    const goBack = () =>
        navigate(internalPaths.esgProfileTopic(topic as string));

    const topicLabel = useMemo(() => {
        if (!questionData) return null;

        return (
            questionData.step.riskArea.customLabel ||
            ESG_SUBCATEGORY[questionData.step.riskArea.name]
        );
    }, [questionData]);

    const header = useMemo(() => {
        if (!questionData) return null;

        return `${ESG_STEP[questionData.step.name]}: ${ESG_QUESTION_ACTIVITY[questionData.activity]}`;
    }, [questionData]);

    return (
        <SettingsLayout>
            <ProfileCard>
                <BackButtonWrapper>
                    <TextButton
                        iconProps={{
                            icon: 'SvgArrowLeft24',
                            size: 24,
                            themeColors: {
                                default: {
                                    'color-1': 'tertiary.default',
                                },
                            },
                        }}
                        onClick={goBack}
                        testId="dNAC2S7"
                        type={'button'}
                    >
                        {`Back to topic ${topicLabel}`}
                    </TextButton>
                </BackButtonWrapper>

                <HeaderWrapper>
                    <Title>{header}</Title>
                    <Description>
                        Use the editor below to update the copy of the activity.
                    </Description>
                </HeaderWrapper>

                <MarkdownForm
                    defaultValue={questionData?.question}
                    loading={loading}
                    onSubmit={handleUpdate}
                    placeholder="**[term]:**&#10;[description]"
                    updateLoading={updateLoading}
                />
            </ProfileCard>
        </SettingsLayout>
    );
};
