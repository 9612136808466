import { nanoid } from 'nanoid';

import { Skeleton } from '@speeki/global-ui-components';

import {
    FieldLabelsWrapperSkeleton,
    MarkdownEditorContentSkeleton,
    MarkdownEditorSkeleton,
    MarkdownFieldSkeletonWrapper,
    MarkdownInputContentSkeleton,
    MarkdownInputSkeleton,
} from '@domains/settings/organisms/MarkdownField/MarkdownField.styled';

interface MarkdownFieldSkeletonProps {
    className?: string;
}
export const MarkdownFieldSkeleton = ({
    className,
}: MarkdownFieldSkeletonProps) => {
    return (
        <div className={className}>
            <FieldLabelsWrapperSkeleton>
                <Skeleton height={20} width={'100px'} />
                <Skeleton height={20} width={'200px'} />
            </FieldLabelsWrapperSkeleton>
            <MarkdownFieldSkeletonWrapper>
                <MarkdownInputSkeleton>
                    {[...Array<unknown>(5)].map(() => (
                        <MarkdownInputContentSkeleton key={nanoid()}>
                            <Skeleton height={24} width={'40%'} />
                            <Skeleton height={120} width={'100%'} />
                        </MarkdownInputContentSkeleton>
                    ))}
                </MarkdownInputSkeleton>
                <MarkdownEditorSkeleton>
                    {[...Array<unknown>(5)].map(() => (
                        <MarkdownEditorContentSkeleton key={nanoid()}>
                            <Skeleton height={44} width={'40%'} />
                            <Skeleton height={180} width={'100%'} />
                        </MarkdownEditorContentSkeleton>
                    ))}
                </MarkdownEditorSkeleton>
            </MarkdownFieldSkeletonWrapper>
        </div>
    );
};
