import { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { ESG_SUBCATEGORY } from '@speeki/dictionary';
import { notify } from '@speeki/global-ui-components';

import {
    Esg_Subcategory,
    GuidelineByUuidDocument,
    GuidelinesDocument,
    GuidelineUpsertDocument,
    SectorByUuidDocument,
    StandardsDocument,
} from '@graphql/generated/graphql';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_VALIDATION_MESSAGES } from '@shared/formValidationMessages';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';
import { internalPaths } from '@shared/paths';
import { useStandardSectorsSelect } from '@shared/selects/useStandardSectorsSelect';
import { optionalSelectV2, requiredSelectV2 } from '@shared/validations';

export const useGuidelineAddEditForm = () => {
    const [markdown, setMarkdown] = useState('');
    const [selectedStandard, setSelectedStandard] = useState<
        Esg_Subcategory | undefined
    >();
    const [requiredIndustry, setRequiredIndustry] = useState(false);

    const navigate = useNavigate();
    const { guidelineId } = useParams<{ guidelineId: string }>();

    const [guidelineUpsert, { loading: upsertLoading }] = useMutation(
        GuidelineUpsertDocument,
        { context: { notifyOnError: true }, onError: () => {} },
    );
    const [getStandards, { data: standardData }] = useLazyQuery(
        StandardsDocument,
        {
            fetchPolicy: 'cache-and-network',
        },
    );

    const standardsOptions =
        standardData?.riskAreas.map((riskArea) => ({
            label: ESG_SUBCATEGORY[riskArea.name],
            value: riskArea.uuid,
        })) || [];

    const { data, loading } = useQuery(GuidelineByUuidDocument, {
        skip: !guidelineId,
        variables: {
            input: { uuids: guidelineId ? [guidelineId] : [] },
        },
    });

    const guideline = data?.guidelinesByUuids[0];
    const isDisabled = loading || upsertLoading;

    const isIFRSSelected = selectedStandard === 'IFRS';
    const isEngageSelected = selectedStandard === 'ENGAGE';

    const {
        clearErrors,
        control,
        formState: { errors },
        handleSubmit,
        register,
        reset,
    } = useForm<GuidelineAddEditFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(getGuidelineAddEditSchema(requiredIndustry)),
    });

    const standardsField = useController({
        control,
        name: 'standard',
    });

    const sectorField = useController({
        control,
        name: 'sector',
    });

    const industryField = useController({
        control,
        name: 'industry',
    });

    const standardSectorsSelect = useStandardSectorsSelect({
        riskAreaName: selectedStandard,
    });

    const selectedSector = sectorField.field?.value?.value;
    const { data: sectorData } = useQuery(SectorByUuidDocument, {
        skip: !selectedSector,
        variables: {
            uuid: selectedSector as string,
        },
    });

    const sectorOptions =
        sectorData?.sector?.units?.map((node) => ({
            label: node?.name ?? '',
            value: node?.uuid ?? '',
        })) || [];

    useEffect(() => {
        if (!guideline) return;

        setMarkdown(guideline.markdown);

        const industry = guideline.unit;
        const sector = guideline.unit?.sector;

        reset({
            uuid: guideline.uuid,
            category: guideline.categoryName,
            description: guideline.markdown,
            industry: industry
                ? { label: industry.name, value: industry.uuid }
                : undefined,
            order: guideline.order?.toString() ?? '',
            sector: sector
                ? { label: sector.name, value: sector.uuid }
                : undefined,
            standard: {
                label: guideline.standard.name,
                value: guideline.standard.uuid,
            },
            subcategory: guideline.name ?? '',
        });
    }, [loading]);

    useEffect(() => {
        void getStandards({
            variables: { input: { types: ['STANDARD'] } },
        });
    }, []);

    const standard = standardsField.field.value?.label;
    const sector = sectorField.field.value?.label;
    useEffect(() => {
        setSelectedStandard(standard as Esg_Subcategory);

        const requiredIndustry = standard === 'IFRS' && !!sector;
        setRequiredIndustry(requiredIndustry);
        !requiredIndustry && setTimeout(() => clearErrors('industry'), 10);
    }, [standard, sector]);

    const handleReset = () => {
        reset();
        setMarkdown(guideline?.markdown ?? '');
    };

    const handleFormSubmit = handleSubmit((data) => {
        const getUnit = () => {
            if (isIFRSSelected) return data?.industry?.value;
            if (!!sectorOptions.length) return sectorOptions[0]?.value;
            return null;
        };
        const unit = getUnit();

        void guidelineUpsert({
            onCompleted: () => {
                navigate(internalPaths.guideline);
                notify(
                    data.uuid
                        ? SUCCESS_MESSAGES.GUIDELINE_UPDATE
                        : SUCCESS_MESSAGES.GUIDELINE_ADD,
                ).SUCCESS();
            },
            // TODO: WILL BE CHANGE AFTER TYPES GENERATOR REFACTOR
            refetchQueries: data.uuid
                ? undefined
                : [
                      {
                          query: GuidelinesDocument,
                          variables: {
                              input: {
                                  paging: { limit: 10, offset: 1 },
                                  sorting: [],
                              },
                          },
                      },
                  ],
            variables: {
                input: {
                    guidelineUpsertInput: [
                        {
                            uuid: data.uuid,
                            categoryName: data.category,
                            markdown: data.description ?? '',
                            name: data.subcategory,
                            order: Number(data.order) ?? null,
                            standardUuid: data?.standard?.value,
                            unitUuid: unit || null,
                        },
                    ],
                },
            },
        });
    });

    return {
        fields: {
            industryField,
            sectorField,
            standardsField,
        },
        form: { errors, handleFormSubmit, handleReset, register },
        guidelineId,
        inputOptions: {
            sectorOptions,
            standardSectorsSelect,
            standardsOptions,
        },
        isDisabled,
        isEngageSelected,
        isIFRSSelected,
        loading,
        markdown,
        selectedStandard,
        setMarkdown,
        upsertLoading,
    };
};

const getGuidelineAddEditSchema = (requiredIndustry?: boolean) =>
    yup.object({
        uuid: yup.string().optional(),
        category: yup
            .string()
            .required(FORM_VALIDATION_MESSAGES.FIELD_REQUIRED),
        description: yup.string().optional(),
        industry: requiredIndustry ? requiredSelectV2 : optionalSelectV2,
        order: yup.string().optional(),
        sector: optionalSelectV2,
        standard: requiredSelectV2,
        subcategory: yup.string().optional(),
    });

type GuidelineAddEditFormValues = yup.InferType<
    ReturnType<typeof getGuidelineAddEditSchema>
>;
