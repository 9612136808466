import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';

import { subscriptionPlanIterable } from '@speeki/dictionary';

import { CardDataRow } from '@components/atoms/CardDataRow/CardDataRow';

import { ClientDetailsDocument } from '@graphql/generated/graphql';

import { useQuery } from '@apollo/client';
import { Card } from '@domains/clients/ions/Card.styled';
import { internalPaths } from '@shared/paths';

interface SubscriptionInformationProps {
    openModal: () => void;
}

export const SubscriptionInformation = ({
    openModal,
}: SubscriptionInformationProps) => {
    const navigate = useNavigate();
    const { clientId } = useParams<{ clientId: string }>();
    const { data } = useQuery(ClientDetailsDocument, {
        context: {
            notifyOnError: true,
        },
        onError: () => navigate(internalPaths.clients),
        variables: {
            disableTaskManager: false,
            tenantUuid: clientId || '',
        },
    });

    const subscription = data?.clientDetails.subscription;

    const period =
        dayjs(subscription?.start).format('YYYY/MM/DD') +
        ' - ' +
        dayjs(subscription?.end).format('YYYY/MM/DD');
    const currentPlan = subscriptionPlanIterable.find(
        (plan) => plan.key === subscription?.plan?.name,
    );

    return (
        <>
            <Card.Header gap={'16px'} marginBottom={'16px'}>
                <Card.SectionName>Subscription information</Card.SectionName>
                <Card.EditButton
                    icon={'SvgEdit24'}
                    iconProps={{ onClick: openModal }}
                    size={24}
                    themeColors={{
                        default: { 'color-1': 'main.tertiary' },
                    }}
                />
            </Card.Header>
            <Card.CardData>
                <CardDataRow name={'Plan:'} value={currentPlan?.value} />

                <CardDataRow
                    name={'Number of standard licences:'}
                    value={subscription?.standardLicenseCount}
                />

                <CardDataRow
                    name={'Number of limited licences:'}
                    value={subscription?.limitedLicenseCount}
                />
                <CardDataRow name={'Period:'} value={period} />
                <CardDataRow
                    name={'Subscriber’s email:'}
                    value={subscription?.subscriberEmail}
                />
            </Card.CardData>
        </>
    );
};
