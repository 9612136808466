import { CardDataRowElement, RowName, RowValue } from './CardDataRow.styled';

interface CardDataRowProps {
    name: string;
    value?: string | number | null;
}

export const CardDataRow = ({ name, value }: CardDataRowProps) => {
    const isInvalidData = !!value?.toString().match('Invalid Date')?.length;
    let currentValue = value;

    if (isInvalidData) {
        currentValue = value?.toString().replace('Invalid Date ', '');
    }

    return (
        <CardDataRowElement>
            <RowName>{name}</RowName>
            {isInvalidData ? (
                <RowValue noData={!currentValue}>
                    <span>no data</span> {currentValue || 'no data'}
                </RowValue>
            ) : (
                <RowValue noData={!currentValue}>
                    {currentValue || 'no data'}
                </RowValue>
            )}
        </CardDataRowElement>
    );
};
