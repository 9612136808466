import { SvgProps } from '@@types/CommonTypes';

import { getSvgSize } from '@utils/helpers';

export const Circle = ({ size }: SvgProps) => (
    <svg {...getSvgSize(784, 784, size)} fill="none">
        <path
            d="M392.388 783.692c216.111 0 391.304-175.193 391.304-391.304 0-216.112-175.193-391.304-391.304-391.304-216.112 0-391.304 175.192-391.304 391.304 0 216.111 175.192 391.304 391.304 391.304Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={0.5}
        />
        <path
            d="M392.388 763.988c205.229 0 371.6-166.371 371.6-371.6s-166.371-371.6-371.6-371.6-371.6 166.371-371.6 371.6 166.371 371.6 371.6 371.6Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={0.656}
        />
        <path
            d="M392.388 744.284c194.347 0 351.896-157.549 351.896-351.896S586.735 40.492 392.388 40.492 40.492 198.04 40.492 392.388 198.04 744.284 392.388 744.284Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={0.813}
        />
        <path
            d="M392.388 724.58c183.465 0 332.193-148.728 332.193-332.192 0-183.465-148.728-332.193-332.193-332.193-183.464 0-332.192 148.728-332.192 332.193 0 183.464 148.728 332.192 332.192 332.192Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={0.969}
        />
        <path
            d="M392.387 704.876c172.582 0 312.488-139.906 312.488-312.488 0-172.583-139.906-312.489-312.488-312.489-172.583 0-312.489 139.906-312.489 312.489 0 172.582 139.906 312.488 312.489 312.488Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={1.125}
        />
        <path
            d="M392.387 685.172c161.7 0 292.784-131.084 292.784-292.784 0-161.701-131.084-292.785-292.784-292.785S99.603 230.687 99.603 392.388c0 161.7 131.084 292.784 292.784 292.784Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={1.281}
        />
        <path
            d="M392.387 665.191c150.665 0 272.803-122.138 272.803-272.803S543.052 119.585 392.387 119.585 119.584 241.723 119.584 392.388s122.138 272.803 272.803 272.803Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={1.438}
        />
        <path
            d="M392.388 645.486c139.782 0 253.099-113.316 253.099-253.098 0-139.783-113.317-253.099-253.099-253.099-139.783 0-253.099 113.316-253.099 253.099 0 139.782 113.316 253.098 253.099 253.098Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={1.594}
        />
        <path
            d="M392.386 625.782c128.9 0 233.395-104.494 233.395-233.394 0-128.901-104.495-233.395-233.395-233.395S158.991 263.487 158.991 392.388c0 128.9 104.495 233.394 233.395 233.394Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={1.75}
        />
        <path
            d="M392.388 606.079c118.018 0 213.691-95.673 213.691-213.691 0-118.019-95.673-213.691-213.691-213.691s-213.691 95.672-213.691 213.691c0 118.018 95.673 213.691 213.691 213.691Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={1.906}
        />
        <path
            d="M392.389 586.375c107.136 0 193.987-86.851 193.987-193.987s-86.851-193.987-193.987-193.987c-107.137 0-193.987 86.851-193.987 193.987s86.85 193.987 193.987 193.987Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={2.063}
        />
        <path
            d="M392.427 566.673c96.256 0 174.287-78.031 174.287-174.287s-78.031-174.287-174.287-174.287-174.286 78.031-174.286 174.287 78.03 174.287 174.286 174.287Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={2.219}
        />
        <path
            d="M392.374 546.971c85.371 0 154.579-69.207 154.579-154.579 0-85.372-69.208-154.579-154.579-154.579-85.372-.001-154.58 69.207-154.58 154.579 0 85.372 69.208 154.579 154.58 154.579Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={2.375}
        />
        <path
            d="M392.361 527.211c74.493 0 134.881-60.388 134.881-134.88 0-74.492-60.388-134.88-134.881-134.88-74.492 0-134.88 60.388-134.88 134.88 0 74.492 60.388 134.88 134.88 134.88Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={2.531}
        />
        <path
            d="M392.402 507.61c63.605 0 115.168-51.562 115.168-115.168 0-63.606-51.563-115.169-115.168-115.169-63.606 0-115.169 51.563-115.169 115.169 0 63.606 51.563 115.168 115.169 115.168Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={2.688}
        />
        <path
            d="M392.411 487.811c52.727 0 95.471-42.744 95.471-95.471 0-52.728-42.744-95.472-95.471-95.472-52.728 0-95.472 42.744-95.472 95.472 0 52.727 42.744 95.471 95.472 95.471Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={2.844}
        />
        <path
            d="M392.379 468.218c41.842 0 75.761-33.919 75.761-75.761 0-41.841-33.919-75.76-75.761-75.76-41.841 0-75.76 33.919-75.76 75.76 0 41.842 33.919 75.761 75.76 75.761Z"
            opacity={0.8}
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={3}
        />
    </svg>
);
