import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Button, Modal, ModalComponent } from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import { SubsidiarySiteForm } from '@components/organisms/SubsidiarySiteForm/SubsidiarySiteForm';

import {
    SubsidiarySitesDocument,
    SubsidiarySitesTotalCountDocument,
    SubsidiarySiteUpsertDocument,
} from '@graphql/generated/graphql';

import { useMutation } from '@apollo/client';
import { SubsidiaryTableRow } from '@domains/clients/organisms/SubsidiaryTable/SubsidiaryTable.settings';
import { yupResolver } from '@hookform/resolvers/yup';

interface SubsidiarySiteModalProps extends UseModal {
    data?: SubsidiaryTableRow;
}

export const SubsidiarySiteModal = ({
    data,
    open,
    setOpen,
}: SubsidiarySiteModalProps) => {
    const { clientId } = useParams<{ clientId: string }>();
    const { name } = data || {};

    const methods = useForm<SubsidiarySiteSchemaFormValues>({
        defaultValues: data
            ? {
                  subsidiaryName: name ?? '',
              }
            : {},
        mode: 'onChange',
        resolver: yupResolver(SubsidiarySiteSchema),
    });

    const {
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
        reset,
    } = methods;

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    const [updateSubsidiarySite, { loading }] = useMutation(
        SubsidiarySiteUpsertDocument,
        {
            context: {
                notifyOnError: true,
            },
            onCompleted: handleClose,
            onError: () => {},
            refetchQueries: [
                SubsidiarySitesTotalCountDocument,
                SubsidiarySitesDocument,
            ],
        },
    );

    const onSubmit = (values: SubsidiarySiteSchemaFormValues) => {
        void updateSubsidiarySite({
            variables: {
                input: {
                    uuid: data?.id,
                    // TODO: Remove when backend change it to not mandatory
                    adminUuids: [],
                    isActive: data ? data.isActive : true,
                    name: values.subsidiaryName,
                    tenantUuid: clientId ?? '',
                },
            },
        });
    };

    return (
        <ModalComponent onClose={handleClose} open={open}>
            <Modal.Wrapper>
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>
                            {data ? 'Entity' : 'Add entities'}
                        </Modal.Title>
                        <Modal.SubTitle>
                            {data
                                ? 'Edit entity’s information.'
                                : 'Start adding entities of this client.'}
                        </Modal.SubTitle>
                    </Modal.Header>
                    <FormProvider {...methods}>
                        <SubsidiarySiteForm subsidiaryName={data?.name} />
                    </FormProvider>
                </Modal.Content>
                <Modal.Footer>
                    <Modal.ButtonsWrapper>
                        <Button
                            onClick={handleClose}
                            size={'small'}
                            testId="W5ffpNb"
                            variant={'secondary'}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!isValid && !!Object.keys(errors).length}
                            isLoading={loading || isSubmitting}
                            onClick={handleSubmit(onSubmit)}
                            size={'small'}
                            testId="dRc_8Yz"
                            variant={'primary'}
                        >
                            Save
                        </Button>
                    </Modal.ButtonsWrapper>
                </Modal.Footer>
            </Modal.Wrapper>
        </ModalComponent>
    );
};

export const SubsidiarySiteSchema = yup.object({
    subsidiaryName: yup
        .string()
        .required('This field cannot be empty')
        .min(3, 'Site name is too short, minimum 3 characters are required.')
        .max(64, 'Site name is too long, maximum 64 characters are allowed.'),
});

export type SubsidiarySiteSchemaFormValues = yup.InferType<
    typeof SubsidiarySiteSchema
>;
