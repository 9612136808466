import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

import { Table } from '@components/atoms/Table/Table';
import { SIDEBAR_WIDTH } from '@components/templates/MainLayout/MainLayout.styled';

export const SlotsTableWrapper = styled.div`
    margin-top: 44px;
`;

const RIGHT_LEFT_PADDING = 48;

export const SlotsTableInnerWrapper = styled.div`
    max-width: calc(100vw - ${SIDEBAR_WIDTH}px - ${RIGHT_LEFT_PADDING}px);
    position: relative;
`;

export const TableHeaderSpace = styled.tr`
    height: 8px;
`;

// TODO: [SPD-2940] find other way to style 7th+ cells
export const StyledTableHeaderCell = styled(Table.HeaderCell)`
    &:nth-child(n + 7) {
        padding-right: 0;
    }
`;

export const StyledTableBodyRow = styled(Table.BodyRow)`
    vertical-align: top;
`;

export const StyledTableBodyCell = styled(Table.BodyCell)`
    &:nth-child(n + 7) {
        padding-right: 8px;
    }
`;

export const EmptyStateWrapper = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        border-top: 1px solid ${colors.dividers};
        display: flex;
        flex-direction: column;
        padding: 48px;
    `,
);

export const NoSlotsText = styled(Typography.TextH3_24_34)(
    ({ theme: { colors, font } }) => css`
        color: ${colors.text_primary};
        font-family: ${font.secondary};
        text-align: center;
    `,
);

export const NoSlotsDescription = styled(Typography.TextBody_14_24)(
    ({ theme: { colors, font } }) => css`
        color: ${colors.text_primary};
        font-family: ${font.primary};
        text-align: center;
    `,
);

export const LegacyTypeNone = styled(Typography.TextBody_14_24)(
    ({ theme: { colors } }) => css`
        color: ${colors.text_assistive};
    `,
);

export const EditIcon = styled.div`
    bottom: 2px;
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
    position: relative;
`;
