import { Column } from 'react-table';

export interface EsgProfileTopicTableRow {
    activity: string;
    id: string;
    stage: string;
}

export const headerData: Column<EsgProfileTopicTableRow>[] = [
    {
        Header: 'Activity',
        accessor: 'activity',
        disableSortBy: true,
    },
    { Header: 'Stage', accessor: 'stage', disableSortBy: true },
];
