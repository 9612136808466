import { compose, createStore, Store } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from 'src/state/_redux/reducers';

import { createSentryEnhancer } from '@speeki/frontend-sentry';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = (): Store => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    const _WINDOW = window as any;
    const composeEnhancers =
        _WINDOW.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createStore(
        persistedReducer,
        composeEnhancers(createSentryEnhancer()),
    ) as Store;
};

export const store = configureStore();
export const persistor = persistStore(store);
