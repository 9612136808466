import styled, { css } from 'styled-components';

import { SearchField, Typography } from '@speeki/global-ui-components';

export const TagsTableWrapper = styled.div`
    margin-top: 34px;
`;

export const TagsSearchField = styled(SearchField)`
    width: 458px;
`;

export const TagsSearchFilterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ActionWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

export const TagDescription = styled(Typography.TextBody_14_24)(
    ({ theme: { palette } }) => css`
        color: ${palette.text.assistive};
        margin-right: 12px;
    `,
);
