import { Column } from 'react-table';

import { IconButton } from '@speeki/icons';

import { SearchTableLastCell } from '@domains/clients/ions/Table.styled';

export interface ElineNumbersTableRow {
    country: string;
    id: string;
    options: boolean;
    phoneNumber: string;
}

export const headerData = ({
    onDelete,
    onEdit,
}: {
    onDelete: (row: ElineNumbersTableRow) => void;
    onEdit: (row: ElineNumbersTableRow) => void;
}): Column<ElineNumbersTableRow>[] => [
    {
        Header: 'Phone number',
        accessor: 'phoneNumber',
        disableSortBy: true,
    },
    {
        Header: 'Country',
        accessor: 'country',
        disableSortBy: true,
    },
    {
        Cell: ({ row: { original } }) => {
            return (
                <SearchTableLastCell>
                    <IconButton
                        iconProps={{
                            icon: 'SvgEdit24',
                            size: 24,
                            themeColors: {
                                default: {
                                    'color-1': 'button.tertiary',
                                },
                                hover: {
                                    'color-1': 'button.tertiaryHover',
                                },
                            },
                        }}
                        onClick={() => onEdit(original)}
                        testId="7pmq5Vg"
                    />
                    <IconButton
                        iconProps={{
                            icon: 'SvgDelete24',
                            size: 24,
                            themeColors: {
                                default: {
                                    'color-1': 'semantic.error',
                                },
                                hover: {
                                    'color-1': 'semantic.errorHover',
                                },
                            },
                        }}
                        onClick={() => onDelete(original)}
                        testId="Pnq57EW"
                    />
                </SearchTableLastCell>
            );
        },
        Header: <></>,
        accessor: 'options',
        disableSortBy: true,
        width: '1%',
    },
];
