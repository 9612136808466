import { notify } from '@speeki/global-ui-components';

import {
    GlossaryInsertDocument,
    GlossaryMarkdownDocument,
} from '@graphql/generated/graphql';

import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { MarkdownForm } from '@domains/settings/organisms/MarkdownForm/MarkdownForm';
import {
    GuidelineAndGlossaryCard,
    GuidelineAndGlossaryDescription,
    GuidelineAndGlossaryTitle,
    HeaderWrapper,
} from '@domains/settings/pages/GuidelineAndGlossaryPage.styled';
import { SettingsLayout } from '@domains/settings/templates/SettingsLayout/SettingsLayout';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';

export const GlossaryPage = () => {
    const { data, loading } = useQuery(GlossaryMarkdownDocument, {
        fetchPolicy: 'cache-and-network',
        onError: () => {},
    });

    const [glossaryInsert, { loading: updateLoading }] = useMutation(
        GlossaryInsertDocument,
        {
            context: {
                notifyOnError: true,
            },
            onError: () => {},
        },
    );

    const handleUpdate = (markdown: string) => {
        void glossaryInsert({
            onCompleted: () => {
                notify(SUCCESS_MESSAGES.GLOSSARY_UPDATED).SUCCESS();
            },
            variables: {
                input: {
                    markdown,
                },
            },
        });
    };

    return (
        <SettingsLayout>
            <GuidelineAndGlossaryCard>
                <HeaderWrapper>
                    <GuidelineAndGlossaryTitle>
                        Glossary
                    </GuidelineAndGlossaryTitle>
                    <GuidelineAndGlossaryDescription>
                        Use the editor below to update the copy of term.
                    </GuidelineAndGlossaryDescription>
                </HeaderWrapper>
                <MarkdownForm
                    defaultValue={data?.glossaryMarkdown}
                    loading={loading}
                    onSubmit={handleUpdate}
                    placeholder="%%% [standard]; [category name]&#10;## [term]&#10;[description]&#10;## [term]&#10;[description]"
                    updateLoading={updateLoading}
                />
            </GuidelineAndGlossaryCard>
        </SettingsLayout>
    );
};
