import { Box } from '@components/atoms/Box/Box';
import { AuthPageWrapper } from '@components/ions/Pages.styled';
import { PasswordResetForm } from '@components/organisms/PasswordResetForm/PasswordResetForm';
import { AuthLayout } from '@components/templates/AuthLayout/AuthLayout';

export const PasswordResetPage = () => {
    return (
        <AuthLayout>
            <AuthPageWrapper>
                <Box title={'Reset password'}>
                    <PasswordResetForm />
                </Box>
            </AuthPageWrapper>
        </AuthLayout>
    );
};
