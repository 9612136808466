import {
    BlockedDomainsFormValues,
    useBlockedDomainsUpsertModal,
} from './useBlockedDomainsUpsertModal';

import {
    Button,
    Modal,
    ModalComponent,
    TextField,
} from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import { FormWrapper } from '@components/ions/Form.styled';

import { ModalTitleSecondary } from '@domains/settings/ions/Modal.styled';

import { TextFieldWrapper } from './BlockedDomainsUpsertModal.styled';

interface BlockedDomainsUpsertModalProps extends UseModal {
    blockedDomainData?: BlockedDomainsFormValues;
    onCancel: () => void;
}

export const BlockedDomainsUpsertModal = ({
    blockedDomainData,
    onCancel,
    open,
    setOpen,
}: BlockedDomainsUpsertModalProps) => {
    const handleClose = () => {
        setOpen(false);
        onCancel();
    };

    const { errors, handleSubmit, isDirty, isValid, loading, register } =
        useBlockedDomainsUpsertModal({
            closeModal: handleClose,
            values: blockedDomainData,
        });

    return (
        <ModalComponent
            onClose={handleClose}
            open={open}
            preventCloseOutside
            width={688}
        >
            <Modal.Wrapper>
                <FormWrapper onSubmit={handleSubmit}>
                    <Modal.Content>
                        <ModalTitleSecondary>
                            {blockedDomainData
                                ? 'Edit blocked domain'
                                : 'Add new blocked domain'}
                        </ModalTitleSecondary>
                        {!blockedDomainData && (
                            <Modal.SubTitle>
                                To add a blocked domain, enter the domain name
                                followed by a dot and the domain suffix.
                            </Modal.SubTitle>
                        )}

                        <TextFieldWrapper>
                            <TextField
                                {...register(`domain`)}
                                error={!!errors.domain}
                                label={'Domain'}
                                message={errors.domain?.message}
                                placeholder={'e.g. gmail.com'}
                                testId="8XvVcJq"
                            />
                        </TextFieldWrapper>
                    </Modal.Content>
                    <Modal.Footer>
                        <Modal.ButtonsWrapper>
                            <Button
                                disabled={loading}
                                onClick={handleClose}
                                size={'small'}
                                testId="CV321rE"
                                variant={'secondary'}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={!isDirty || !isValid}
                                isLoading={loading}
                                size={'small'}
                                testId="Nse6zBZ"
                                type={'submit'}
                                variant={'primary'}
                            >
                                Save
                            </Button>
                        </Modal.ButtonsWrapper>
                    </Modal.Footer>
                </FormWrapper>
            </Modal.Wrapper>
        </ModalComponent>
    );
};
