import { Button, Modal, ModalComponent } from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import { User_Status_Key } from '@graphql/generated/graphql';

interface AdminUsersDeactivationModalProps extends UseModal {
    loading: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    userStatus?: User_Status_Key;
}

export const AdminUsersDeactivationModal = ({
    loading,
    onCancel,
    onConfirm,
    open,
    setOpen,
    userStatus,
}: AdminUsersDeactivationModalProps) => {
    const handleClose = () => {
        setOpen(false);
        onCancel();
    };

    const isUserActive = userStatus === 'ACTIVE';

    return (
        <ModalComponent
            onClose={handleClose}
            open={open}
            preventCloseOutside
            width={688}
        >
            <Modal.Wrapper>
                <Modal.Content>
                    <Modal.TitleSecondary>Are you sure?</Modal.TitleSecondary>
                    <Modal.SubTitle>
                        Are you sure you want to{' '}
                        {isUserActive ? 'deactivate' : 'reactivate'} this admin
                        user?
                    </Modal.SubTitle>
                </Modal.Content>
                <Modal.Footer>
                    <Modal.ButtonsWrapper>
                        <Button
                            disabled={loading}
                            onClick={handleClose}
                            testId="mCJwaND"
                            variant={'secondary'}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={loading}
                            isLoading={loading}
                            onClick={onConfirm}
                            testId="TC3dtPA"
                            variant={'primary'}
                        >
                            {isUserActive ? 'Deactivate' : 'Reactivate'}
                        </Button>
                    </Modal.ButtonsWrapper>
                </Modal.Footer>
            </Modal.Wrapper>
        </ModalComponent>
    );
};
