interface ValidationMessageProps {
    fieldName: string;
    limitCharacter: number;
}

export const FORM_VALIDATION_MESSAGES = {
    FIELD_CANNOT_BE_EMPTY: 'This field cannot be empty',
    FIELD_REQUIRED: 'This field is mandatory and can not be empty',
    FIELD_TOO_LONG: ({ fieldName, limitCharacter }: ValidationMessageProps) =>
        `${fieldName} is too long, maximum ${limitCharacter} characters are possible.`,
    FIELD_TOO_SHORT: ({ fieldName, limitCharacter }: ValidationMessageProps) =>
        `${fieldName} is too short, minimum ${limitCharacter} characters are required.`,
    INVALID_DOMAIN: 'Invalid domain. The correct format is: example.com',
    INVALID_EMAIL:
        'Invalid email address. The correct format is: name@example.com',
    PASSWORD_NOT_MATCH: 'Given passwords are not the same',
    TAG_NAME_DUPLICATED: 'The given tag name is a duplicate',
} as const;
