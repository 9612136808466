import {
    Button,
    Modal,
    ModalComponent,
    notify,
} from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import {
    GroupTagsRowsDocument,
    TagsDeleteDocument,
} from '@graphql/generated/graphql';

import { useMutation, useReactiveVar } from '@apollo/client';
import { editedTagIdVar } from '@boot/reactiveVariables';
import { ModalTitleSecondary } from '@domains/clients/ions/Modal.styled';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';

interface ChildTagDeleteModalProps extends UseModal {
    closeEditModal: () => void;
}
export const ChildTagDeleteModal = ({
    closeEditModal,
    open,
    setOpen,
}: ChildTagDeleteModalProps) => {
    const editedTagId = useReactiveVar(editedTagIdVar);

    const [tagDelete, { loading }] = useMutation(TagsDeleteDocument, {
        context: {
            notifyOnError: true,
        },
        onError: () => {},
        refetchQueries: [GroupTagsRowsDocument],
    });

    const handleClose = () => setOpen(false);

    const handleSubmit = () => {
        void tagDelete({
            onCompleted: () => {
                notify(SUCCESS_MESSAGES.TAG_DELETE).INFO();
                handleClose();
                closeEditModal();
            },
            variables: {
                input: {
                    uuidsToDelete: [editedTagId],
                },
            },
        });
    };

    return (
        <ModalComponent
            onClose={handleClose}
            open={open}
            preventCloseEsc
            preventCloseOutside
            width={648}
        >
            <Modal.Content>
                <Modal.Header>
                    <ModalTitleSecondary center>
                        Are you sure?
                    </ModalTitleSecondary>
                    <Modal.Description center color="black">
                        Are you sure you want to delete this tag? A change made
                        cannot be undone later.
                    </Modal.Description>
                </Modal.Header>
            </Modal.Content>
            <Modal.Footer>
                <Modal.ButtonsWrapper>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        testId="wuZEI7j"
                        variant={'secondary'}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        isLoading={loading}
                        onClick={handleSubmit}
                        testId="_WSaQlA"
                        variant={'primary'}
                    >
                        Delete
                    </Button>
                </Modal.ButtonsWrapper>
            </Modal.Footer>
        </ModalComponent>
    );
};
