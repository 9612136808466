import { Box } from '@components/atoms/Box/Box';
import { AuthPageWrapper } from '@components/ions/Pages.styled';
import { PasswordResetConfirmForm } from '@components/organisms/PasswordResetConfirmForm/PasswordResetConfirmForm';
import { AuthLayout } from '@components/templates/AuthLayout/AuthLayout';

export const PasswordResetConfirmPage = () => {
    return (
        <AuthLayout>
            <AuthPageWrapper>
                <Box title={'Change password'}>
                    <PasswordResetConfirmForm />
                </Box>
            </AuthPageWrapper>
        </AuthLayout>
    );
};
