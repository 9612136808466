import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { headerData, SubsidiaryTableRow } from './SubsidiaryTable.settings';

import {
    SearchTable,
    SecondaryManualTable,
} from '@speeki/global-ui-components';
import { useModal } from '@speeki/react-hooks';

import { TableEmptyState } from '@components/molecules/CardTableEmptyState/TableEmptyState';

import {
    SubsidiarySitesDocument,
    UpdateSubsidiarySiteStatusDocument,
} from '@graphql/generated/graphql';

import { useLazyQuery, useMutation } from '@apollo/client';
import { SearchTableWrapper } from '@domains/clients/ions/Table.styled';
import { DisableSubsidiarySiteModal } from '@domains/clients/organisms/DisableSubsidiarySiteModal/DisableSubsidiarySiteModal';
import { SubsidiarySiteModal } from '@domains/clients/organisms/SubsidiarySiteModal/SubsidiarySiteModal';

export const SubsidiaryTable = () => {
    const { clientId } = useParams<{ clientId: string }>();

    const editModal = useModal();
    const disableModal = useModal();

    const [editedItem, setEditedItem] = useState<SubsidiaryTableRow>();
    const [disabledItem, setDisabledItem] = useState<SubsidiaryTableRow>();

    const [getData, { called, data, loading }] = useLazyQuery(
        SubsidiarySitesDocument,
        { fetchPolicy: 'cache-and-network' },
    );
    const [updateStatus, { loading: updateStatusLoading }] = useMutation(
        UpdateSubsidiarySiteStatusDocument,
        {
            context: {
                notifyOnError: true,
            },
            onError: () => {},
            optimisticResponse: ({ input }) => ({
                subsidiary: {
                    uuid: input.uuid,
                    __typename: 'ClientSubsidiarySite',
                    isActive: input.status,
                },
            }),
        },
    );

    const transformedData: SubsidiaryTableRow[] =
        useMemo(
            () =>
                data?.subsidiary?.edges?.map((site) => ({
                    ...site.node,
                    id: site.node.uuid,
                })),
            [data],
        ) || [];

    const onConfirm = (row?: SubsidiaryTableRow) => {
        if (!row) return;

        !updateStatusLoading &&
            void updateStatus({
                onCompleted: () => disableModal.setOpen(false),
                variables: {
                    input: {
                        uuid: row.id,
                        status: !row.isActive,
                    },
                },
            });
    };

    return (
        <SearchTableWrapper>
            <SearchTable
                render={(searchValue) => (
                    <SecondaryManualTable<SubsidiaryTableRow>
                        columns={headerData({
                            onEdit: (row) => {
                                setEditedItem(row);
                                editModal.setOpen(true);
                            },
                            onSwitch: (row) => {
                                if (row.isActive) {
                                    setDisabledItem(row);
                                    disableModal.setOpen(true);
                                } else {
                                    !updateStatusLoading &&
                                        void updateStatus({
                                            variables: {
                                                input: {
                                                    uuid: row.id,
                                                    status: !row.isActive,
                                                },
                                            },
                                        });
                                }
                            },
                        })}
                        data={transformedData}
                        pagination={{
                            options: {
                                defaultPageSize: 20,
                                totalCount: data?.subsidiary.totalCount ?? 0,
                            },
                        }}
                        tableState={{
                            emptyState: <TableEmptyState marginTop={'-24px'} />,
                            getTableData: (props) =>
                                void getData({
                                    variables: {
                                        name: props.searchPhrase,
                                        paging: props.paging,
                                        tenantId: clientId ?? '',
                                    },
                                }),
                            loading: loading || !called,
                            searchPhrase: searchValue,
                            skeletonVariant: 'default',
                        }}
                        testId="E57lpti"
                    />
                )}
                testId="QWfs-8z"
            />
            {editModal.open && (
                <SubsidiarySiteModal {...editModal} data={editedItem} />
            )}
            {disableModal.open && (
                <DisableSubsidiarySiteModal
                    {...disableModal}
                    onConfirm={() => onConfirm(disabledItem)}
                />
            )}
        </SearchTableWrapper>
    );
};
