import styled from 'styled-components';

import { FieldRow } from '@components/ions/Form.styled';

export const ChangePasswordFormWrapper = styled.form``;

export const StyledFieldRow = styled(FieldRow)`
    display: grid;
    grid-gap: 4px;
`;
