import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const ProfileCard = styled.div(
    ({ theme: { boxShadow, palette } }) => css`
        background: ${palette.neutral.white};
        border-radius: 4px;
        box-shadow: ${boxShadow.small};
        gap: 16px;
        padding: 32px 48px 40px;
    `,
);

export const HeaderWrapper = styled.div`
    margin-bottom: 16px;
`;

export const Title = styled(Typography.TextH3_24_34)(
    ({ theme: { font } }) => css`
        font-family: ${font.secondary};
    `,
);

export const Description = styled(Typography.TextBody_14_20)(
    ({ theme: { palette } }) => css`
        color: ${palette.text.secondary};
        padding-top: 8px;
    `,
);

export const SearchWrapper = styled.div`
    margin-bottom: 8px;
`;
