import { CSSProperties } from 'react';

import {
    EmptyStateDescriptionWrapper,
    EmptyStateTitleWrapper,
} from '@components/molecules/commonStyles.styled';

import { EmptyStateWrapper } from './TableEmptyState.styled';

export interface TableEmptyStateProps {
    description?: string;
    marginTop?: CSSProperties['marginTop'];
    paddingBottom?: CSSProperties['paddingBottom'];
    paddingTop?: CSSProperties['paddingTop'];
    title?: string;
}

export const TableEmptyState = ({
    description = 'Make sure you have typed the search name correctly or change the query.',
    marginTop,
    paddingBottom = 0,
    paddingTop = '32px',
    title = 'Sorry, no results found',
}: TableEmptyStateProps) => {
    return (
        <EmptyStateWrapper
            marginTop={marginTop}
            paddingBottom={paddingBottom}
            paddingTop={paddingTop}
        >
            <EmptyStateTitleWrapper>{title}</EmptyStateTitleWrapper>
            <EmptyStateDescriptionWrapper>
                {description}
            </EmptyStateDescriptionWrapper>
        </EmptyStateWrapper>
    );
};
