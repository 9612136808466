import styled, { css } from 'styled-components';

export const ErrorBoxWrapper = styled.div(
    ({ theme: { palette } }) => css`
        align-items: center;
        background: ${palette.semantic.errorBg};
        display: flex;
        gap: 8px;
        margin-bottom: 16px;
        padding: 14px 24px;
    `,
);
