import styled, { css } from 'styled-components';

export const ReactMarkdownWrapper = styled.div`
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr 1fr;
`;

export const FieldLabelsWrapper = styled.div`
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    width: calc(50% - 16px);
`;

export const TooltipInfoButton = styled.div`
    height: 24px;
`;

export const ReactMarkdownInput = styled.textarea<{ error?: boolean }>(
    ({ error, theme: { font, fontSize, palette } }) => css`
        border: 1px solid ${palette.neutral.dividers};
        color: ${palette.text.secondary};
        font-family: ${font.primary};
        font-size: ${fontSize[16]}px;
        height: 700px;
        line-height: 24px;
        overflow-y: scroll;
        padding: 20px;
        resize: none;

        &:focus-within {
            border: 2px solid ${palette.secondary.default};
            padding: 19px;

            &:focus {
                outline: none;
            }
        }

        ${error &&
        css`
            border-color: ${palette.semantic.error};
            caret-color: ${palette.semantic.error};

            &:focus-within,
            &:hover {
                border-color: ${palette.semantic.error};
            }
        `}
    `,
);

export const ReactMarkdownPreview = styled.div(
    ({ theme: { font, palette } }) => css`
        background-color: ${palette.neutral.lightGrey};
        box-shadow: none;
        display: flex;
        flex-direction: column;
        height: 700px;
        overflow-y: scroll;
        padding: 20px;
        word-break: break-word;

        h1 {
            border-bottom: none;
            color: ${palette.text.primary};
            font-family: ${font.secondary};
            font-size: 36px;
            font-weight: normal;
            line-height: 52px;
            margin: 0;
        }

        h2 {
            border-bottom: none;
            color: ${palette.text.primary};
            font-family: ${font.secondary};
            font-size: 28px;
            font-weight: normal;
            line-height: 44px;
            margin: 0;
        }

        h3 {
            border-bottom: none;
            color: ${palette.text.primary};
            font-family: ${font.secondary};
            font-size: 24px;
            font-weight: normal;
            line-height: 34px;
        }

        h4 {
            border-bottom: none;
            color: ${palette.text.primary};
            font-family: ${font.secondary};
            font-size: 20px;
            font-weight: normal;
            line-height: 28px;
        }

        h5 {
            border-bottom: none;
            color: ${palette.text.primary};
            font-family: ${font.secondary};
            font-size: 18px;
            font-weight: normal;
            line-height: 26px;
        }

        h6 {
            border-bottom: none;
            color: ${palette.text.primary};
            font-family: ${font.secondary};
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            margin: 0;
        }

        p {
            color: ${palette.text.primary};
            font-family: ${font.primary};
            font-size: 14px;
            font-weight: normal;
            line-height: 24px;
        }

        a {
            color: ${palette.tertiary.default};
            cursor: pointer;
            font-family: ${font.primary};
            font-weight: normal;
            text-decoration: none;
            transition: all 0.3s ease-in;
        }

        a:hover {
            color: ${palette.tertiary.hover};
            text-decoration: underline;
            text-underline-position: under;
        }

        blockquote {
            border-left: 3px solid ${palette.primary.default};
            font-family: ${font.primary};
            margin-left: 0;
            padding-left: 8px;
        }

        ul,
        ol {
            color: ${palette.text.primary};
            font-family: ${font.primary};
            padding-left: 14px;

            li {
                font-family: ${font.primary};
                font-size: 14px;
                line-height: 24px;
            }
        }

        img {
            max-width: 100%;
        }
    `,
);

// SKELETONS
export const MarkdownFieldSkeletonWrapper = styled.div`
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr 1fr;
`;

export const FieldLabelsWrapperSkeleton = styled.div`
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: calc(50% - 16px);
`;

export const MarkdownInputSkeleton = styled.div(
    ({ theme: { palette } }) => css`
        border: 1px solid ${palette.neutral.dividers};
        color: ${palette.text.secondary};
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: 700px;
        overflow-y: scroll;
        padding: 20px;
        resize: none;
    `,
);

export const MarkdownInputContentSkeleton = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const MarkdownEditorSkeleton = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 700px;
    overflow-y: scroll;
    padding: 20px;
`;

export const MarkdownEditorContentSkeleton = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
