import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

import { Card } from '@domains/clients/ions/Card.styled';

export const DetailedActionWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    gap: 24px;
`;

export const DetailedInfoWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    flex: 1;
    padding-top: 20px;
`;

export const DetailedActionTitle = styled(Typography.TextBody_18_30)(
    ({ theme: { palette } }) => css`
        line-height: 26px;
        margin-bottom: 8px;
        padding-left: 16px;
        position: relative;
        width: 100%;

        &:before {
            background: ${palette.text.primary};
            content: '';
            height: 22px;
            left: 0;
            position: absolute;
            top: 0;
            width: 1px;
        }
    `,
);

export const DetailedActionDescription = styled(Typography.TextBody_14_20)`
    padding-left: 16px;
    width: 100%;
`;

export const DetailedContentWrapper = styled(Card.Wrapper)`
    display: inline-flex;
    flex: 3;
`;

export const CardInnerWrapper = styled(Card.InnerWrapper)`
    width: 100%;
`;
