import { ReportHandler } from 'web-vitals';

export const reportWebVitals = async (onPerfEntry?: ReportHandler) => {
    if (onPerfEntry) {
        await import('web-vitals').then(
            ({ getCLS, getFCP, getFID, getLCP, getTTFB }) => {
                getCLS(onPerfEntry);
                getFID(onPerfEntry);
                getFCP(onPerfEntry);
                getLCP(onPerfEntry);
                getTTFB(onPerfEntry);
            },
        );
    }
};
