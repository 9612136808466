import { memo } from 'react';

import { useMarkdownForm } from './useMarkdownForm';

import { Button } from '@speeki/global-ui-components';

import { MarkdownFieldSkeleton } from '@domains/settings/organisms/MarkdownField/MarkdownFieldSkeleton';

import {
    FormButtonWrapper,
    MarkdownField,
    MarkdownFormWrapper,
} from './MarkdownForm.styled';

interface MarkdownFormProps {
    defaultValue?: string;
    loading: boolean;
    onSubmit: (question: string) => void;
    placeholder: string;
    updateLoading: boolean;
}

export const MarkdownForm = memo(
    ({
        defaultValue,
        loading,
        onSubmit,
        placeholder,
        updateLoading,
    }: MarkdownFormProps) => {
        const { errors, handleFormSubmit, markdown, register, setMarkdown } =
            useMarkdownForm({ defaultValue, onSubmit });

        return (
            <>
                <MarkdownFormWrapper>
                    {loading ? (
                        <MarkdownFieldSkeleton />
                    ) : (
                        <MarkdownField
                            {...register('description')}
                            error={!!errors.description}
                            inputClassName={'markdown-wrapper'}
                            label={'Markdown'}
                            markdown={markdown}
                            message={errors.description?.message}
                            placeholder={placeholder}
                            setMarkdown={setMarkdown}
                            testId="Bw5rsZB"
                            tooltip={
                                'You can use Markdown syntax to format your text. To help you get started or remind you of the formatting rules, please check the syntax on the right.'
                            }
                        />
                    )}
                </MarkdownFormWrapper>
                <FormButtonWrapper>
                    <Button
                        disabled={updateLoading}
                        isLoading={updateLoading}
                        onClick={handleFormSubmit}
                        size={'small'}
                        testId="u_1FakC"
                        variant={'primary'}
                    >
                        Save
                    </Button>
                </FormButtonWrapper>
            </>
        );
    },
);
