import { Button, Modal, ModalComponent } from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

interface ConfirmationModalProps extends UseModal {
    description: string;
    loading?: boolean;
    onSubmit: () => void;
}

export const ConfirmationModal = ({
    description,
    loading,
    onSubmit,
    open,
    setOpen,
}: ConfirmationModalProps) => {
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        onSubmit();
        handleClose();
    };

    return (
        <ModalComponent onClose={handleClose} open={open} width={688}>
            <Modal.Wrapper>
                <Modal.Content>
                    <Modal.TitleSecondary>Are you sure?</Modal.TitleSecondary>
                    <Modal.SubTitle>{description}</Modal.SubTitle>
                </Modal.Content>
                <Modal.Footer>
                    <Modal.ButtonsWrapper>
                        <Button
                            onClick={handleClose}
                            size={'small'}
                            testId="EgsrjVG"
                            variant={'secondary'}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={loading}
                            isLoading={loading}
                            onClick={handleSubmit}
                            size={'small'}
                            testId="DXltdBb"
                            variant={'primary'}
                        >
                            Confirm
                        </Button>
                    </Modal.ButtonsWrapper>
                </Modal.Footer>
            </Modal.Wrapper>
        </ModalComponent>
    );
};
