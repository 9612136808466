import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { Loader, Typography } from '@speeki/global-ui-components';
import { IconManager } from '@speeki/icons';

export const VerificationLoader = styled.div(
    ({ theme: { palette } }) => css`
        align-items: center;
        background-color: ${rgba(palette.secondary.default, 0.85)};
        display: flex;
        height: 100vh;
        justify-content: center;
        left: 0;
        outline: 0;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 16px;
        position: fixed;
        top: 0;
        width: 100%;
        will-change: opacity;
        z-index: 999;
    `,
);

export const VerificationWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const DomainLoader = styled(Loader)`
    cursor: default;
    display: inline-flex;
    margin-bottom: 16px;
`;

export const DomainIcon = styled(IconManager)`
    cursor: default;
    display: inline-flex;
    margin: 0 auto 16px;
`;

export const VerificationTextWrapper = styled.div`
    width: 360px;
`;

export const VerificationText = styled(Typography.TextH3_24_34)(
    ({ theme: { palette } }) => css`
        color: ${palette.text.white};
        text-align: center;
    `,
);
