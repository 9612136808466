import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
    Button,
    Modal,
    ModalComponent,
    notify,
} from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import { CompanyInformationForm } from '@components/organisms/CompanyInformationForm/CompanyInformationForm';

import {
    TenantsCountry,
    UpdateTenantInformationDocument,
} from '@graphql/generated/graphql';

import { countriesOptions } from '@utils/dictionary';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';
import { requiredSelect } from '@shared/validations';

interface CompanyInformationModalProps extends UseModal {
    defaultData: {
        companyName: string;
        country: string;
        id: string;
    } | null;
    tenantUuid: string;
}

export const CompanyInformationModal = ({
    defaultData,
    open,
    setOpen,
    tenantUuid,
}: CompanyInformationModalProps) => {
    const methods = useForm<CompanyInformationFormValues>({
        defaultValues: {
            companyName: defaultData?.companyName,
            country: countriesOptions.find(
                (country) => country.key === defaultData?.country,
            ),
        },
        mode: 'onChange',
        resolver: yupResolver(CompanyInformationSchema),
    });

    const {
        formState: { isSubmitted, isSubmitting, isValid },
        handleSubmit,
        reset,
    } = methods;

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    const [updateTenantInformation, { loading }] = useMutation(
        UpdateTenantInformationDocument,
        {
            context: {
                notifyOnError: true,
            },
            onCompleted: () => {
                handleClose?.();
                notify(SUCCESS_MESSAGES.TENANT_INFORMATION_UPDATED).SUCCESS();
            },
            onError: () => {},
        },
    );

    const onSubmit = (data: CompanyInformationFormValues) => {
        void updateTenantInformation({
            variables: {
                input: {
                    companyName: data.companyName,
                    country: data.country.value as TenantsCountry,
                    tenantUuid,
                },
            },
        });
    };

    return (
        <ModalComponent onClose={handleClose} open={open}>
            <Modal.Wrapper>
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>Company information</Modal.Title>
                        <Modal.SubTitle>
                            Edit tenant’s company information.
                        </Modal.SubTitle>
                    </Modal.Header>
                    <FormProvider {...methods}>
                        <CompanyInformationForm />
                    </FormProvider>
                </Modal.Content>
                <Modal.Footer>
                    <Modal.ButtonsWrapper>
                        <Button
                            onClick={handleClose}
                            size={'small'}
                            testId="wZ-2TEt"
                            variant={'secondary'}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!isValid && isSubmitted}
                            isLoading={loading || isSubmitting}
                            onClick={handleSubmit(onSubmit)}
                            size={'small'}
                            testId="MT_sAIX"
                            variant={'primary'}
                        >
                            Save
                        </Button>
                    </Modal.ButtonsWrapper>
                </Modal.Footer>
            </Modal.Wrapper>
        </ModalComponent>
    );
};

const CompanyInformationSchema = yup.object({
    companyName: yup.string().required('This field cannot be empty'),
    country: requiredSelect,
});

export type CompanyInformationFormValues = yup.InferType<
    typeof CompanyInformationSchema
>;
