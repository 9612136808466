import { TextButton } from '@speeki/global-ui-components';

import { DeactivateButton } from '@domains/clients/pages/SingleClientPage/SingleClientPage.styled';

interface ActionButtonProps {
    children: string;
    handleClick: () => void;
    isDemoIcon?: boolean;
    testId: string;
}
export const ClientTextButton = ({
    children,
    handleClick,
    isDemoIcon = false,
    testId,
}: ActionButtonProps) => (
    <TextButton
        iconProps={{
            icon: isDemoIcon ? 'SvgDemoMode24' : 'SvgRefresh24',
            size: 24,
            themeColors: {
                default: {
                    'color-1': 'button.tertiary',
                },
                hover: {
                    'color-1': 'button.tertiaryHover',
                },
            },
        }}
        onClick={handleClick}
        testId={testId}
    >
        {children}
    </TextButton>
);

export const ClientDeactivateButton = ({
    children,
    handleClick,
    testId,
}: ActionButtonProps) => (
    <DeactivateButton
        iconProps={{
            icon: 'SvgEnd24',
            size: 24,
            themeColors: {
                default: {
                    'color-1': 'semantic.error',
                },
                hover: {
                    'color-1': 'semantic.errorHover',
                },
            },
        }}
        onClick={handleClick}
        testId={testId}
    >
        {children}
    </DeactivateButton>
);
