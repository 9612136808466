import { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TAG_NAME_DUPLICATE } from '@speeki/dictionary';
import { notify } from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import {
    GroupTagsCountDocument,
    GroupTagsDocument,
    GroupTagsQuery,
    GroupTagsRowsDocument,
    GroupTagUpsertDocument,
    TagDocument,
    TagUpsertDocument,
} from '@graphql/generated/graphql';

import { getErrorCode } from '@utils/helpers';

import {
    useLazyQuery,
    useMutation,
    useQuery,
    useReactiveVar,
} from '@apollo/client';
import { editedTagIdVar } from '@boot/reactiveVariables';
import { yupResolver } from '@hookform/resolvers/yup';
import { CUSTOM_HOOK_FORM_ERRORS } from '@shared/constants';
import { FORM_VALIDATION_MESSAGES } from '@shared/formValidationMessages';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '@shared/notifyMessages';
import { useGroupTagNameSelect } from '@shared/selects/useGroupTagNameSelect';
import { useRiskAreasSelect } from '@shared/selects/useRiskAreasSelect';
import { requiredSelectV2 } from '@shared/validations';

const TAGS_CALL_OFFSET_LIMIT = 100;

export const useChildTagEditModal = (setOpen: UseModal['setOpen']) => {
    const [existingGroupTagSelected, setExistingGroupTagSelected] =
        useState(true);

    const {
        control,
        formState: { errors, isDirty, isValid },
        handleSubmit: handleFormSubmit,
        register,
        reset,
        setError,
        setValue,
        watch,
    } = useForm<ChildTagEditFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(ChildTagEditSchema),
    });

    const { field: riskAreaField } = useController({
        control,
        name: 'riskArea',
    });

    const { field: groupTagNameField } = useController({
        control,
        name: 'groupTagName',
    });

    const editedTagId = useReactiveVar(editedTagIdVar);
    const { data: tagData } = useQuery(TagDocument, {
        fetchPolicy: 'cache-and-network',
        variables: {
            uuid: editedTagId,
        },
    });

    const [getTags, { loading: tagsLoading }] = useLazyQuery(
        GroupTagsDocument,
        { fetchPolicy: 'cache-and-network' },
    );
    const [getTagsCount, { loading: tagsCountLoading }] = useLazyQuery(
        GroupTagsCountDocument,
        { fetchPolicy: 'network-only' },
    );

    const compareGroupTagsLoading = tagsLoading || tagsCountLoading;

    const [groupTagUpsert, { loading: groupTagUpsertLoading }] = useMutation(
        GroupTagUpsertDocument,
        {
            context: {
                notifyOnError: true,
            },
            onError: () => {},
        },
    );

    const [tagUpsert, { loading: tagUpsertLoading }] = useMutation(
        TagUpsertDocument,
        {
            context: {
                notifyOnError: true,
            },
            onError: () => {},
        },
    );

    const upsertLoading = tagUpsertLoading || groupTagUpsertLoading;

    const handleClose = () => setOpen(false);

    const handleAddNewTag = (name: string) => {
        setValue(
            'groupTagName',
            {
                label: name,
                value: name,
            },
            {
                shouldDirty: true,
                shouldValidate: true,
            },
        );
        setExistingGroupTagSelected(false);
    };

    const handleSubmit = handleFormSubmit(
        ({ childTagName, groupTagName, riskArea }) => {
            existingGroupTagSelected
                ? void tagUpsert({
                      onCompleted: () => {
                          notify(SUCCESS_MESSAGES.TAG_UPDATE).SUCCESS();
                          handleClose();
                      },

                      onError: (error) => {
                          if (getErrorCode(error) === TAG_NAME_DUPLICATE)
                              setError('childTagName', {
                                  message:
                                      ERROR_MESSAGES.TAG_NAME_ALREADY_EXISTS,
                                  type: CUSTOM_HOOK_FORM_ERRORS.NAME_ALREADY_EXISTS,
                              });
                      },
                      refetchQueries: [GroupTagsRowsDocument],
                      variables: {
                          input: {
                              uuid: editedTagId,
                              groupTagUuid: groupTagName.value,
                              name: childTagName,
                          },
                      },
                  })
                : void groupTagUpsert({
                      onCompleted: () => {
                          notify(SUCCESS_MESSAGES.TAG_UPDATE).SUCCESS();
                          handleClose();
                      },
                      refetchQueries: [GroupTagsRowsDocument],
                      variables: {
                          input: {
                              name: groupTagName.label,
                              riskAreaUuid: riskArea.value,
                              tagUpsertInput: [
                                  {
                                      uuid: editedTagId,
                                      name: childTagName,
                                  },
                              ],
                          },
                      },
                  });
        },
    );

    const riskAreaValue = watch('riskArea.value') ?? '';

    const riskAreasSelect = useRiskAreasSelect();
    const groupTagNameSelect = useGroupTagNameSelect({
        riskAreaUuids: [riskAreaValue],
    });

    const compareGroupTags = async () => {
        const result: GroupTagsQuery['groupTags']['nodes'] = [];

        //NOTE: start offset, change with every next request
        let offset = 1;

        try {
            const { data } = await getTagsCount({
                variables: {
                    input: {
                        riskAreaUuids: [riskAreaValue],
                    },
                },
            });

            if (!data) throw new Error('Failed to get tags count');

            while (offset <= data?.groupTags.totalCount) {
                await getTags({
                    onCompleted: (data) => {
                        result.push(...data.groupTags.nodes);
                        offset += TAGS_CALL_OFFSET_LIMIT;
                    },
                    variables: {
                        input: {
                            paging: {
                                limit: TAGS_CALL_OFFSET_LIMIT,
                                offset: offset,
                            },
                            riskAreaUuids: [riskAreaValue],
                        },
                    },
                });
            }
        } catch (error) {
            console.error(error);
        }

        const existingGroupTag = result.find(
            (item) => item.name === groupTagNameField?.value?.label,
        );

        existingGroupTag
            ? groupTagNameField.onChange({
                  label: existingGroupTag.name,
                  value: existingGroupTag.uuid,
              })
            : groupTagNameField.onChange(undefined);
    };

    useEffect(() => {
        if (riskAreaValue) void compareGroupTags();
    }, [riskAreaValue]);

    useEffect(() => {
        if (!tagData) return;

        const {
            tag: {
                groupTag: {
                    name: groupTagName,
                    riskArea: { label: riskAreaLabel, uuid: riskAreaId },
                    uuid: groupTagUuid,
                },
                name: childTagName,
            },
        } = tagData;

        reset({
            childTagName,
            groupTagName: {
                label: groupTagName,
                value: groupTagUuid,
            },
            riskArea: {
                label: riskAreaLabel,
                value: riskAreaId,
            },
        });
    }, [tagData]);

    return {
        compareGroupTagsLoading,
        errors,
        groupTagNameField,
        groupTagNameSelect,
        handleAddNewTag,
        handleClose,
        handleSubmit,
        isDirty,
        isValid,
        register,
        riskAreaField,
        riskAreaValue,
        riskAreasSelect,
        setExistingGroupTagSelected,
        upsertLoading,
    };
};

const ChildTagEditSchema = yup.object({
    childTagName: yup
        .string()
        .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
    groupTagName: requiredSelectV2,
    riskArea: requiredSelectV2,
});

type ChildTagEditFormValues = yup.InferType<typeof ChildTagEditSchema>;
