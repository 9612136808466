import { FormProvider } from 'react-hook-form';

import { useNewTagAddModal } from './useNewTagAddModal';

import { Button, Modal, ModalComponent } from '@speeki/global-ui-components';
import { IconManager } from '@speeki/icons';
import { UseModal } from '@speeki/react-hooks';

import { NewTagForm } from '@components/organisms/NewTagForm/NewTagForm';

import { FooterText, FooterTextWrapper } from './NewTagAddModal.styled';

export const NewTagAddModal = ({ open, setOpen }: UseModal) => {
    const {
        existingGroupTagSelected,
        handleClose,
        handleSubmit,
        isSubmitted,
        isValid,
        methods,
        setExistingGroupTagSelected,
        setShowTagsArray,
        upsertLoading,
    } = useNewTagAddModal(setOpen);

    return (
        <ModalComponent
            onClose={handleClose}
            open={open}
            preventCloseEsc
            preventCloseOutside
            width={985}
        >
            <Modal.Content>
                <Modal.Header>
                    <Modal.Title>Add new</Modal.Title>
                    <Modal.SubTitle color="black">
                        Add a new tag or group tag that can be associated with
                        your data.
                    </Modal.SubTitle>
                </Modal.Header>
                <FormProvider {...methods}>
                    <NewTagForm
                        existingGroupTagSelected={existingGroupTagSelected}
                        setExistingGroupTagSelected={
                            setExistingGroupTagSelected
                        }
                        setShowTagsArray={setShowTagsArray}
                    />
                </FormProvider>
            </Modal.Content>
            <Modal.Footer>
                {!existingGroupTagSelected && (
                    <FooterTextWrapper>
                        <IconManager
                            icon={'SvgInfo24'}
                            size={24}
                            themeColors={{
                                default: {
                                    'color-1': 'text.secondary',
                                },
                            }}
                        />
                        <FooterText>
                            You can create your own group tag without adding any
                            tags.
                        </FooterText>
                    </FooterTextWrapper>
                )}
                <Modal.ButtonsWrapper>
                    <Button
                        disabled={upsertLoading}
                        onClick={handleClose}
                        size={'small'}
                        testId="GSO41j5"
                        variant={'secondary'}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={(!isValid && isSubmitted) || upsertLoading}
                        isLoading={upsertLoading}
                        onClick={handleSubmit}
                        size={'small'}
                        testId="SniHMiO"
                        variant={'primary'}
                    >
                        Save
                    </Button>
                </Modal.ButtonsWrapper>
            </Modal.Footer>
        </ModalComponent>
    );
};
