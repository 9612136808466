import {
    Button,
    InputCheck,
    PasswordField,
} from '@speeki/global-ui-components';

import { ButtonRow, FieldRow } from '@components/ions/Form.styled';
import { usePasswordResetConfirmForm } from '@components/organisms/PasswordResetConfirmForm/usePasswordResetConfirmForm';

import { passwordRequirements } from '@shared/passwordRequirements';

import {
    ChangePasswordFormWrapper,
    StyledFieldRow,
} from './PasswordResetConfirmForm.styled';

export const PasswordResetConfirmForm = () => {
    const {
        dirtyFields,
        errors,
        handleSubmit,
        isDisabled,
        isSubmitted,
        isSubmitting,
        loading,
        newPasswordValue,
        register,
    } = usePasswordResetConfirmForm();

    return (
        <ChangePasswordFormWrapper noValidate onSubmit={handleSubmit}>
            <StyledFieldRow>
                <PasswordField
                    {...register('newPassword')}
                    className="htmlToImageIgnore"
                    error={!!errors.newPassword}
                    id={'change-password-new'}
                    label={'New password'}
                    placeholder={'Enter new password'}
                    testId="vUSofGL"
                />
                <InputCheck
                    isDirty={dirtyFields.newPassword || isSubmitted}
                    isSubmitted={isSubmitted}
                    passwordValue={newPasswordValue}
                    requirements={passwordRequirements}
                    testId="Gtwd4A-"
                />
            </StyledFieldRow>
            <FieldRow>
                <PasswordField
                    {...register('confirmNewPassword')}
                    className="htmlToImageIgnore"
                    error={!!errors.confirmNewPassword}
                    id={'change-password-new'}
                    label={'Confirm new password'}
                    message={errors.confirmNewPassword?.message}
                    placeholder={'Re-enter new password'}
                    testId="LMglsNU"
                />
            </FieldRow>
            <ButtonRow>
                {/* TODO:: check conditions after connect API */}
                <Button
                    disabled={isDisabled}
                    fullWidth
                    isLoading={isSubmitting || loading}
                    testId="NV9aJFc"
                    type={'submit'}
                    variant={'primary'}
                >
                    Save and log in
                </Button>
            </ButtonRow>
        </ChangePasswordFormWrapper>
    );
};
