import { useBlocker } from 'react-router-dom';

import { Button, Modal, ModalComponent } from '@speeki/global-ui-components';
import { useAlertBeforeLeave, useModal } from '@speeki/react-hooks';

interface CancelAddNewClientModalProps {
    when: boolean;
}

export const CancelAddNewClientModal = ({
    when,
}: CancelAddNewClientModalProps) => {
    const { open, setOpen } = useModal();
    const onClose = () => {
        blocker?.reset?.();
        setOpen(false);
    };

    const blocker = useBlocker((): boolean => {
        if (when) {
            setOpen(true);
            return true;
        }

        return false;
    });

    const handleRedirect = () => {
        setOpen(false);
        blocker?.proceed?.();
    };

    useAlertBeforeLeave({
        when: when && blocker.state === 'blocked',
    });

    return (
        <ModalComponent
            onClose={onClose}
            open={open}
            preventCloseEsc
            preventCloseOutside
            width={648}
        >
            <Modal.Content>
                <Modal.TitleSecondary>{'Are you sure?'}</Modal.TitleSecondary>
                <Modal.SubTitle color="black">
                    {
                        'Are you sure you want to leave? Unsaved changes will be lost.'
                    }
                </Modal.SubTitle>
            </Modal.Content>
            <Modal.Footer>
                <Modal.ButtonsWrapper>
                    <Button
                        onClick={onClose}
                        size={'small'}
                        testId="X5RwcTX"
                        variant={'secondary'}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleRedirect}
                        size={'small'}
                        testId="4g_h7MW"
                        variant={'primary'}
                    >
                        Leave without saving
                    </Button>
                </Modal.ButtonsWrapper>
            </Modal.Footer>
        </ModalComponent>
    );
};
