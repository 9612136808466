export const SUCCESS_MESSAGES = {
    ACTIVITY_UPDATE: 'Activity has been updated',
    ADMIN_USER_ADD: 'Admin user has been added',
    ADMIN_USER_DEACTIVATE: (name: string) =>
        `Admin user “${name}” has been deactivated`,
    ADMIN_USER_EDIT: 'Admin user’s information has been updated',
    ADMIN_USER_REACTIVATE: (name: string) =>
        `Admin user “${name}” has been reactivated`,
    BLOCKED_DOMAINS_EDIT: 'Blocked domains have been updated',
    BLOCKED_DOMAIN_ADD: 'Blocked domain has been added',
    BLOCKED_DOMAIN_EDIT: 'Blocked domain has been updated',
    CREDENTIALS_SUCCESSFULLY_CHANGED: 'Watson credentials has been updated.',
    ELINE_NUMBER_ADDED: 'Phone number has been successfully added',
    ELINE_NUMBER_DELETED: 'Phone number has been removed',
    ELINE_NUMBER_UPDATED: 'Phone number details have been updated',
    ESG_PROFILE_UPDATED: 'ESG profile has been updated',
    GLOSSARY_UPDATED: 'Glossary has been successfully updated',
    GROUP_TAG_ADD: 'Group tag has been added',
    GROUP_TAG_DELETE: 'Group tag has been deleted',
    GROUP_TAG_UPDATE: 'Group tag has been updated',
    GUIDELINE_ADD: 'Guideline has been added.',
    GUIDELINE_DELETE: 'Guideline has been deleted.',
    GUIDELINE_UPDATE: 'Guideline has been updated.',
    LOGIN_SUCCESSFULLY: 'You have been successfully logged in',
    PASSWORD_CHANGE_SUCCESSFULLY: 'Your password has been changed',
    RELEASE_NOTES_PUBLISHED: 'Release note has been published',
    RELEASE_NOTES_UPDATED: 'Release note has been saved',
    SLOT_ADD_SUCCESSFULLY: 'Slot has been added.',
    SLOT_UPDATED_SUCCESSFULLY: 'Slot has been updated.',
    TAGS_ADD: (isMany?: boolean) =>
        `Tag${isMany ? 's have' : ' has'} been added`,
    TAG_DELETE: `Tag has been deleted`,
    TAG_UPDATE: `Tag has been updated`,
    TENANT_INFORMATION_UPDATED: 'Tenant information has been updated.',
    TENANT_PRIMARY_CONTACT_INFORMATION_UPDATED:
        'Tenant primary contact information has been updated.',
    TENANT_REACTIVATION: (name: string) =>
        `Tenant “${name}” has been reactivated`,
    TENANT_SUBSCRIPTION_UPDATED: 'Tenant subscription has been updated.',
} as const;

export const ERROR_MESSAGES = {
    INVALID_EMAIL_OR_PASSWORD: 'Incorrect email address or password',
    SELECT_FETCH: 'Something went wrong. Try again later',
    TAG_NAME_ALREADY_EXISTS:
        'Tag with this name already exists. Try another one',
} as const;

export const INFO_MESSAGES = {
    EMAIL_SENT: 'Email has been sent',
    LOGGED_IN: 'You have been logged in',
    TENANT_DEACTIVATION: (name: string) =>
        `Tenant “${name}” has been deactivated`,
    TENANT_DELETION: (name: string) => `Tenant “${name}” has been deleted`,
} as const;
