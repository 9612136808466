import { TagsTable } from '@components/organisms/TagsTable/TagsTable';

import { SettingsLayout } from '@domains/settings/templates/SettingsLayout/SettingsLayout';

export const TagManagementPage = () => {
    return (
        <SettingsLayout>
            <TagsTable />
        </SettingsLayout>
    );
};
