import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import { App } from 'src/App/App';

import { resolveEnv } from '@speeki/frontend-utils';

import { PermissionRoute } from '@services/RouteService/routes/PermissionRoute';

import { LoginPage } from '@domains/auth/pages/LoginPage/LoginPage';
import { PasswordResetConfirmPage } from '@domains/auth/pages/PasswordResetConfirmPage/PasswordResetConfirmPage';
import { PasswordResetEmailPage } from '@domains/auth/pages/PasswordResetEmailPage/PasswordResetEmailPage';
import { PasswordResetPage } from '@domains/auth/pages/PasswordResetPage/PasswordResetPage';
import { DetailsTab } from '@domains/clients/organisms/DetailsTab/DetailsTab';
import { MicroservicesTab } from '@domains/clients/organisms/MicroservicesTab/MicroservicesTab';
import { SubsidiaryTab } from '@domains/clients/organisms/SubsidiaryTab/SubsidiaryTab';
import { AddNewClientPage } from '@domains/clients/pages/AddNewClient/AddNewClientPage/AddNewClientPage';
import { ValidateClientPage } from '@domains/clients/pages/AddNewClient/ValidateClientPage/ValidateClientPage';
import { ClientsPage } from '@domains/clients/pages/ClientsPage/ClientsPage';
import { SingleClientPage } from '@domains/clients/pages/SingleClientPage/SingleClientPage';
import { MessagingPage } from '@domains/messaging/pages/MessagingPage/MessagingPage';
import { ReleaseNotePage } from '@domains/releaseNote/pages/ReleaseNotePage/ReleaseNotePage';
import { EsgActivityEditPage } from '@domains/settings/pages/EsgActivityEditPage/EsgActivityEditPage';
import { EsgProfilePage } from '@domains/settings/pages/EsgProfilePage/EsgProfilePage';
import { EsgProfileTopicPage } from '@domains/settings/pages/EsgProfileTopicPage/EsgProfileTopicPage';
import { GlossaryPage } from '@domains/settings/pages/GlossaryPage/GlossaryPage';
import { GuidelineAddPage } from '@domains/settings/pages/GuidelinePage/GuidelineAddPage/GuidelineAddPage';
import { GuidelineEditPage } from '@domains/settings/pages/GuidelinePage/GuidelineEditPage/GuidelineEditPage';
import { GuidelineTablePage } from '@domains/settings/pages/GuidelinePage/GuidelineTablePage/GuidelineTablePage';
import { OtherPage } from '@domains/settings/pages/OtherPage/OtherPage';
import { TagManagementPage } from '@domains/settings/pages/TagManagementPage/TagManagementPage';
import * as Sentry from '@sentry/react';
import { internalPaths } from '@shared/paths';

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const routes: RouteObject[] = [
    {
        children: [
            {
                element: <LoginPage />,
                path: internalPaths.login,
            },
            {
                element: <PasswordResetPage />,
                path: internalPaths.passwordReset,
            },
            {
                element: <PasswordResetEmailPage />,
                path: internalPaths.passwordResetEmail,
            },
            {
                element: <PasswordResetConfirmPage />,
                path: internalPaths.passwordResetConfirm,
            },
            {
                element: <ValidateClientPage />,
                path: internalPaths.validationClient,
            },
            {
                element: <AddNewClientPage />,
                path: internalPaths.addClient,
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <ValidateClientPage />
                    </PermissionRoute>
                ),
                path: internalPaths.validationClient,
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <AddNewClientPage />
                    </PermissionRoute>
                ),
                path: internalPaths.addClient,
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <ClientsPage />
                    </PermissionRoute>
                ),
                path: internalPaths.clients,
            },
            {
                children: [
                    {
                        element: <DetailsTab />,
                        path: 'details',
                    },
                    {
                        element: <MicroservicesTab />,
                        path: 'microservices',
                    },
                    {
                        element: <SubsidiaryTab />,
                        path: 'subsidiary-sites',
                    },
                ],
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <SingleClientPage />
                    </PermissionRoute>
                ),
                path: `${internalPaths.client(':clientId')}/*`,
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <MessagingPage />
                    </PermissionRoute>
                ),
                path: internalPaths.messaging,
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <ReleaseNotePage />
                    </PermissionRoute>
                ),
                path: internalPaths.releaseNote,
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <TagManagementPage />
                    </PermissionRoute>
                ),
                path: internalPaths.tagManagement,
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <OtherPage />
                    </PermissionRoute>
                ),
                path: internalPaths.other,
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <EsgProfilePage />
                    </PermissionRoute>
                ),
                path: internalPaths.esgProfile,
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <EsgProfileTopicPage />
                    </PermissionRoute>
                ),
                path: internalPaths.esgProfileTopic(':topic'),
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <EsgActivityEditPage />
                    </PermissionRoute>
                ),
                path: internalPaths.esgActivityEdit(':topic', ':questionId'),
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <GlossaryPage />
                    </PermissionRoute>
                ),
                path: internalPaths.glossary,
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <GuidelineTablePage />
                    </PermissionRoute>
                ),
                path: internalPaths.guideline,
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <GuidelineAddPage />
                    </PermissionRoute>
                ),
                path: internalPaths.guidelineAdd,
            },
            {
                element: (
                    <PermissionRoute redirectTo={'/'}>
                        <GuidelineEditPage />
                    </PermissionRoute>
                ),
                path: internalPaths.guidelineEdit(':guidelineId'),
            },
            {
                element: <Navigate replace to={'/'} />,
                path: '*',
            },
        ],
        element: <App />,
        path: '/',
    },
];

const publicUrl = resolveEnv('PUBLIC_URL');

export const router = sentryCreateBrowserRouter(routes, {
    basename: publicUrl.endsWith('/') ? publicUrl : `${publicUrl}/`,
});
