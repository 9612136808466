import parsePhoneNumber from 'libphonenumber-js';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useModal } from '@speeki/react-hooks';

import { ClientELineNumbersDocument } from '@graphql/generated/graphql';

import { countriesOptions } from '@utils/dictionary';

import { useLazyQuery } from '@apollo/client';
import { ElineNumbersTableRow } from '@domains/clients/organisms/ELineNumbers/ELineNumbersTable/ELineNumbersTable.settings';
import { ELineNumbersUpsertFormValues } from '@domains/clients/organisms/ELineNumbers/ELineNumbersUpsertModal/ELineNumbersUpsertModal';

export const useELineNumbersCard = () => {
    const { clientId } = useParams<{ clientId: string }>();
    const [editedItem, setEditedItem] = useState<
        ElineNumbersTableRow | undefined
    >(undefined);
    const [deletedItem, setDeletedItem] = useState<ElineNumbersTableRow>();

    const upsertModal = useModal();

    const [getNumbersList, { data }] = useLazyQuery(
        ClientELineNumbersDocument,
        {
            context: {
                notifyOnError: true,
            },
            fetchPolicy: 'cache-and-network',
        },
    );
    const showTable = !!data?.numbers.elineNumbers.length;

    const defaultValues: ELineNumbersUpsertFormValues | undefined =
        useMemo(() => {
            const parsedPhoneNumber = parsePhoneNumber(
                editedItem?.phoneNumber || '',
            );

            return editedItem
                ? {
                      country: countriesOptions.find(
                          (country) => country.label === editedItem?.country,
                      ) || {
                          key: '',
                          label: '',
                          value: '',
                      },
                      phone: {
                          phoneNumber: parsedPhoneNumber?.nationalNumber || '',
                          prefix: parsedPhoneNumber?.countryCallingCode || '',
                      },
                  }
                : undefined;
        }, [editedItem]);

    useEffect(() => {
        void getNumbersList({
            variables: {
                tenantUuid: clientId ?? '',
            },
        });
    }, []);

    return {
        defaultValues,
        editedItem,
        setEditedItem,
        showTable,
        tableProps: {
            deletedItem,
            setDeletedItem,
            setEditedItem,
        },
        upsertModal,
        upsertModalProps: {
            defaultValues,
            editedItem,
            setEditedItem,
            upsertModal,
        },
    };
};
