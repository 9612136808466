import { useLocation } from 'react-router-dom';

import { IconClients } from '@components/svgs/IconClients';
import { IconMegaphoneWhite } from '@components/svgs/IconMegaphoneWhite';
import { IconMessagingWhite } from '@components/svgs/IconMessagingWhite';
import { SpeekiLogoWhite } from '@components/svgs/SpeekiLogoWhite';

import { internalPaths } from '@shared/paths';

import {
    IconWrapper,
    LogoWrapper,
    MenuItemText,
    SidebarMenu,
    SidebarMenuItem,
    SidebarWrapper,
} from './Sidebar.styled';

export const Sidebar = () => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split('/')[1];

    return (
        <SidebarWrapper>
            <LogoWrapper>
                <SpeekiLogoWhite />
            </LogoWrapper>
            <SidebarMenu>
                <SidebarMenuItem
                    className={
                        splitLocation === internalPaths.clients ? 'active' : ''
                    }
                    to={internalPaths.clients}
                >
                    <IconWrapper>
                        <IconClients />
                    </IconWrapper>
                    <MenuItemText>Clients</MenuItemText>
                </SidebarMenuItem>
                <SidebarMenuItem
                    className={splitLocation === '' ? 'active' : ''}
                    to={internalPaths.messaging}
                >
                    <IconWrapper>
                        <IconMessagingWhite />
                    </IconWrapper>
                    <MenuItemText>Incidents</MenuItemText>
                </SidebarMenuItem>
                <SidebarMenuItem
                    className={
                        splitLocation === internalPaths.releaseNote
                            ? 'active'
                            : ''
                    }
                    to={internalPaths.releaseNote}
                >
                    <IconWrapper>
                        <IconMegaphoneWhite />
                    </IconWrapper>
                    <MenuItemText>Release note</MenuItemText>
                </SidebarMenuItem>

                {/* TODO:: add correct path in the future */}
                {/*<SidebarMenuItem*/}
                {/*    className={splitLocation === '' ? '' : ''}*/}
                {/*    to={internalPaths.survey}*/}
                {/*>*/}
                {/*    <IconWrapper>*/}
                {/*        <IconSurveyWhite />*/}
                {/*    </IconWrapper>*/}
                {/*    <MenuItemText>Survey</MenuItemText>*/}
                {/*</SidebarMenuItem>*/}
                {/*/!* TODO:: add correct path in the future *!/*/}
                {/*<SidebarMenuItem*/}
                {/*    className={splitLocation === '' ? '' : ''}*/}
                {/*    to={internalPaths.pulseSurveys}*/}
                {/*>*/}
                {/*    <IconWrapper>*/}
                {/*        <IconPulseSurveyWhite />*/}
                {/*    </IconWrapper>*/}
                {/*    <MenuItemText>Pulse survey</MenuItemText>*/}
                {/*</SidebarMenuItem>*/}
                {/*/!* TODO:: add correct path in the future *!/*/}
                {/*<SidebarMenuItem*/}
                {/*    className={splitLocation === '' ? '' : ''}*/}
                {/*    to={internalPaths.declareAndDisclose}*/}
                {/*>*/}
                {/*    <IconWrapper>*/}
                {/*        <IconDeclareAndDiscloseWhite />*/}
                {/*    </IconWrapper>*/}
                {/*    <MenuItemText>Declare & Disclose</MenuItemText>*/}
                {/*</SidebarMenuItem>*/}
            </SidebarMenu>
        </SidebarWrapper>
    );
};
