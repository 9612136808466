import { BlockedDomainsFormValues } from '../BlockedDomainsUpsertModal/useBlockedDomainsUpsertModal';
import { useMemo, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { BlockedDomainsCardTableRow } from './BlockedDomainsCard.settings';

import { notify, QueryFilters } from '@speeki/global-ui-components';

import {
    BannedEmailBatchStatusChangeDocument,
    BlockedDomainFragment,
    BlockedDomainFragmentDoc,
    BlockedDomainsListDocument,
} from '@graphql/generated/graphql';

import { useFragment, useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';

export const useBlockedDomainsCard = () => {
    const [editedUuid, setEditedUuid] = useState<string | undefined>();
    const [searchPhrase, setSearchPhrase] = useState<string | undefined>();

    const [getBlockedDomains, { data: domainsData, loading: domainsLoading }] =
        useLazyQuery(BlockedDomainsListDocument, {
            fetchPolicy: 'cache-and-network',
        });

    const [blockedDomainsStatusUpsert, { loading: upsertLoading }] =
        useMutation(BannedEmailBatchStatusChangeDocument, {
            context: {
                notifyOnError: true,
            },
            onCompleted: () => {
                notify(SUCCESS_MESSAGES.BLOCKED_DOMAINS_EDIT).SUCCESS();
            },
            onError: () => {},
            refetchQueries: [BlockedDomainsListDocument],
        });

    const { data: blockedDomain } = useFragment<BlockedDomainFragment>({
        fragment: BlockedDomainFragmentDoc,
        fragmentName: 'BlockedDomain',
        from: `BannedEmail:${editedUuid}`,
    });

    const { nodes, totalCount } = domainsData?.blockedDomains || {};
    const noData = totalCount === 0 && typeof searchPhrase !== 'string';

    const tableData: BlockedDomainsCardTableRow[] = useMemo(
        () =>
            nodes?.map(({ email, isActive, uuid }) => ({
                id: uuid,
                domain: {
                    enabled: !!isActive,
                    text: email,
                },
            })) || [],
        [domainsData],
    );

    const formValues: BlockedDomainsCardValues = useMemo(
        () => ({
            blockedDomains:
                nodes?.map((domain) => ({
                    id: domain.uuid,
                    enabled: domain.isActive,
                    label: domain.email,
                })) || [],
        }),
        [domainsData],
    );

    const {
        control,
        formState: { isDirty, isValid },
        handleSubmit: handleFormSubmit,
        reset,
    } = useForm<BlockedDomainsCardValues>({
        defaultValues: formValues,
        mode: 'onChange',
        resolver: yupResolver(BlockedDomainsCardSchema),
        values: formValues,
    });

    const blockedDomainsField = useController({
        control,
        name: 'blockedDomains',
    });

    const blockedDomainData: BlockedDomainsFormValues | undefined =
        useMemo(() => {
            return !!editedUuid
                ? {
                      uuid: blockedDomain.uuid,
                      domain: blockedDomain.email ?? '',
                      enabled: blockedDomain.isActive ?? true,
                  }
                : undefined;
        }, [editedUuid, blockedDomain]);

    const getTableData = ({ paging, searchPhrase }: QueryFilters) => {
        void getBlockedDomains({
            variables: { input: { freeTextQuery: searchPhrase || '', paging } },
        });
    };

    const handleSave = handleFormSubmit(({ blockedDomains }) => {
        void blockedDomainsStatusUpsert({
            variables: {
                input: {
                    batchInput: blockedDomains.map((domain) => ({
                        uuid: domain.id,
                        isEnabled: domain.enabled,
                    })),
                },
            },
        });
    });

    return {
        blockedDomainData,
        blockedDomainsField,
        getTableData,
        handleSave,
        isDirty,
        isValid,
        loading: domainsLoading,
        noData,
        reset,
        searchPhrase,
        setEditedUuid,
        setSearchPhrase,
        tableData,
        totalCount: totalCount ?? 0,
        upsertLoading,
    };
};
const BlockedDomainsCardSchema = yup.object({
    blockedDomains: yup
        .array()
        .of(
            yup.object({
                id: yup.string().required(),
                enabled: yup.boolean().required(),
                label: yup.string().required(),
            }),
        )
        .required(''),
});

type BlockedDomainsCardValues = yup.InferType<typeof BlockedDomainsCardSchema>;
