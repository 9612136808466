import { useChildTagEditModal } from './useChildTagEditModal';

import {
    Button,
    DeleteButton,
    GroupTagSelectField,
    Modal,
    ModalComponent,
    PaginatedSelectField,
    TextField,
} from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import { DividedFieldRow, FormWrapper } from '@components/ions/Form.styled';

import { DeleteButtonWrapper } from '@domains/clients/ions/Modal.styled';

interface ChildTagEditModalProps extends UseModal {
    openWarningModal: () => void;
}

export const ChildTagEditModal = ({
    open,
    openWarningModal,
    setOpen,
}: ChildTagEditModalProps) => {
    const {
        compareGroupTagsLoading,
        errors,
        groupTagNameField,
        groupTagNameSelect,
        handleAddNewTag,
        handleClose,
        handleSubmit,
        isDirty,
        isValid,
        register,
        riskAreaField,
        riskAreaValue,
        riskAreasSelect,
        setExistingGroupTagSelected,
        upsertLoading,
    } = useChildTagEditModal(setOpen);

    return (
        <ModalComponent
            onClose={handleClose}
            open={open}
            preventCloseEsc
            preventCloseOutside
            width={985}
        >
            <Modal.Content>
                <Modal.Header>
                    <Modal.Title>Add new</Modal.Title>
                    <Modal.SubTitle color="black">
                        Add a new tag or group tag that can be associated with
                        your data.
                    </Modal.SubTitle>
                </Modal.Header>
                <FormWrapper>
                    <DividedFieldRow>
                        <PaginatedSelectField
                            {...riskAreaField}
                            {...riskAreasSelect}
                            closeMenuOnSelect={true}
                            error={!!errors.riskArea}
                            label={'Topic name'}
                            message={
                                errors.riskArea?.message ||
                                errors.riskArea?.value?.message
                            }
                            placeholder={'Select topic'}
                            testId="hlNxks-"
                        />
                        <GroupTagSelectField
                            {...groupTagNameField}
                            {...groupTagNameSelect}
                            closeMenuOnSelect={true}
                            error={!!errors.groupTagName}
                            isDisabled={!riskAreaField.value}
                            key={JSON.stringify(riskAreaValue)}
                            label={'Group tag name'}
                            maxLength={75}
                            message={
                                errors.groupTagName?.message ||
                                errors.groupTagName?.value?.message
                            }
                            onChange={(newVal) => {
                                groupTagNameField.onChange(newVal);
                                setExistingGroupTagSelected(true);
                            }}
                            onConfirm={handleAddNewTag}
                            placeholder={'Select or enter group tag name'}
                            testId="2Evfs3L"
                        />
                    </DividedFieldRow>
                    <DividedFieldRow>
                        <TextField
                            {...register('childTagName')}
                            error={!!errors.childTagName}
                            label={'Tag name'}
                            maxLength={75}
                            message={errors.childTagName?.message}
                            placeholder={'Enter tag name'}
                            testId="1NGcDfo"
                        />
                    </DividedFieldRow>
                </FormWrapper>
            </Modal.Content>
            <Modal.Footer>
                <DeleteButtonWrapper>
                    <DeleteButton onClick={openWarningModal} testId="n_LLpUA">
                        Delete tag
                    </DeleteButton>
                </DeleteButtonWrapper>
                <Modal.ButtonsWrapper>
                    <Button
                        disabled={upsertLoading}
                        onClick={handleClose}
                        size={'small'}
                        testId="7yW7OFr"
                        variant={'secondary'}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={
                            !isValid || !isDirty || compareGroupTagsLoading
                        }
                        isLoading={upsertLoading}
                        onClick={handleSubmit}
                        size={'small'}
                        testId="gk7l0oK"
                        variant={'primary'}
                    >
                        Save
                    </Button>
                </Modal.ButtonsWrapper>
            </Modal.Footer>
        </ModalComponent>
    );
};
