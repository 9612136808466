import { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { Typography } from '@speeki/global-ui-components';
import { IconManager } from '@speeki/icons';
import { useEventListener, useOnClickOutside } from '@speeki/react-hooks';

import {
    BlockquoteTypography,
    CloseButtonWrapper,
    ItalicTypography,
    MarkdownLink,
    MarkdownSyntaxList,
    MarkdownSyntaxListItem,
    MarkdownSyntaxModalContent,
    MarkdownSyntaxModalInner,
    MarkdownSyntaxModalTitle,
    MarkdownSyntaxModalWrapper,
    OrderedList,
    StrikethroughTypography,
    UnorderedList,
} from './MarkdownSyntaxModal.styled';

interface ModalProps {
    backgroundOpacity?: number;
    className?: string;
    closeButton?: boolean;
    onClose: () => void;
    open?: boolean;
    preventCloseEsc?: boolean;
    preventCloseOutside?: boolean;
}

export const MarkdownSyntaxModal = ({
    className,
    onClose,
    open,
    preventCloseEsc,
    preventCloseOutside,
}: ModalProps) => {
    const contentRef = useRef(null);
    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            !preventCloseEsc && event.key === 'Escape' && onClose();
        },
        [onClose],
    );

    useEventListener('keydown', handleKeyDown);

    useOnClickOutside(contentRef, () => !preventCloseOutside && onClose());

    // TODO: create hook and add to package
    useEffect(() => {
        if (open) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [open]);

    if (!open) return null;

    return createPortal(
        <MarkdownSyntaxModalWrapper className={className}>
            <MarkdownSyntaxModalInner>
                <CloseButtonWrapper>
                    <IconManager
                        icon={'SvgCloseBig24'}
                        iconProps={{
                            onClick: onClose,
                        }}
                        size={24}
                    />
                </CloseButtonWrapper>
                <MarkdownSyntaxModalContent ref={contentRef}>
                    <MarkdownSyntaxModalTitle>
                        Markdown syntax
                    </MarkdownSyntaxModalTitle>
                    <Typography.TextBody_14_20>
                        You can check the Markdown syntax below and use it to
                        modify the text as needed.
                    </Typography.TextBody_14_20>
                    <MarkdownSyntaxList>
                        <MarkdownSyntaxListItem>
                            <Typography.TextH1_36_52>
                                Heading 1
                            </Typography.TextH1_36_52>
                            <Typography.TextBody_16_24>
                                # Heading level 1
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <Typography.TextH2_28_44>
                                Heading 2
                            </Typography.TextH2_28_44>
                            <Typography.TextBody_16_24>
                                ## Heading level 2
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <Typography.TextH3_24_34>
                                Heading 3
                            </Typography.TextH3_24_34>
                            <Typography.TextBody_16_24>
                                ### Heading level 3
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <Typography.TextH4_20_28>
                                Heading 4
                            </Typography.TextH4_20_28>
                            <Typography.TextBody_16_24>
                                ### Heading level 4
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <Typography.TextH5_18_26>
                                Heading 5
                            </Typography.TextH5_18_26>
                            <Typography.TextBody_16_24>
                                ##### Heading level 5
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <Typography.TextH6_14_20>
                                Heading 6
                            </Typography.TextH6_14_20>
                            <Typography.TextBody_16_24>
                                ###### Heading level 6
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <Typography.TextBody_16_24 fontWeight={'bold'}>
                                Bold
                            </Typography.TextBody_16_24>
                            <Typography.TextBody_16_24>
                                **bold** or __bold__
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <ItalicTypography>Italic</ItalicTypography>
                            <Typography.TextBody_16_24>
                                *italic* or _italic_
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <StrikethroughTypography>
                                strikethrough
                            </StrikethroughTypography>
                            <Typography.TextBody_16_24>
                                ~~strikethrough~~
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <MarkdownLink>[Link]</MarkdownLink>
                            <Typography.TextBody_16_24>
                                [Link](https://link-url-here.org)
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <Typography.TextBody_16_24>
                                x<sup>2</sup>
                            </Typography.TextBody_16_24>
                            <Typography.TextBody_16_24>
                                ^superscript^
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <Typography.TextBody_16_24>
                                H<sub>2</sub>O
                            </Typography.TextBody_16_24>
                            <Typography.TextBody_16_24>
                                ~subscript~
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <BlockquoteTypography>
                                <Typography.TextBody_16_24>
                                    This is a placeholder blockquote.
                                </Typography.TextBody_16_24>
                            </BlockquoteTypography>
                            <Typography.TextBody_16_24>
                                {'>'} blockquote text
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <UnorderedList>
                                <li>list item one</li>
                                <li>list item two</li>
                            </UnorderedList>
                            <Typography.TextBody_16_24>
                                - list item one
                                <br /> - list item two
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                        <MarkdownSyntaxListItem>
                            <OrderedList>
                                <li>List item one</li>
                                <li>List item two</li>
                            </OrderedList>
                            <Typography.TextBody_16_24>
                                1. Order list item one
                                <br /> 2. Order list item two
                            </Typography.TextBody_16_24>
                        </MarkdownSyntaxListItem>
                    </MarkdownSyntaxList>
                </MarkdownSyntaxModalContent>
            </MarkdownSyntaxModalInner>
        </MarkdownSyntaxModalWrapper>,

        document.getElementById('modal')!,
    );
};
