import * as yup from 'yup';

import { FORM_VALIDATION_MESSAGES } from './formValidationMessages';

import { notEmpty } from '@speeki/frontend-utils';

export const optionalSelect = yup
    .object({
        key: yup.string().optional(),
        label: yup.string().optional(),
        value: yup.string().optional(),
    })
    .nullable();

export const optionalSelectV2 = yup
    .object({
        label: yup.string().optional(),
        value: yup.string().optional(),
    })
    .notRequired()
    .nullable();

export const requiredSelect = yup
    .object({
        key: yup
            .string()
            .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
        label: yup
            .string()
            .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
        value: yup
            .string()
            .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
    })
    .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY)
    .nullable();

export const requiredSelectV2 = yup
    .object({
        label: yup
            .string()
            .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
        value: yup
            .string()
            .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
    })
    .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY)
    .nullable();

export const tagsArray = yup
    .array()
    .of(
        yup
            .object({
                id: yup.string(),
                value: yup
                    .string()
                    .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
            })
            .required(),
    )
    .test('unique', (value, context) => {
        const values = value?.map((item) => item?.value);

        const errors =
            value
                ?.map((element, index) => {
                    const isDuplicated =
                        values &&
                        values.filter((item) => item === element?.value)
                            .length > 1;

                    if (!isDuplicated || !element?.value) return null;

                    return new yup.ValidationError(
                        FORM_VALIDATION_MESSAGES.TAG_NAME_DUPLICATED,
                        element.value,
                        `${context.path}.${index}.value`,
                        'DUPLICATE',
                    );
                })
                .filter(notEmpty) ?? [];

        return errors?.length ? new yup.ValidationError(errors) : true;
    });
