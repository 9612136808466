import React, { useEffect, useState } from 'react';
import {
    Column,
    SortingRule,
    useAsyncDebounce,
    usePagination,
    useSortBy,
    useTable,
} from 'react-table';

import { SLOT_TYPE_LABEL } from '@speeki/dictionary';

import {
    AssistantSlotsDocument,
    AssistantSlotSortFields,
    AssistantSlotSortInput,
    Slot_Type,
} from '@graphql/generated/graphql';

import { toCapitalize } from '@utils/helpers';

import { useLazyQuery } from '@apollo/client';

import { LegacyTypeNone } from './SlotsTable.styled';

export interface SlotsTableRow {
    actionId: string;
    dataType?: string | null;
    isLegacy: string;
    legacyType: string;
    slotId: string;
    text: string;
}

type SortKeys = Exclude<keyof SlotsTableRow, 'dataType' | 'text' | 'isLegacy'>;

const parsedSortKeys: Record<SortKeys, AssistantSlotSortFields> = {
    actionId: 'ACTION_ID',
    legacyType: 'LEGACY_TYPE',
    slotId: 'SLOT_ID',
};

const getQuerySorting = (
    sort: SortingRule<SlotsTableRow>[],
): AssistantSlotSortInput[] =>
    sort.map((item) => ({
        direction: item.desc ? 'DESC' : 'ASC',
        field: parsedSortKeys[item.id as SortKeys],
    }));

// TODO: create reusable hook to handle table with pagination, similar to useQueryInfinitePagination from @speeki/utils (SPD-2939)
export const useSlotsTable = () => {
    const [getAssistantSlots, { data, loading }] = useLazyQuery(
        AssistantSlotsDocument,
        { fetchPolicy: 'cache-and-network' },
    );
    const [pageSizeSelect, setPageSizeSelect] = useState(10);
    const getAssistantSlotsDebounced = useAsyncDebounce(getAssistantSlots, 100);

    const slots = data?.list.edges.map((edge) => edge.node) || [];
    const totalCount = data?.list.totalCount;
    const pageCount = totalCount ? Math.ceil(totalCount / pageSizeSelect) : 0;

    const columns = React.useMemo<Column<SlotsTableRow>[]>(
        () => [
            { Header: 'Slot ID', accessor: 'slotId', width: 40 },
            {
                Header: 'Action ID',
                accessor: 'actionId',
                width: 40,
            },
            {
                Header: 'Text',
                accessor: 'text',
                disableSortBy: true,
                width: 450,
            },
            {
                Header: 'Legacy',
                accessor: 'isLegacy',
                disableSortBy: true,
                width: 100,
            },
            {
                Header: 'Data type',
                accessor: 'dataType',
                disableSortBy: true,
            },
            {
                Cell: ({ value }) =>
                    value === 'NONE' ? (
                        <LegacyTypeNone>{toCapitalize(value)}</LegacyTypeNone>
                    ) : (
                        <>{toCapitalize(SLOT_TYPE_LABEL[value as Slot_Type])}</>
                    ),
                Header: 'Legacy type',
                accessor: 'legacyType',
                width: 100,
            },
        ],
        [],
    );

    const table = useTable(
        {
            columns: columns,
            data: slots.map((slot) => ({
                ...slot,
                isLegacy: slot.isLegacy ? 'True' : 'False',
            })),
            disableSortRemove: true,
            initialState: {
                hiddenColumns: ['dataType'],
                pageSize: pageSizeSelect,
            },
            manualPagination: true,
            manualSortBy: true,
            pageCount,
        },
        useSortBy,
        usePagination,
    );

    const { pageIndex, pageSize, sortBy } = table.state;

    useEffect(() => {
        setPageSizeSelect(pageSize);
        void getAssistantSlotsDebounced({
            variables: {
                input: {
                    paging: {
                        limit: pageSize,
                        /** We need add 1 because pageIndex starts from 0 */
                        offset: pageIndex * pageSize + 1,
                    },
                    sorting: getQuerySorting(sortBy),
                },
            },
        });
    }, [pageIndex, pageSize, sortBy]);

    return {
        ...table,
        loading,
        totalCount,
    };
};
