import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TAG_NAME_DUPLICATE } from '@speeki/dictionary';
import { notify } from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import {
    GroupTagsRowsDocument,
    GroupTagUpsertDocument,
    TagsUpsertDocument,
} from '@graphql/generated/graphql';

import { handleBatchErrorV2 } from '@utils/handleBatchError';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { CUSTOM_HOOK_FORM_ERRORS } from '@shared/constants';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '@shared/notifyMessages';
import {
    optionalSelectV2,
    requiredSelectV2,
    tagsArray,
} from '@shared/validations';

interface SelectType {
    label: string;
    value: string;
}

export const useNewTagAddModal = (setOpen: UseModal['setOpen']) => {
    const [showTagsArray, setShowTagsArray] = useState(false);
    const [existingGroupTagSelected, setExistingGroupTagSelected] =
        useState(false);

    const methods = useForm<NewTagAddFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(NewTagAddSchema(showTagsArray)),
    });

    const {
        formState: { isSubmitted, isValid },
        getFieldState,
        getValues,
        handleSubmit: handleFormSubmit,
        setError,
        setValue,
    } = methods;

    const [tagUpsert, { loading: tagUpsertLoading }] = useMutation(
        TagsUpsertDocument,
        {
            context: {
                notifyOnError: true,
            },
            onError: () => {},
        },
    );
    const [groupTagUpsert, { loading: groupTagUpsertLoading }] = useMutation(
        GroupTagUpsertDocument,
        {
            context: {
                notifyOnError: true,
            },
            onError: () => {},
        },
    );

    const upsertLoading = tagUpsertLoading || groupTagUpsertLoading;

    const handleClose = () => setOpen(false);

    const handleSubmit = handleFormSubmit(
        ({ childTags, groupTagName, riskArea }) => {
            const isMulti = childTags && childTags.length > 1;

            existingGroupTagSelected
                ? void tagUpsert({
                      onCompleted: () => {
                          notify(SUCCESS_MESSAGES.TAGS_ADD(isMulti)).SUCCESS();
                          handleClose();
                      },
                      onError: (error) => {
                          handleBatchErrorV2({
                              errorCode: TAG_NAME_DUPLICATE,
                              onError: ({ data }) => {
                                  const values = getValues('childTags') || [];
                                  const addTagsErrors =
                                      getFieldState('childTags').error;

                                  const errorIndex = values.findIndex(
                                      (item) => item.value === data?.name,
                                  );

                                  if (errorIndex < 0) return;

                                  const newErrors: Record<
                                      number | string,
                                      unknown
                                  > = addTagsErrors ? { ...addTagsErrors } : {};

                                  newErrors[errorIndex] = {
                                      value: {
                                          message:
                                              ERROR_MESSAGES.TAG_NAME_ALREADY_EXISTS,
                                          type: CUSTOM_HOOK_FORM_ERRORS.NAME_ALREADY_EXISTS,
                                      },
                                  };
                                  setError('childTags', newErrors);
                              },
                          })(error);
                      },
                      refetchQueries: [GroupTagsRowsDocument],
                      variables: {
                          input: {
                              groupTagUuid: groupTagName?.value ?? '',
                              tagUpsertInput:
                                  childTags?.map((tag) => ({
                                      name: tag.value,
                                  })) || [],
                          },
                      },
                  })
                : void groupTagUpsert({
                      context: {
                          notifyOnError: true,
                      },
                      onCompleted: () => {
                          const isChildTagAdd =
                              existingGroupTagSelected ||
                              (!existingGroupTagSelected &&
                                  !!childTags?.length);

                          notify(
                              isChildTagAdd
                                  ? SUCCESS_MESSAGES.TAGS_ADD(isMulti)
                                  : SUCCESS_MESSAGES.GROUP_TAG_ADD,
                          ).SUCCESS();

                          handleClose();
                      },
                      refetchQueries: [GroupTagsRowsDocument],
                      variables: {
                          input: {
                              name: groupTagName?.label,
                              riskAreaUuid: riskArea.value,
                              tagUpsertInput: !!childTags?.length
                                  ? childTags.map((tag) => ({
                                        uuid: tag.id,
                                        name: tag.value,
                                    }))
                                  : undefined,
                          },
                      },
                  });
        },
    );

    useEffect(() => {
        setValue(
            'childTags',
            showTagsArray
                ? [
                      {
                          id: undefined,
                          value: '',
                      },
                  ]
                : [],
        );
    }, [showTagsArray]);

    return {
        existingGroupTagSelected,
        handleClose,
        handleSubmit,
        isSubmitted,
        isValid,
        methods,
        setExistingGroupTagSelected,
        setShowTagsArray,
        upsertLoading,
    };
};

const NewTagAddSchema = (showTagsArray?: boolean) =>
    yup.object({
        childTags: !showTagsArray ? tagsArray : tagsArray.required(),
        groupTagName: optionalSelectV2.when('riskArea', {
            is: (obj: SelectType) => {
                if (!obj) return;

                return obj.value;
            },
            then: requiredSelectV2,
        }),
        newTagSwitch: yup.boolean(),
        riskArea: requiredSelectV2,
    });

export type NewTagAddFormValues = yup.InferType<
    ReturnType<typeof NewTagAddSchema>
>;
