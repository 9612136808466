import styled from 'styled-components';

export const SearchTableWrapper = styled.div`
    tr {
        th:first-child {
            max-width: 100px;
        }
        td:last-child {
            padding-right: 0;
        }
    }
`;

export const SearchTableLastCell = styled.div`
    align-items: center;
    display: grid;
    gap: 24px;
    grid-auto-flow: column;
`;
