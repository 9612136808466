/* eslint-disable sort-keys-custom-order-fix/sort-keys-custom-order-fix */
import { Column } from 'react-table';

import { Switch } from '@speeki/global-ui-components';
import { IconButton } from '@speeki/icons';

import { SearchTableLastCell } from '@domains/clients/ions/Table.styled';

export interface SubsidiaryTableRow {
    id: string;
    isActive: boolean;
    name: string;
}

export const headerData = ({
    onEdit,
    onSwitch,
}: {
    onEdit: (row: SubsidiaryTableRow) => void;
    onSwitch: (row: SubsidiaryTableRow) => void;
}): Column<SubsidiaryTableRow>[] => [
    {
        accessor: 'name',
        disableSortBy: true,
        Header: 'Entity name',
    },
    {
        accessor: 'isActive',
        disableSortBy: true,
        Header: <></>,
        width: '1%',
        Cell: ({ row: { original } }) => {
            return (
                <SearchTableLastCell>
                    <IconButton
                        iconProps={{
                            icon: 'SvgEdit24',
                            size: 24,
                            customColors: {
                                default: {
                                    'color-1': '#0032F5',
                                },
                                hover: {
                                    'color-1': '#0022A8',
                                },
                            },
                        }}
                        onClick={() => onEdit(original)}
                        testId="INZ0s1f"
                    />
                    <Switch
                        checked={!!original.isActive}
                        onChange={() => onSwitch(original)}
                        testId="plE6gzq"
                    />
                </SearchTableLastCell>
            );
        },
    },
];
