import { useController, useForm, useWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { FRONTEND_COUNTRY_NAMES_ORDERED } from '@speeki/dictionary';
import {
    Button,
    InputCheck,
    PasswordField,
    SelectField,
    TextField,
    Typography,
} from '@speeki/global-ui-components';

import { DividedFieldRow } from '@components/ions/Form.styled';
import {
    MainPageContent,
    MainPagePanel,
    MainPageWrapper,
} from '@components/ions/Pages.styled';
import { MainLayout } from '@components/templates/MainLayout/MainLayout';

import {
    AddNewClientDocument,
    ClusterIds,
    TenantsCountry,
} from '@graphql/generated/graphql';

import { getCountriesFromDict } from '@utils/helpers';

import { useMutation } from '@apollo/client';
import { DetailedAction } from '@domains/clients/molecules/DetailedAction/DetailedAction';
import { CancelAddNewClientModal } from '@domains/clients/organisms/CancelAddNewClientModal/CancelAddNewClientModal';
import {
    NewClientButtonWrapper,
    NewClientTitleWrapper,
    SignUpInformation,
} from '@domains/clients/pages/AddNewClient/AddNewClient.styled';
import {
    InfoKey,
    InfoValue,
    PasswordWrapper,
    ValidationStepInfo,
} from '@domains/clients/pages/AddNewClient/AddNewClientPage/AddNewClientPage.styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_VALIDATION_MESSAGES } from '@shared/formValidationMessages';
import { passwordRequirements } from '@shared/passwordRequirements';
import { internalPaths } from '@shared/paths';
import { requiredSelectV2 } from '@shared/validations';

const dataCentreOptions = [
    { label: 'United States of America', value: 'US' },
    { label: 'Germany', value: 'EU' },
];

interface Location {
    companyName: string;
    email: string;
}

export const AddNewClientPage = () => {
    const location = useLocation();
    const state = location.state as Location;
    const navigate = useNavigate();

    const countries = getCountriesFromDict(FRONTEND_COUNTRY_NAMES_ORDERED);

    const [addNewClient, { loading }] = useMutation(AddNewClientDocument, {
        context: {
            globalUrl: true,
            notifyOnError: true,
        },
        onError: () => {},
    });

    const {
        control,
        formState: { dirtyFields, errors, isDirty, isSubmitted, isValid },
        handleSubmit,
        register,
    } = useForm<AddNewClientFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(AddNewClientSchema),
    });

    const shouldBlockRedirect = isDirty && !isSubmitted;

    const { field: countrySelect } = useController({
        control,
        name: 'country',
    });

    const { field: dataCentreSelect } = useController({
        control,
        name: 'dataCentre',
    });

    const isPasswordCheckOpened =
        dirtyFields.password || (!isValid && isSubmitted);
    const password = useWatch({ control, name: 'password' });

    return (
        <MainLayout>
            <MainPageWrapper>
                <MainPagePanel>
                    <NewClientTitleWrapper>
                        <Typography.TextH1_36_52>
                            Add new client
                        </Typography.TextH1_36_52>
                        <SignUpInformation>
                            2 of 2 | Activate client’s account
                        </SignUpInformation>
                    </NewClientTitleWrapper>
                    <NewClientButtonWrapper>
                        <Button
                            disabled={loading}
                            onClick={() =>
                                navigate(internalPaths.validationClient, {
                                    state: {
                                        companyName: state.companyName,
                                        email: state.email,
                                    },
                                })
                            }
                            testId="t0WrImE"
                            variant={'secondary'}
                        >
                            Previous
                        </Button>
                        <Button
                            disabled={loading}
                            isLoading={loading}
                            onClick={handleSubmit((data) => {
                                void addNewClient({
                                    onCompleted: () => {
                                        navigate(internalPaths.clients);
                                    },
                                    variables: {
                                        input: {
                                            clusterRegion: data.dataCentre
                                                .value as ClusterIds,
                                            companyName: state.companyName,
                                            country: data.country
                                                .value as TenantsCountry,
                                            email: state.email,
                                            firstName: data.firstName,
                                            lastName: data.lastName,
                                            password: data.password,
                                        },
                                    },
                                });
                            })}
                            testId="AAJQQLv"
                            variant={'primary'}
                        >
                            Add new client
                        </Button>
                    </NewClientButtonWrapper>
                </MainPagePanel>
                <MainPageContent>
                    <DetailedAction
                        additionalInfoComponent={
                            <ValidationStepInfo>
                                <div>
                                    <InfoKey>Email address</InfoKey>
                                    <InfoValue>{state.email}</InfoValue>
                                </div>
                                <div>
                                    <InfoKey>Company name</InfoKey>
                                    <InfoValue>{state.companyName}</InfoValue>
                                </div>
                            </ValidationStepInfo>
                        }
                        description={
                            "Enter client’s first name, last name, and a secure password. Select the country, and upon clicking 'Add New Client,' an activation link will be sent to your email. "
                        }
                        title={'Client’s details'}
                    >
                        <DividedFieldRow>
                            <TextField
                                {...register('firstName')}
                                error={!!errors.firstName}
                                label={'First name'}
                                message={errors.firstName?.message}
                                placeholder={'Enter client’s first name'}
                                testId="lHmGneR"
                            />

                            <TextField
                                {...register('lastName')}
                                error={!!errors.lastName}
                                label={'Last name'}
                                message={errors.lastName?.message}
                                placeholder={'Enter client’s last name'}
                                testId="GiQ-j70"
                            />
                        </DividedFieldRow>
                        <DividedFieldRow>
                            <PasswordWrapper>
                                <PasswordField
                                    {...register('password')}
                                    error={!!errors.password}
                                    label={'Password'}
                                    message={errors.password?.message}
                                    placeholder={'Enter password'}
                                    testId="KGLdu6a"
                                />

                                <InputCheck
                                    isDirty={isPasswordCheckOpened}
                                    isSubmitted={isSubmitted}
                                    passwordValue={password}
                                    requirements={passwordRequirements}
                                    testId="ncFzRlB"
                                />
                            </PasswordWrapper>

                            <SelectField
                                {...countrySelect}
                                error={!!errors.country}
                                id={'country'}
                                isClearable={false}
                                label={'Country of residence'}
                                message={errors.country?.value?.message}
                                options={countries}
                                placeholder={'Select country'}
                                testId="e9rQr71"
                            />
                        </DividedFieldRow>
                        <DividedFieldRow>
                            <SelectField
                                {...dataCentreSelect}
                                error={!!errors.dataCentre}
                                id={'country'}
                                isClearable={false}
                                label={'Data centre'}
                                message={errors.dataCentre?.value?.message}
                                options={dataCentreOptions}
                                placeholder={'Select data centre'}
                                testId="I6LnMGf"
                            />
                        </DividedFieldRow>
                    </DetailedAction>
                </MainPageContent>
            </MainPageWrapper>

            <CancelAddNewClientModal when={shouldBlockRedirect} />
        </MainLayout>
    );
};

const AddNewClientSchema = yup.object({
    country: requiredSelectV2,
    dataCentre: requiredSelectV2,
    firstName: yup
        .string()
        .required(FORM_VALIDATION_MESSAGES.FIELD_REQUIRED)
        .min(
            3,
            FORM_VALIDATION_MESSAGES.FIELD_TOO_SHORT({
                fieldName: 'First name',
                limitCharacter: 3,
            }),
        )
        .max(
            64,
            FORM_VALIDATION_MESSAGES.FIELD_TOO_LONG({
                fieldName: 'First name',
                limitCharacter: 64,
            }),
        ),
    lastName: yup
        .string()
        .required(FORM_VALIDATION_MESSAGES.FIELD_REQUIRED)
        .min(
            3,
            FORM_VALIDATION_MESSAGES.FIELD_TOO_SHORT({
                fieldName: 'Last name',
                limitCharacter: 3,
            }),
        )
        .max(
            64,
            FORM_VALIDATION_MESSAGES.FIELD_TOO_LONG({
                fieldName: 'Last name',
                limitCharacter: 64,
            }),
        ),
    password: yup.string().required(''),
});

type AddNewClientFormValues = yup.InferType<typeof AddNewClientSchema>;
