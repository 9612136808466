import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const BoxWrapper = styled.div(
    ({ theme: { boxShadow, colors, radius } }) => css`
        background: ${colors.white};
        border-radius: ${radius[8]}px;
        box-shadow: ${boxShadow.small};
        display: flex;
        flex-direction: column;
        width: 684px;
        padding: 64px 80px 72px;
    `,
);

export const BoxTitle = styled(Typography.TextH1_36_52)`
    margin-bottom: 32px;
`;
