import {
    EmptyStateDescriptionWrapper,
    EmptyStateTitleWrapper,
} from '@components/molecules/commonStyles.styled';

import { EmptyStateWrapper } from './GuidelineTableEmptyState.styled';

interface GuidelineTableEmptyStateProps {
    search?: string;
}

export const GuidelineTableEmptyState = ({
    search,
}: GuidelineTableEmptyStateProps) => {
    if (search)
        return (
            <EmptyStateWrapper>
                <EmptyStateTitleWrapper>
                    There are no items for you to see.
                </EmptyStateTitleWrapper>
                <EmptyStateDescriptionWrapper>
                    Please adjust your search filter if you believe you should
                    see one.
                </EmptyStateDescriptionWrapper>
            </EmptyStateWrapper>
        );

    return (
        <EmptyStateWrapper>
            <EmptyStateTitleWrapper>
                There are no terms for you to see.
            </EmptyStateTitleWrapper>
            <EmptyStateDescriptionWrapper>
                Please add one using the button in the top right.
            </EmptyStateDescriptionWrapper>
        </EmptyStateWrapper>
    );
};
