import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

const NewClientWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const NewClientTitleWrapper = styled(NewClientWrapper)`
    gap: 40px;
`;

export const NewClientButtonWrapper = styled(NewClientWrapper)`
    gap: 16px;
`;

export const SignUpInformation = styled(Typography.TextBody_18_30)(
    ({ theme: { palette } }) => css`
        color: ${palette.text.assistive};
        line-height: 25px;
    `,
);
