import { Skeleton } from '@speeki/global-ui-components';

import { MainLayoutSkeletonStyles } from './MainLayout.styled';

export const MainLayoutSkeleton = () => {
    return (
        <MainLayoutSkeletonStyles.Wrapper>
            <MainLayoutSkeletonStyles.Sidebar>
                <Skeleton height={30} />
                <Skeleton height={150} />
            </MainLayoutSkeletonStyles.Sidebar>
            <MainLayoutSkeletonStyles.Header>
                <Skeleton height={30} variant={'inverted'} width={150} />
            </MainLayoutSkeletonStyles.Header>
            <MainLayoutSkeletonStyles.Content>
                <MainLayoutSkeletonStyles.ContentHeader>
                    <Skeleton height={50} variant={'inverted'} width={150} />
                    <Skeleton height={50} variant={'inverted'} width={150} />
                </MainLayoutSkeletonStyles.ContentHeader>
                <MainLayoutSkeletonStyles.ContentSection>
                    <Skeleton height={50} variant={'inverted'} width={450} />
                    <Skeleton height={400} variant={'inverted'} />
                </MainLayoutSkeletonStyles.ContentSection>
            </MainLayoutSkeletonStyles.Content>
        </MainLayoutSkeletonStyles.Wrapper>
    );
};
