import { useGroupTagEditModal } from './useGroupTagEditModal';

import {
    Button,
    DeleteButton,
    Modal,
    ModalComponent,
    PaginatedSelectField,
    TextField,
} from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import { DividedFieldRow, FormWrapper } from '@components/ions/Form.styled';

import { DeleteButtonWrapper } from '@domains/clients/ions/Modal.styled';

interface GroupTagEditModalProps extends UseModal {
    openWarningModal: () => void;
}

export const GroupTagEditModal = ({
    open,
    openWarningModal,
    setOpen,
}: GroupTagEditModalProps) => {
    const {
        errors,
        handleClose,
        handleSubmit,
        isDirty,
        isValid,
        register,
        riskAreaField,
        riskAreasSelect,
        upsertLoading,
    } = useGroupTagEditModal(setOpen);

    return (
        <ModalComponent
            onClose={handleClose}
            open={open}
            preventCloseEsc
            preventCloseOutside
            width={985}
        >
            <Modal.Content>
                <Modal.Header>
                    <Modal.Title>Edit group tag</Modal.Title>
                    <Modal.SubTitle color="black">
                        Edit group tag that can be associated with your data.
                    </Modal.SubTitle>
                </Modal.Header>
                <FormWrapper>
                    <DividedFieldRow>
                        <PaginatedSelectField
                            {...riskAreaField}
                            {...riskAreasSelect}
                            error={!!errors.riskArea}
                            isDisabled
                            label={'Topic name'}
                            message={errors.riskArea?.message}
                            placeholder={'Select topic'}
                            testId="W3vpo63"
                        />
                        <TextField
                            {...register('groupTagName')}
                            error={!!errors.groupTagName}
                            label={'Group tag name'}
                            maxLength={75}
                            message={errors.groupTagName?.message}
                            placeholder={'Select or enter group tag name'}
                            testId="Ir8Mip5"
                        />
                    </DividedFieldRow>
                </FormWrapper>
            </Modal.Content>
            <Modal.Footer>
                <DeleteButtonWrapper>
                    <DeleteButton onClick={openWarningModal} testId="ZXWAeiO">
                        Delete group tag
                    </DeleteButton>
                </DeleteButtonWrapper>
                <Modal.ButtonsWrapper>
                    <Button
                        disabled={upsertLoading}
                        onClick={handleClose}
                        size={'small'}
                        testId="g_QvBia"
                        variant={'secondary'}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!isValid || !isDirty}
                        isLoading={upsertLoading}
                        onClick={handleSubmit}
                        size={'small'}
                        testId="7rmzQCW"
                        variant={'primary'}
                    >
                        Save
                    </Button>
                </Modal.ButtonsWrapper>
            </Modal.Footer>
        </ModalComponent>
    );
};
