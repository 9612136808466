import { debounce } from 'lodash-es';
import { useMemo, useState } from 'react';

import { useModal } from '@speeki/react-hooks';

import { TagsFields } from '@components/organisms/TagsTable/TagsTable';
import {
    getHeaderData,
    GroupTagTableRow,
} from '@components/organisms/TagsTable/TagsTable.settings';

import {
    GroupTagsNameSearchDocument,
    GroupTagsRowsDocument,
    RiskAreasNameSearchDocument,
    TagsNameSearchDocument,
} from '@graphql/generated/graphql';

import { useLazyQuery, useQuery } from '@apollo/client';
import { editedGroupTagIdVar, editedTagIdVar } from '@boot/reactiveVariables';

export interface TagFilters extends Record<string, string[]> {
    groupTagName: string[];
    riskArea: string[];
    tags: string[];
}

const DEFAULT_TAG_FILTERS = {
    groupTagName: [],
    riskArea: [],
    tags: [],
};

const SHARED_RISK_AREA_NAME = 'SHARED';

export const useTagsTable = () => {
    const [filters, setFilters] = useState<TagFilters>(DEFAULT_TAG_FILTERS);
    const [queryOptionsFilters, setQueryOptionsFilters] =
        useState<TagFilters>(DEFAULT_TAG_FILTERS);

    const [filterSearchPhrase, setFilterSearchPhrase] = useState('');

    const [tableSearchPhrase, setTableSearchPhrase] = useState('');
    const [tableSearchValue, setTableSearchValue] = useState('');

    const [selectedCategory, setSelectedCategory] = useState<
        keyof TagFilters | undefined
    >();

    const newTagAddModal = useModal();

    const groupTagEditModal = useModal();
    const groupTagDeleteModal = useModal();

    const tagEditModal = useModal();
    const tagDeleteModal = useModal();

    const headerData = getHeaderData({
        onGroupTagEdit: () => groupTagEditModal.setOpen(true),
        onTagEdit: () => tagEditModal.setOpen(true),
        setGroupTagEditedId: (id: string) => editedGroupTagIdVar(id),
        setTagEditedId: (id: string) => editedTagIdVar(id),
    });

    const debouncedSetFiltersSearchPhrase = debounce((value: string) => {
        setFilterSearchPhrase(value);
    }, 200);

    const [
        getGroupTagsData,
        { called, data: groupTagsRowsData, loading: groupTagsRowsLoading },
    ] = useLazyQuery(GroupTagsRowsDocument, {
        fetchPolicy: 'cache-and-network',
    });

    const { data: riskAreasData, loading: riskAreasLoading } = useQuery(
        RiskAreasNameSearchDocument,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                input: {
                    includeTagNames: queryOptionsFilters.tags.length
                        ? queryOptionsFilters.tags
                        : undefined,
                    includesGroupTagNames: queryOptionsFilters.groupTagName
                        .length
                        ? queryOptionsFilters.groupTagName
                        : undefined,
                    nameSearch:
                        selectedCategory === 'riskArea'
                            ? filterSearchPhrase
                            : undefined,
                    types: null,
                },
            },
        },
    );

    const { data: groupTagsData, loading: groupTagsLoading } = useQuery(
        GroupTagsNameSearchDocument,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                input: {
                    includeTagNames: queryOptionsFilters.tags.length
                        ? queryOptionsFilters.tags
                        : undefined,
                    nameSearch:
                        selectedCategory === 'groupTagName'
                            ? filterSearchPhrase
                            : undefined,
                    riskAreasUuids: queryOptionsFilters.riskArea.length
                        ? queryOptionsFilters.riskArea
                        : undefined,
                },
            },
        },
    );

    const { data: tagsData, loading: tagsLoading } = useQuery(
        TagsNameSearchDocument,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                input: {
                    groupTagNames: queryOptionsFilters.groupTagName.length
                        ? queryOptionsFilters.groupTagName
                        : undefined,
                    nameSearch:
                        selectedCategory === 'tags'
                            ? filterSearchPhrase
                            : undefined,
                    riskAreasUuids: queryOptionsFilters.riskArea.length
                        ? queryOptionsFilters.riskArea
                        : undefined,
                },
            },
        },
    );

    const sharedRiskArea = riskAreasData?.riskAreas.find(
        (area) => area.name === SHARED_RISK_AREA_NAME,
    );

    const transformedData: GroupTagTableRow[] =
        useMemo(
            () =>
                groupTagsRowsData?.groupTagsRows.nodes.map((item) => {
                    return {
                        id: item.groupTag.uuid,
                        groupTag: {
                            id: item.groupTag.uuid,
                            colorPrimary: item.groupTag.colorPrimary,
                            colorSecondary: item.groupTag.colorSecondary,
                            isPublic: item.groupTag.isPublic,
                            name: item.groupTag.name,
                        },
                        riskArea: item.groupTag.riskArea?.label,
                        tag: {
                            id: item.tag?.uuid || '',
                            color: item.tag?.color || '',
                            isPublic: item.tag?.isPublic || false,
                            name: item.tag?.name || '',
                        },
                    };
                }),
            [groupTagsRowsData],
        ) || [];

    const filtersData: TagsFields[] = useMemo(
        () => [
            {
                label: 'Topic',
                name: 'riskArea',
                options:
                    [sharedRiskArea]
                        .concat(
                            riskAreasData?.riskAreas.filter(
                                (item) => item.name !== SHARED_RISK_AREA_NAME,
                            ),
                        )
                        .filter((item) => item)
                        .map((item) => ({
                            key: item?.uuid || '',
                            label: item?.label || '',
                        })) || [],
                showCategoryOptionsNumber: true,
            },
            {
                label: 'Group tag name',
                name: 'groupTagName',
                options:
                    groupTagsData?.groupTags.map((item) => ({
                        key: item || '',
                        label: item || '',
                    })) || [],
                showCategoryOptionsNumber: true,
            },
            {
                label: 'Tag(s)',
                name: 'tags',
                options:
                    tagsData?.tags.map((item) => ({
                        key: item || '',
                        label: item || '',
                    })) || [],
                showCategoryOptionsNumber: true,
            },
        ],
        [riskAreasData, groupTagsData, tagsData],
    );

    return {
        called,
        debouncedSetFiltersSearchPhrase,
        filters,
        filtersData,
        filtersLoading: riskAreasLoading || groupTagsLoading || tagsLoading,
        getGroupTagsData,
        groupTagDeleteModal,
        groupTagEditModal,
        groupTagsRowsData,
        groupTagsRowsLoading,
        headerData,
        newTagAddModal,
        selectedCategory,
        setFilterSearchPhrase,
        setFilters,
        setQueryOptionsFilters,
        setSelectedCategory,
        setTableSearchPhrase,
        setTableSearchValue,
        tableSearchPhrase,
        tableSearchValue,
        tagDeleteModal,
        tagEditModal,
        transformedData,
    };
};
