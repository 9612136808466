import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { notify } from '@speeki/global-ui-components';

import {
    AdminUsersDocument,
    AdminUserUpsertDocument,
} from '@graphql/generated/graphql';

import { passwordValid } from '@utils/helpers';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_VALIDATION_MESSAGES } from '@shared/formValidationMessages';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';

interface UseAdminUsersUpsertModalArgs {
    closeModal: () => void;
    values?: AdminUsersUpsertFormValues;
}

export const useAdminUsersUpsertModal = ({
    closeModal,
    values,
}: UseAdminUsersUpsertModalArgs) => {
    const [showPasswordFields, setShowPasswordFields] = useState(false);

    const [adminUserUpsert, { loading }] = useMutation(
        AdminUserUpsertDocument,
        {
            context: {
                notifyOnError: true,
            },
            onCompleted: () => {
                notify(
                    SUCCESS_MESSAGES[
                        values ? 'ADMIN_USER_EDIT' : 'ADMIN_USER_ADD'
                    ],
                ).SUCCESS();
                closeModal();
            },
            onError: () => {},
            refetchQueries: !!values ? undefined : [AdminUsersDocument],
        },
    );

    const {
        clearErrors,
        control,
        formState: { dirtyFields, errors, isDirty, isSubmitted, isValid },
        handleSubmit: handleFormSubmit,
        register,
        setValue,
    } = useForm<AdminUsersUpsertFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(
            AdminUsersUpsertFormSchema(showPasswordFields || !values),
        ),
        values,
    });

    const passwordFieldValue =
        useWatch({
            control,
            name: 'password',
        }) ?? '';

    const handleSubmit = handleFormSubmit(
        ({ email, firstName, lastName, password, status, uuid }) => {
            const getStatus = () => {
                if (status === undefined || !!status) return 'ACTIVE';
                return 'INACTIVE';
            };

            void adminUserUpsert({
                variables: {
                    input: {
                        uuid,
                        email,
                        firstName,
                        lastName,
                        password,
                        status: getStatus(),
                    },
                },
            });
        },
    );

    const handleDeletePasswordFields = () => {
        setShowPasswordFields(false);
        setValue('password', undefined);
        setValue('passwordConfirm', undefined);
        clearErrors(['password', 'passwordConfirm']);
    };

    return {
        dirtyFields,
        errors,
        handleDeletePasswordFields,
        handleSubmit,
        isDirty,
        isSubmitted,
        isValid,
        loading,
        passwordFieldValue,
        register,
        setShowPasswordFields,
        setValue,
        showPasswordFields,
    };
};

const AdminUsersUpsertFormSchema = (showPasswordFields: boolean) => {
    const passwordValidation = yup.string();
    const passwordConfirmValidation = yup
        .string()
        .oneOf(
            [yup.ref('password')],
            FORM_VALIDATION_MESSAGES.PASSWORD_NOT_MATCH,
        );

    return yup.object({
        uuid: yup.string().optional(),
        email: yup
            .string()
            .email(FORM_VALIDATION_MESSAGES.INVALID_EMAIL)
            .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
        firstName: yup
            .string()
            .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
        lastName: yup
            .string()
            .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY),
        password: showPasswordFields
            ? passwordValidation
                  .required(FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY)
                  .test('passwordValidation', '', passwordValid)
            : passwordValidation.optional(),
        passwordConfirm: showPasswordFields
            ? passwordConfirmValidation.required(
                  FORM_VALIDATION_MESSAGES.FIELD_CANNOT_BE_EMPTY,
              )
            : passwordConfirmValidation.optional(),
        status: yup.boolean(),
    });
};

export type AdminUsersUpsertFormValues = yup.InferType<
    ReturnType<typeof AdminUsersUpsertFormSchema>
>;
