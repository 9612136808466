import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    EsgProfileTopicTableRow,
    headerData,
} from './EsgProfileTopicPage.settings';

import {
    ESG_QUESTION_ACTIVITY,
    ESG_STEP,
    ESG_SUBCATEGORY,
} from '@speeki/dictionary';
import { SecondaryTable, TextButton } from '@speeki/global-ui-components';

import {
    Esg_Subcategory,
    EsgProfileTopicPageDocument,
} from '@graphql/generated/graphql';

import { toKebabCase, toSnakeUpperCase } from '@utils/helpers';

import { useQuery } from '@apollo/client';
import { EsgProfileSearchForm } from '@domains/settings/organisms/EsgProfileSearchForm/EsgProfileSearchForm';
import { SettingsLayout } from '@domains/settings/templates/SettingsLayout/SettingsLayout';
import { internalPaths } from '@shared/paths';

import {
    BackButtonWrapper,
    Description,
    HeaderWrapper,
    ProfileCard,
    SearchWrapper,
    Title,
} from './EsgProfileTopicPage.styled';

export const EsgProfileTopicPage = () => {
    const [searchPhrase, setSearchPhrase] = useState('');

    const navigate = useNavigate();

    const { topic } = useParams<{ topic: string }>();
    const topicKey = toSnakeUpperCase(topic as string) as Esg_Subcategory;

    const { called, data, loading } = useQuery(EsgProfileTopicPageDocument, {
        variables: {
            riskAreaName: topicKey,
        },
    });
    const topicData = data?.topic;

    const topicLabel = useMemo(() => {
        if (!topicData) return null;

        return topicData.customLabel || ESG_SUBCATEGORY[topicData.name];
    }, [topicData, topic]);

    const transformedData: EsgProfileTopicTableRow[] =
        useMemo(
            () =>
                topicData?.steps.flatMap(({ name, questions }) =>
                    questions.map(({ activity, uuid }) => ({
                        id: uuid,
                        activity: ESG_QUESTION_ACTIVITY[activity],
                        stage: ESG_STEP[name],
                    })),
                ),
            [topicData, topic],
        ) || [];

    return (
        <SettingsLayout>
            <ProfileCard>
                <BackButtonWrapper>
                    <TextButton
                        iconProps={{
                            icon: 'SvgArrowLeft24',
                            size: 24,
                            themeColors: {
                                default: {
                                    'color-1': 'tertiary.default',
                                },
                            },
                        }}
                        onClick={() => navigate(internalPaths.esgProfile)}
                        testId="KloHSTx"
                        type={'button'}
                    >
                        Back to ESG profile
                    </TextButton>
                </BackButtonWrapper>

                <HeaderWrapper>
                    <Title>{topicLabel}</Title>
                    <Description>
                        Edit the content of specific activity in this topic.
                    </Description>
                </HeaderWrapper>
                <SearchWrapper>
                    <EsgProfileSearchForm onSearch={setSearchPhrase} />
                </SearchWrapper>
                <SecondaryTable
                    columns={headerData}
                    data={transformedData}
                    pagination={{
                        options: {
                            pageSelect: true,
                            totalCount: transformedData.length,
                        },
                    }}
                    tableHeaderRow={{ testId: 'ybv6Lu_' }}
                    tableRow={{
                        onRowClick: (data) =>
                            navigate(
                                internalPaths.esgActivityEdit(
                                    topic as string,
                                    toKebabCase(data.id),
                                ),
                            ),
                        showRightArrow: true,
                        testId: 'ILr8F4V',
                    }}
                    tableState={{
                        loading: loading || !called,
                        searchPhrase,
                    }}
                    testId="gdTTk_s"
                />
            </ProfileCard>
        </SettingsLayout>
    );
};
