import { Column } from 'react-table';

import { Table } from '@speeki/global-ui-components';

import { Tenant_Status } from '@graphql/generated/graphql';

export interface ClientTableRow {
    companyName: string;
    country: string;
    id: string;
    licenses: number;
    plan: string;
    subscriptionEnd: string;
    subscriptionStatus: Tenant_Status;
}

export const headerData: Column<ClientTableRow>[] = [
    { Header: 'Company name', accessor: 'companyName' },
    {
        Cell: (data) => {
            switch (data.value) {
                case 'ACTIVE':
                    return (
                        <Table.Status status={'active'}>Active</Table.Status>
                    );
                case 'AWAIT_ACTIVATION':
                    return (
                        <Table.Status status={'awaiting'}>
                            Await activation
                        </Table.Status>
                    );
                default:
                    return (
                        <Table.Status status={'inactive'}>
                            Inactive
                        </Table.Status>
                    );
            }
        },
        Header: 'Subscription status',
        accessor: 'subscriptionStatus',
    },
    {
        Header: 'Subscription end date',
        accessor: 'subscriptionEnd',
    },
    {
        Header: 'Country',
        accessor: 'country',
    },
    {
        Header: 'Plan',
        accessor: 'plan',
    },
    {
        Header: 'Licenses',
        accessor: 'licenses',
    },
];
