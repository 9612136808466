import { useController } from 'react-hook-form';
import { mergeRefs } from 'react-merge-refs';

import { useReleaseNotePage } from './useReleaseNotePage';

import {
    Button,
    InputMessage,
    Label,
    NumberField,
    SelectField,
    Switch,
    Typography,
} from '@speeki/global-ui-components';
import { IconButton, IconManager } from '@speeki/icons';
import { WysiwygEditor } from '@speeki/wysiwyg-editor';

import { DividedFieldRow, FormWrapper } from '@components/ions/Form.styled';
import {
    MainPageContent,
    MainPageName,
    MainPagePanel,
    MainPageWrapper,
} from '@components/ions/Pages.styled';
import { TitleInput } from '@components/molecules/TitleInput/TitleInput';
import { MainLayout } from '@components/templates/MainLayout/MainLayout';

import { Card } from '@domains/clients/ions/Card.styled';

import {
    AddTitleText,
    ButtonsWrapper,
    ContentWrapper,
    EditButtonWrapper,
    PageNameWrapper,
    ReleaseInfoWrapper,
    SwitchWrapper,
    TitleInputWrapper,
    WysiwygWrapper,
} from './ReleaseNotePage.styled';

export const ReleaseNotePage = () => {
    const {
        changeStatus,
        control,
        errors,
        isDirty,
        isEdited,
        isPublished,
        isValid,
        onReset,
        onSubmit,
        register,
        releaseOptions,
        setIsEdited,
        titleRef,
        updateLoading,
        wysiwygLoading,
    } = useReleaseNotePage();

    const { field: typeField } = useController({
        control,
        name: 'type',
    });

    const { field: wysiwygField } = useController({
        control,
        defaultValue: '<p><br></p>',
        name: 'wysiwyg',
    });

    const {
        field: { ref, ...titleField },
    } = useController({
        control,
        defaultValue: '',
        name: 'title',
    });

    return (
        <MainLayout>
            <MainPageWrapper>
                <MainPagePanel>
                    <PageNameWrapper>
                        <MainPageName>
                            Release note:{' '}
                            {!isEdited &&
                                (titleField.value ? (
                                    titleField.value
                                ) : (
                                    <AddTitleText
                                        onClick={() => setIsEdited(true)}
                                    >
                                        Add title
                                    </AddTitleText>
                                ))}
                        </MainPageName>
                        {!!titleField.value && !isEdited && (
                            <EditButtonWrapper>
                                <IconButton
                                    disabled={isPublished}
                                    iconProps={{
                                        icon: 'SvgEdit24',
                                        size: 24,
                                        themeColors: {
                                            default: {
                                                'color-1': 'button.tertiary',
                                            },
                                            disabled: {
                                                'color-1':
                                                    'button.tertiaryDisabled',
                                            },
                                            hover: {
                                                'color-1':
                                                    'button.tertiaryHover',
                                            },
                                        },
                                    }}
                                    onClick={() => setIsEdited(true)}
                                    testId="CumSfI_"
                                />
                            </EditButtonWrapper>
                        )}
                        {isEdited && (
                            <TitleInputWrapper>
                                <TitleInput
                                    {...titleField}
                                    onCancel={() => setIsEdited(false)}
                                    onChange={(value) => {
                                        titleField.onChange(value);
                                        setIsEdited(false);
                                    }}
                                    ref={mergeRefs([titleRef, ref])}
                                    testId="DWzLcP7"
                                />
                            </TitleInputWrapper>
                        )}
                    </PageNameWrapper>
                    <SwitchWrapper>
                        <Switch
                            checked={isPublished}
                            disabled={isDirty || !isValid || isEdited}
                            onChange={changeStatus}
                            testId="o2Lalh0"
                            textPosition="left"
                        >
                            Set to Published
                        </Switch>
                    </SwitchWrapper>
                </MainPagePanel>
                <MainPageContent>
                    <Card.Wrapper>
                        <Card.InnerWrapper padding={'48px'}>
                            <Card.CardData>
                                <FormWrapper
                                    id="release-notes-form"
                                    onReset={onReset}
                                    onSubmit={onSubmit}
                                >
                                    <ContentWrapper>
                                        {isPublished && (
                                            <DividedFieldRow>
                                                <ReleaseInfoWrapper>
                                                    <IconManager
                                                        icon="SvgInfo24"
                                                        size={24}
                                                    />
                                                    <Typography.TextBody_14_20>
                                                        Unpublish the release
                                                        note to edit.
                                                    </Typography.TextBody_14_20>
                                                </ReleaseInfoWrapper>
                                            </DividedFieldRow>
                                        )}
                                        <div>
                                            <DividedFieldRow>
                                                <SelectField
                                                    {...typeField}
                                                    error={!!errors.type}
                                                    id={'type'}
                                                    isClearable={false}
                                                    isDisabled={isPublished}
                                                    isMulti
                                                    label={'Type'}
                                                    message={
                                                        errors.type?.message
                                                    }
                                                    options={releaseOptions}
                                                    placeholder={'Select type'}
                                                    testId="hAUCSgw"
                                                />
                                                <NumberField
                                                    {...register('version')}
                                                    customRegExp={/^[\.0-9]*$/}
                                                    disabled={isPublished}
                                                    label={'Version (optional)'}
                                                    placeholder={
                                                        'Enter release version'
                                                    }
                                                    testId="KLMBlxk"
                                                />
                                            </DividedFieldRow>
                                            <WysiwygWrapper>
                                                <Label
                                                    disabled={isPublished}
                                                    testId="hPG-s5V"
                                                >
                                                    Description
                                                </Label>
                                                <WysiwygEditor
                                                    {...wysiwygField}
                                                    disabled={
                                                        !wysiwygLoading &&
                                                        isPublished
                                                    }
                                                    error={!!errors.wysiwyg}
                                                    testId="qZiCBQa"
                                                />
                                                {errors.wysiwyg?.message && (
                                                    <InputMessage
                                                        iconProps={{
                                                            icon: 'SvgAlert24',
                                                            size: 16,
                                                        }}
                                                        testId="P6kdw5I"
                                                        variant={'error'}
                                                    >
                                                        {errors.wysiwyg.message}
                                                    </InputMessage>
                                                )}
                                            </WysiwygWrapper>
                                        </div>
                                    </ContentWrapper>
                                </FormWrapper>
                            </Card.CardData>
                        </Card.InnerWrapper>
                        {isDirty && (
                            <Card.Footer padding={'24px 48px'}>
                                <ButtonsWrapper>
                                    <Button
                                        disabled={updateLoading}
                                        form="release-notes-form"
                                        size="small"
                                        testId="DnFbSXE"
                                        type="reset"
                                        variant="secondary"
                                    >
                                        cancel
                                    </Button>
                                    <Button
                                        disabled={!isValid}
                                        form="release-notes-form"
                                        isLoading={updateLoading}
                                        size="small"
                                        testId="qKZFRen"
                                        type="submit"
                                        variant="primary"
                                    >
                                        save
                                    </Button>
                                </ButtonsWrapper>
                            </Card.Footer>
                        )}
                    </Card.Wrapper>
                </MainPageContent>
            </MainPageWrapper>
        </MainLayout>
    );
};
