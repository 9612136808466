import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_VALIDATION_MESSAGES } from '@shared/formValidationMessages';

interface UseMarkdownFormArgs {
    defaultValue?: string;
    onSubmit: (question: string) => void;
}

export const useMarkdownForm = ({
    defaultValue,
    onSubmit,
}: UseMarkdownFormArgs) => {
    const [markdown, setMarkdown] = useState(defaultValue || '');

    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm<MarkdownFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(MarkdownFormSchema),
        values: {
            description: defaultValue || '',
        },
    });

    const handleFormSubmit = handleSubmit((data) => {
        onSubmit(data.description);
    });

    useEffect(() => {
        if (!defaultValue) return;

        setMarkdown(defaultValue);
    }, [defaultValue]);

    return {
        errors,
        handleFormSubmit,
        markdown,
        register,
        setMarkdown,
    };
};

const MarkdownFormSchema = yup.object({
    description: yup.string().required(FORM_VALIDATION_MESSAGES.FIELD_REQUIRED),
});

type MarkdownFormValues = yup.InferType<typeof MarkdownFormSchema>;
