import { __DEV__ } from '@speeki/frontend-utils';

import { cache } from '@graphql/cache';
import { links } from '@graphql/links';
import { localTypeDefs } from '@graphql/localTypeDefs';

import { ApolloClient } from '@apollo/client';

export const client = new ApolloClient({
    cache,
    connectToDevTools: __DEV__,
    link: links,
    name: 'speeki-feature-manager',
    typeDefs: localTypeDefs,
});
