import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const PasswordWrapper = styled.div`
    display: grid;
    gap: 4px;
`;

export const ValidationStepInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;
    padding-left: 16px;
`;

export const InfoKey = styled(Typography.TextBody_14_20)(
    ({ theme: { palette } }) => css`
        color: ${palette.text.assistive};
    `,
);

export const InfoValue = styled(Typography.TextBody_14_20)`
    margin-top: 2px;
`;
