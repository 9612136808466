import { useEffect } from 'react';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';

import {
    GroupTagSelectField,
    PaginatedSelectField,
    Switch,
    TextButton,
    TextField,
} from '@speeki/global-ui-components';

import { FormDeleteButton } from '@components/atoms/FormDeleteButton/FormDeleteButton';
import { FieldsDivided, FormWrapper } from '@components/ions/Form.styled';

import { NewTagAddFormValues } from '@domains/settings/organisms/NewTagAddModal/useNewTagAddModal';
import { CUSTOM_HOOK_FORM_ERRORS } from '@shared/constants';
import { useGroupTagNameSelect } from '@shared/selects/useGroupTagNameSelect';
import { useRiskAreasSelect } from '@shared/selects/useRiskAreasSelect';

import {
    DividedFieldRow,
    SwitchText,
    SwitchWrapper,
    TagFieldsWrapper,
} from './NewTagForm.styled';

interface NewTagFormProps {
    existingGroupTagSelected: boolean;
    setExistingGroupTagSelected: (data: boolean) => void;
    setShowTagsArray: (data: boolean) => void;
}

export const NewTagForm = ({
    existingGroupTagSelected,
    setExistingGroupTagSelected,
    setShowTagsArray,
}: NewTagFormProps) => {
    const {
        control,
        formState: { errors },
        register,
        setValue,
        watch,
    } = useFormContext<NewTagAddFormValues>();

    const { field: riskAreaField } = useController({
        control,
        name: 'riskArea',
    });

    const { field: groupTagNameField } = useController({
        control,
        name: 'groupTagName',
    });

    const { field: newTagSwitchField } = useController({
        control,
        name: 'newTagSwitch',
    });

    const tagsArray = useFieldArray({
        control,
        name: 'childTags',
    });

    const handleAddNewTag = (name: string) => {
        setValue(
            'groupTagName',
            {
                label: name,
                value: name,
            },
            {
                shouldDirty: true,
                shouldValidate: true,
            },
        );
        setValue('newTagSwitch', false);
        setExistingGroupTagSelected(false);
    };

    const switchValue = watch('newTagSwitch') ?? false;
    const riskAreaValue = watch('riskArea.value') ?? '';

    const handleAddField = () =>
        tagsArray.prepend(
            {
                id: undefined,
                value: '',
            },
            {
                shouldFocus: false,
            },
        );

    const disableSwitch = !groupTagNameField.value || existingGroupTagSelected;

    const riskAreasSelect = useRiskAreasSelect();
    const groupTagNameSelect = useGroupTagNameSelect({
        riskAreaUuids: [riskAreaValue],
    });

    useEffect(() => {
        setShowTagsArray(switchValue);
    }, [switchValue]);

    return (
        <FormWrapper>
            <DividedFieldRow>
                <PaginatedSelectField
                    {...riskAreaField}
                    {...riskAreasSelect}
                    closeMenuOnSelect={true}
                    error={!!errors.riskArea}
                    label={'Topic name'}
                    message={
                        errors.riskArea?.message ||
                        errors.riskArea?.value?.message
                    }
                    placeholder={'Select topic'}
                    testId="hlNxks-"
                />
                {/** @ts-expect-error value type is not proper because of optional select notRequired fields */}
                <GroupTagSelectField
                    {...groupTagNameField}
                    {...groupTagNameSelect}
                    closeMenuOnSelect={true}
                    error={!!errors.groupTagName}
                    isDisabled={!riskAreaField.value}
                    key={JSON.stringify(riskAreaValue)}
                    label={'Group tag name'}
                    maxLength={75}
                    message={
                        errors.groupTagName?.message ||
                        errors.groupTagName?.value?.message
                    }
                    onBlur={(e) => {
                        if (e.target.value) handleAddNewTag(e.target.value);
                    }}
                    onChange={(newVal) => {
                        if (newVal) {
                            groupTagNameField.onChange(newVal);
                            setExistingGroupTagSelected(true);
                            setValue('newTagSwitch', true, {
                                shouldDirty: true,
                                shouldValidate: true,
                            });
                        }
                    }}
                    onConfirm={handleAddNewTag}
                    placeholder={'Select or enter group tag name'}
                    testId="NsTm3tK"
                />
            </DividedFieldRow>
            <SwitchWrapper addNewTags={!!newTagSwitchField.value}>
                <Switch
                    checked={newTagSwitchField.value}
                    disabled={disableSwitch}
                    onChange={newTagSwitchField.onChange}
                    testId="MjGalmv"
                />
                <SwitchText disabled={!groupTagNameField.value}>
                    Add a new tag that can be associated with this group tag.
                </SwitchText>
            </SwitchWrapper>

            {!!newTagSwitchField.value && (
                <TagFieldsWrapper>
                    <TextButton
                        iconProps={{
                            icon: 'SvgAddNew',
                            size: 16,
                            themeColors: {
                                default: {
                                    'color-1': 'tertiary.default',
                                },
                                hover: {
                                    'color-1': 'tertiary.hover',
                                },
                            },
                        }}
                        onClick={handleAddField}
                        testId="iMnIq7y"
                        type="button"
                    >
                        Add new tag
                    </TextButton>

                    {tagsArray.fields.map((field, index) => {
                        const errorType =
                            errors.childTags?.[index]?.value?.type;

                        const isFieldError =
                            errorType &&
                            [
                                CUSTOM_HOOK_FORM_ERRORS.DUPLICATE,
                                CUSTOM_HOOK_FORM_ERRORS.NAME_ALREADY_EXISTS,
                                CUSTOM_HOOK_FORM_ERRORS.REQUIRED,
                            ].includes(errorType);

                        return (
                            <FieldsDivided
                                gap={'16px'}
                                gridTemplateColumns={'428px auto'}
                                key={field.id}
                            >
                                <TextField
                                    {...register(`childTags.${index}.value`)}
                                    error={!!isFieldError}
                                    label={'Tag name'}
                                    maxLength={75}
                                    message={
                                        isFieldError
                                            ? errors.childTags?.[index]?.value
                                                  ?.message
                                            : undefined
                                    }
                                    placeholder={'Enter tag name'}
                                    tabIndex={1}
                                    testId="4md15gE"
                                />

                                {tagsArray.fields.length > 1 && (
                                    <FormDeleteButton
                                        handleRemove={() =>
                                            tagsArray.remove(index)
                                        }
                                    />
                                )}
                            </FieldsDivided>
                        );
                    })}
                </TagFieldsWrapper>
            )}
        </FormWrapper>
    );
};
