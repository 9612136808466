import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const EmptyStateTitleWrapper = styled(Typography.TextH4_20_28)`
    text-align: center;
`;

export const EmptyStateDescriptionWrapper = styled(Typography.TextBody_14_24)(
    ({ theme: { font } }) => css`
        font-family: ${font.secondary};
        text-align: center;
    `,
);
