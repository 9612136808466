import { ReactNode } from 'react';

import { Typography } from '@speeki/global-ui-components';
import { IconManager } from '@speeki/icons';

import { ErrorBoxWrapper } from '@components/molecules/ErrorBox/ErrorBox.styled';

interface ErrorBoxProps {
    children: string | ReactNode;
    className?: string;
}

export const ErrorBox = ({ children, className }: ErrorBoxProps) => {
    return (
        <ErrorBoxWrapper className={className}>
            <IconManager icon={'SvgAlert24'} size={24} />
            {typeof children === 'string' ? (
                <Typography.TextBody_14_20>
                    {children}
                </Typography.TextBody_14_20>
            ) : (
                children
            )}
        </ErrorBoxWrapper>
    );
};
