import { Outlet } from 'react-router-dom';

import { Skeleton, Typography } from '@speeki/global-ui-components';

import {
    MainPageContent,
    MainPageWrapper,
} from '@components/ions/Pages.styled';
import { Tab, Tabs } from '@components/molecules/Tabs/Tabs';
import { MainLayout } from '@components/templates/MainLayout/MainLayout';

import { ConfirmationModal } from '@domains/clients/organisms/ConfirmationModal/ConfirmationModal';
import { DeactivateTenantModal } from '@domains/clients/organisms/DeactivateTenantModal/DeactivateTenantModal';
import {
    ClientDeactivateButton,
    ClientTextButton,
} from '@domains/clients/pages/SingleClientPage/CustomClientButtons';
import {
    ClientTableTab,
    useSingleClientPage,
} from '@domains/clients/pages/SingleClientPage/useSingleClientPage';

import {
    DeletionIcon,
    DeletionInfoBox,
    DeletionText,
    DemoLabel,
    MainPagePanel,
    PanelActions,
    PanelHeader,
} from './SingleClientPage.styled';

export const SingleClientPage = () => {
    const {
        clientId,
        clientTableTab,
        data,
        deactivationConfirmationModal,
        deleteConfirmationModal,
        demoModule,
        handleDeleteTenant,
        handleReactivateTenant,
        handleUpdateDemoMode,
        isTenantAwaitActivation,
        isTenantDeactivated,
        loadingChange,
        loadingDelete,
        reactivationConfirmationModal,
        tenantDeletionDate,
    } = useSingleClientPage();

    return (
        <MainLayout>
            <MainPageWrapper>
                <MainPagePanel>
                    <PanelHeader>
                        {data?.clientDetails.name ? (
                            <Typography.TextH1_36_52>
                                {data?.clientDetails.name}
                            </Typography.TextH1_36_52>
                        ) : (
                            <Skeleton height={52} width={250} />
                        )}

                        {demoModule?.enabled && (
                            <DemoLabel>
                                <Typography.TextBody_14_20 fontWeight="bold">
                                    DEMO MODE
                                </Typography.TextBody_14_20>
                            </DemoLabel>
                        )}
                        <PanelActions>
                            {data?.clientDetails.name ? (
                                <>
                                    {demoModule && !isTenantDeactivated && (
                                        <ClientTextButton
                                            handleClick={handleUpdateDemoMode}
                                            isDemoIcon
                                            testId="FZq-XeD"
                                        >
                                            {demoModule.enabled
                                                ? 'Disable demo mode'
                                                : 'Enable demo mode'}
                                        </ClientTextButton>
                                    )}
                                    <>
                                        {!isTenantDeactivated &&
                                            (isTenantAwaitActivation ? (
                                                <ClientDeactivateButton
                                                    handleClick={() =>
                                                        deleteConfirmationModal.setOpen(
                                                            true,
                                                        )
                                                    }
                                                    testId="WeC1KGT"
                                                >
                                                    Delete tenant data
                                                    immediately
                                                </ClientDeactivateButton>
                                            ) : (
                                                <ClientDeactivateButton
                                                    handleClick={() =>
                                                        deactivationConfirmationModal.setOpen(
                                                            true,
                                                        )
                                                    }
                                                    testId="KZTiqAe"
                                                >
                                                    Deactivate tenant
                                                </ClientDeactivateButton>
                                            ))}

                                        {isTenantDeactivated && (
                                            <>
                                                <ClientTextButton
                                                    handleClick={() =>
                                                        reactivationConfirmationModal.setOpen(
                                                            true,
                                                        )
                                                    }
                                                    testId="plJWMpA"
                                                >
                                                    Reactivate tenant
                                                </ClientTextButton>
                                                <ClientDeactivateButton
                                                    handleClick={() =>
                                                        deleteConfirmationModal.setOpen(
                                                            true,
                                                        )
                                                    }
                                                    testId="WeC1KGT"
                                                >
                                                    Delete tenant data
                                                    immediately
                                                </ClientDeactivateButton>
                                            </>
                                        )}
                                    </>
                                </>
                            ) : (
                                <Skeleton height={24} width={500} />
                            )}
                        </PanelActions>
                    </PanelHeader>
                    <Tabs<ClientTableTab>
                        additionalComponent={
                            isTenantDeactivated && (
                                <DeletionInfoBox>
                                    <DeletionIcon
                                        icon={'SvgInfo24'}
                                        size={24}
                                    />
                                    <DeletionText>
                                        This tenant data will be permanently
                                        deleted at {tenantDeletionDate}.
                                    </DeletionText>
                                </DeletionInfoBox>
                            )
                        }
                        tabComponent={({ disabled, name, path }, index) => (
                            <Tab disabled={disabled} key={index} to={path}>
                                {name}
                            </Tab>
                        )}
                        tabs={clientTableTab}
                    />
                </MainPagePanel>
                <MainPageContent>
                    <Outlet />

                    {deleteConfirmationModal.open && (
                        <ConfirmationModal
                            {...deleteConfirmationModal}
                            description={
                                'Are you sure you want to delete all tenant data immediately? This action cannot be undone.'
                            }
                            loading={loadingDelete}
                            onSubmit={handleDeleteTenant}
                        />
                    )}

                    {reactivationConfirmationModal.open && (
                        <ConfirmationModal
                            {...reactivationConfirmationModal}
                            description={
                                'Are you sure you want to reactivate this tenant?'
                            }
                            loading={loadingChange}
                            onSubmit={handleReactivateTenant}
                        />
                    )}

                    {deactivationConfirmationModal.open && (
                        <DeactivateTenantModal
                            {...deactivationConfirmationModal}
                            tenantName={data?.clientDetails.name ?? ''}
                            tenantUuid={clientId ?? ''}
                        />
                    )}
                </MainPageContent>
            </MainPageWrapper>
        </MainLayout>
    );
};
