import { SLOT_DATA_TYPE, SLOT_TYPE, SLOT_TYPE_LABEL } from '@speeki/dictionary';

import { toCapitalize } from '@utils/helpers';

type SlotTypeValue = keyof typeof SLOT_TYPE;

export interface SlotTypeOptions {
    key: number;
    label: string;
    value: SlotTypeValue;
}

export interface IsLegacyTypeOptions {
    key: number;
    label: string;
    value: boolean;
}

type DataTypeValue = keyof typeof SLOT_DATA_TYPE;

export interface DataTypeOptions {
    key: number;
    label: string;
    value: DataTypeValue;
}

export const slotTypes: SlotTypeOptions[] = Object.entries(SLOT_TYPE_LABEL).map(
    ([key, value], index) => ({
        key: index,
        label: toCapitalize(value),
        value: key as SlotTypeValue,
    }),
);

const dataTypesKeys = Object.keys(SLOT_DATA_TYPE) as DataTypeValue[];

export const dataTypes: DataTypeOptions[] = dataTypesKeys.map((key, index) => ({
    key: index,
    label: SLOT_DATA_TYPE[key],
    value: key,
}));

export const isLegacyTypes: IsLegacyTypeOptions[] = [
    {
        key: 0,
        label: 'True',
        value: true,
    },
    { key: 1, label: 'False', value: false },
];
