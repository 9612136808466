import styled, { css } from 'styled-components';

export const TabsWrapper = styled.div`
    display: flex;
`;

export const TabsContainer = styled.div`
    ${({ theme: { colors } }) => css`
        align-items: flex-end;
        background: ${colors.white};
        border-bottom: 1px solid ${colors.dividers};
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;
    `};
`;
