import styled from 'styled-components';

const HEADER_HEIGHT = 100;

const AuthLayoutWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${HEADER_HEIGHT}px 1fr;
    grid-template-areas:
        'header'
        'content';
    min-height: 100vh;
    overflow: hidden;
    position: relative;
`;

const AuthLayoutHeader = styled.div`
    align-items: center;
    display: flex;
    grid-area: header;
    padding-left: 46px;
    z-index: 99;
`;

const AuthLayoutContent = styled.div`
    align-items: center;
    display: flex;
    grid-area: content;
    justify-content: center;
    margin-top: -${HEADER_HEIGHT}px;
    position: relative;
    z-index: 9;
`;

const BackgroundImageWrapper = styled.div`
    position: absolute;
    right: 100px;
    top: -100px;
`;

const LogoWrapper = styled.div`
    cursor: pointer;
`;

export const AuthLayoutStyles = {
    Content: AuthLayoutContent,
    Header: AuthLayoutHeader,
    ImageWrapper: BackgroundImageWrapper,
    LogoWrapper,
    Wrapper: AuthLayoutWrapper,
};
