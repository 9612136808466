import { useELineNumbersCard } from './useELineNumbersCard';

import { Button, Typography } from '@speeki/global-ui-components';

import { Card } from '@domains/clients/ions/Card.styled';
import { ELineNumbersTable } from '@domains/clients/organisms/ELineNumbers/ELineNumbersTable/ELineNumbersTable';
import { ELineNumbersUpsertModal } from '@domains/clients/organisms/ELineNumbers/ELineNumbersUpsertModal/ELineNumbersUpsertModal';

export const ELineNumbersCard = () => {
    const { showTable, tableProps, upsertModal, upsertModalProps } =
        useELineNumbersCard();

    return (
        <>
            <Card.Wrapper>
                <Card.InnerWrapper>
                    <Card.Header
                        justifyContent={'space-between'}
                        marginBottom={'32px'}
                    >
                        <Typography.TextH2_28_44>
                            Speeki eLine
                        </Typography.TextH2_28_44>
                        <Button
                            onClick={() => upsertModal.setOpen(true)}
                            size={'small'}
                            testId="nPzraub"
                            variant={'secondary'}
                        >
                            Add new phone number
                        </Button>
                    </Card.Header>
                    {showTable && (
                        <ELineNumbersTable
                            {...tableProps}
                            upsertModal={upsertModal}
                        />
                    )}
                </Card.InnerWrapper>
            </Card.Wrapper>

            {upsertModal.open && (
                <ELineNumbersUpsertModal
                    {...upsertModal}
                    {...upsertModalProps}
                />
            )}
        </>
    );
};
