import { useFormContext } from 'react-hook-form';

import { TextField } from '@speeki/global-ui-components';

import { DividedFieldRow, FormWrapper } from '@components/ions/Form.styled';

import { PrimaryContactFormValues } from '@domains/clients/organisms/PrimaryContactModal/PrimaryContactModal.tsx';

export const PrimaryContactForm = () => {
    const {
        formState: { errors },
        register,
    } = useFormContext<PrimaryContactFormValues>();

    return (
        <FormWrapper>
            <DividedFieldRow>
                <TextField
                    {...register('firstName')}
                    error={!!errors.firstName}
                    id={'first-name'}
                    label={'First name'}
                    message={errors.firstName?.message}
                    placeholder={'Enter user’s first name'}
                    testId="AyU6g1S"
                />
                <TextField
                    {...register('lastName')}
                    error={!!errors.lastName}
                    id={'last-name'}
                    label={'Last name'}
                    message={errors.lastName?.message}
                    placeholder={'Enter user’s last name'}
                    testId="9i0Faw3"
                />
            </DividedFieldRow>
            <DividedFieldRow>
                <TextField
                    {...register('email')}
                    error={!!errors.email}
                    id={'email-address'}
                    label={'Email address'}
                    message={errors.email?.message}
                    placeholder={'Enter user’s email address'}
                    testId="PwMMpRt"
                />
            </DividedFieldRow>
        </FormWrapper>
    );
};
