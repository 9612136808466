import styled from 'styled-components';

import { FieldRow } from '@components/ions/Form.styled';

export const SlotFormWrapper = styled.form`
    position: relative;
`;

export const DividedFieldRow = styled(FieldRow)`
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
`;
