import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
    Button,
    Modal,
    ModalComponent,
    notify,
} from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import { SlotForm } from '@components/organisms/SlotForm/SlotForm';

import {
    AssistantSlotsDocument,
    CreateAssistantSlotDocument,
    Slot_Type,
    SlotDataType,
    UpdateAssistantSlotDocument,
} from '@graphql/generated/graphql';

import { useMutation } from '@apollo/client';
import {
    DataTypeOptions,
    dataTypes,
    IsLegacyTypeOptions,
    isLegacyTypes,
    SlotTypeOptions,
    slotTypes,
} from '@domains/messaging/pages/MessagingPage/useMessagingPage';
import { yupResolver } from '@hookform/resolvers/yup';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';

interface SlotModalProps extends UseModal {
    defaultValues?: {
        actionId: string;
        dataType?: string | null;
        isLegacy: string;
        legacyType: string;
        slotId: string;
        text: string;
    };
    onClose?: () => void;
}

interface SelectType {
    key: number;
    label: string;
    value: boolean;
}

export const SlotModal = ({
    defaultValues,
    onClose,
    open,
    setOpen,
}: SlotModalProps) => {
    const isUpdate = !!(defaultValues?.slotId && defaultValues?.actionId);
    const defaultLegacyType = slotTypes.find(
        (slot) => slot.value === defaultValues?.legacyType,
    ) as SlotTypeOptions;
    const defaultIsLegacy = isLegacyTypes.find(
        (legacyType) => legacyType.label === defaultValues?.isLegacy,
    ) as IsLegacyTypeOptions;
    const defaultDataType = dataTypes.find(
        (dataType) => dataType.value === defaultValues?.dataType,
    ) as DataTypeOptions;

    const methods = useForm<SlotFormValues>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(SlotSchema),
    });

    const {
        formState: { isSubmitted, isSubmitting, isValid },
        handleSubmit,
        reset,
        setValue,
    } = methods;

    const handleClose = () => {
        setOpen(false);
        onClose?.();
        reset();
    };

    const [createAssistantSlot, { loading }] = useMutation(
        CreateAssistantSlotDocument,
        {
            context: {
                notifyOnError: true,
            },
            onCompleted: () => {
                handleClose?.();
                notify(SUCCESS_MESSAGES.SLOT_ADD_SUCCESSFULLY).SUCCESS();
            },
            onError: () => {},
            refetchQueries: [
                {
                    query: AssistantSlotsDocument,
                    variables: {
                        input: {
                            paging: { limit: 10, offset: 1 },
                            sorting: [],
                        },
                    },
                },
            ],
        },
    );
    const [updateAssistantSlot] = useMutation(UpdateAssistantSlotDocument, {
        context: {
            notifyOnError: true,
        },
        onCompleted: () => {
            handleClose?.();
            notify(SUCCESS_MESSAGES.SLOT_UPDATED_SUCCESSFULLY).SUCCESS();
        },
        onError: () => {},
        optimisticResponse: ({ input }) => ({
            assistantSlot: {
                ...input,
                __typename: 'AssistantSlot',
                dataType: input.dataType || null,
                isLegacy: !!input.isLegacy,
                legacyType: input.legacyType as Slot_Type,
                text: input.text ?? '',
            },
        }),
    });

    const onSubmit = (data: SlotFormValues) => {
        void createAssistantSlot({
            variables: {
                input: {
                    actionId: data.actionId,
                    dataType: (data?.dataType?.value as SlotDataType) ?? null,
                    isLegacy: data.isLegacy.value,
                    legacyType: data.legacyType.value as unknown as Slot_Type,
                    slotId: data.slotId,
                    text: data.text,
                },
            },
        });
    };

    const onUpdate = (data: SlotFormValues) => {
        void updateAssistantSlot({
            variables: {
                input: {
                    actionId: data.actionId,
                    dataType: (data?.dataType?.value as SlotDataType) ?? null,
                    isLegacy: !!data.isLegacy.value,
                    legacyType: data.legacyType.value as unknown as Slot_Type,
                    slotId: data.slotId,
                    text: data.text,
                },
            },
        });
    };

    useEffect(() => {
        if (isUpdate) {
            setValue('actionId', defaultValues.actionId, {
                shouldDirty: false,
                shouldValidate: false,
            });
            setValue('isLegacy', defaultIsLegacy, {
                shouldDirty: false,
                shouldValidate: false,
            });
            setValue(
                'legacyType',
                {
                    key: defaultLegacyType.key,
                    label: defaultLegacyType.label,
                    value: defaultLegacyType.value,
                },
                {
                    shouldDirty: false,
                    shouldValidate: false,
                },
            );

            if (defaultDataType?.value)
                setValue(
                    'dataType',
                    {
                        key: defaultDataType.key,
                        label: defaultDataType.label,
                        value: defaultDataType.value,
                    },
                    {
                        shouldDirty: false,
                        shouldValidate: false,
                    },
                );
            setValue('slotId', defaultValues.slotId, {
                shouldDirty: false,
                shouldValidate: false,
            });
            setValue('text', defaultValues.text, {
                shouldDirty: false,
                shouldValidate: false,
            });
        }
    }, [defaultValues, isUpdate]);

    return (
        <ModalComponent onClose={handleClose} open={open}>
            <Modal.Wrapper>
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>Slot</Modal.Title>
                        <Modal.SubTitle>
                            {isUpdate
                                ? 'Edit a mapped response of IBM Watson bot.'
                                : 'Add a mapped response of IBM Watson bot.'}
                        </Modal.SubTitle>
                    </Modal.Header>
                    <FormProvider {...methods}>
                        <SlotForm isUpdate={isUpdate} />
                    </FormProvider>
                </Modal.Content>
                <Modal.Footer>
                    <Modal.ButtonsWrapper>
                        <Button
                            onClick={handleClose}
                            size={'small'}
                            testId="07gYxdX"
                            variant={'secondary'}
                        >
                            cancel
                        </Button>
                        <Button
                            disabled={!isValid && isSubmitted}
                            isLoading={loading || isSubmitting}
                            onClick={
                                isUpdate
                                    ? handleSubmit(onUpdate)
                                    : handleSubmit(onSubmit)
                            }
                            size={'small'}
                            testId="3fSC0F1"
                            variant={'primary'}
                        >
                            save
                        </Button>
                    </Modal.ButtonsWrapper>
                </Modal.Footer>
            </Modal.Wrapper>
        </ModalComponent>
    );
};

export const SlotSchema = yup.object({
    actionId: yup.string().required('This field cannot be empty'),
    dataType: yup
        .object({
            key: yup.number(),
            label: yup.string(),
            value: yup.string(),
        })
        .optional()
        .nullable(),
    isLegacy: yup
        .object({
            key: yup.number().required('This field cannot be empty'),
            label: yup.string().required('This field cannot be empty'),
            value: yup.boolean().required('This field cannot be empty'),
        })
        .nullable()
        .required('This field cannot be empty.'),
    legacyType: yup
        .object({
            key: yup.number(),
            label: yup.string(),
            value: yup.string(),
        })
        .when('isLegacy', {
            is: (obj: SelectType) => {
                return obj.value;
            },
            then: yup.object({
                key: yup.number().required('This field cannot be empty'),
                label: yup.string().required('This field cannot be empty'),
                value: yup.string().required('This field cannot be empty'),
            }),
        }),
    slotId: yup.string().required('This field cannot be empty'),
    text: yup.string().required('This field cannot be empty'),
});

export type SlotFormValues = yup.InferType<typeof SlotSchema>;
