import styled, { css } from 'styled-components';

export const EmptyStateWrapper = styled.div(
    ({ theme: { palette } }) => css`
        align-items: center;
        border-top: 1px solid ${palette.neutral.dividers};
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-top: 32px;
        width: 100%;
    `,
);
