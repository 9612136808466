import styled from 'styled-components';

import { SearchField } from '@speeki/global-ui-components';

export const HeaderWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
`;

export const ActionsWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 24px;
    justify-content: flex-end;
`;

export const GuidelineSearchField = styled(SearchField)`
    margin: 24px 0 24px auto;
    width: 264px;
`;
