import { useState } from 'react';

import { PaginatedSelectSelectFieldProps } from '@speeki/global-ui-components';

import { RiskAreasDocument } from '@graphql/generated/graphql';

import { useLazyQuery } from '@apollo/client';
import { ERROR_MESSAGES } from '@shared/notifyMessages';

export const useRiskAreasSelect = () => {
    const [noResultsText, setResultsText] = useState<string | undefined>(
        undefined,
    );

    const [getRiskAreas] = useLazyQuery(RiskAreasDocument, {
        fetchPolicy: 'cache-and-network',
    });

    const loadOptions: PaginatedSelectSelectFieldProps['loadOptions'] = async (
        nameSearch,
    ) => {
        try {
            const { data, error } = await getRiskAreas({
                variables: {
                    input: {
                        nameSearch,
                        sort: [
                            {
                                direction: 'DESC',
                                field: 'IS_SHARED',
                            },
                            {
                                direction: 'ASC',
                                field: 'NAME',
                            },
                        ],
                        types: null,
                    },
                },
            });

            !!error
                ? setResultsText(ERROR_MESSAGES.SELECT_FETCH)
                : setResultsText(undefined);

            const options =
                data?.riskAreas?.map((node) => ({
                    label: node.label,
                    value: node.uuid,
                })) || [];

            return error
                ? { hasMore: false, options: [] }
                : {
                      additional: {
                          offset: 1,
                      },
                      hasMore: false,
                      options,
                  };
        } catch {
            setResultsText(ERROR_MESSAGES.SELECT_FETCH);

            return {
                hasMore: false,
                options: [],
            };
        }
    };

    return {
        debounceTimeout: 200,
        loadOptions,
        noResultsText,
    };
};
