import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthMeDocument } from '@graphql/generated/graphql';

import { useLazyQuery } from '@apollo/client';
import { logout } from '@shared/logout';

interface PermissionRouteProps {
    children: ReactNode;
    /**
     * Additional condition if {@link permissions} are insufficient.
     */
    condition?: boolean;
    /**
     * Event fired before {@link redirectTo}.
     */
    onRedirect?: () => void;
    /**
     * The path to which the user will be redirected when {@link condition} or {@link permissions} are not met.
     */
    redirectTo: string;
}

/**
 * IMPORTANT! PermissionRoute requires user data before render.
 */
export const PermissionRoute = ({
    children,
    condition = true,
    onRedirect,
    redirectTo,
}: PermissionRouteProps) => {
    const navigate = useNavigate();

    const [authMe, { called, data, loading }] = useLazyQuery(AuthMeDocument, {
        context: {
            logoutOnError: true,
            notifyOnError: false,
        },
    });

    useEffect(() => {
        void authMe();
    }, []);

    const logOutCondition = !data;
    const validCondition = !!condition && data?.user.type === 'SPEEKI_ADMIN';

    useEffect(() => {
        if (!loading && called) {
            if (logOutCondition) logout();
            else if (!validCondition) {
                onRedirect?.();
                navigate(redirectTo);
            }
        }
    }, [logOutCondition, redirectTo, validCondition, called]);

    return <>{children}</>;
};
