import { useController, useForm } from 'react-hook-form';

import { SearchField } from '@speeki/global-ui-components';

import {
    FieldWrapper,
    ProfileSearchWrapper,
} from './EsgProfileSearchForm.styled';

interface ClientsSearchFormProps {
    onSearch: (value: string) => void;
}

export const EsgProfileSearchForm = ({ onSearch }: ClientsSearchFormProps) => {
    const { control } = useForm<{ search: string }>({
        defaultValues: {
            search: '',
        },
    });

    const {
        field: { name, onChange, value },
    } = useController({
        control,
        name: 'search',
    });

    return (
        <ProfileSearchWrapper>
            <FieldWrapper>
                <SearchField
                    name={name}
                    onChange={onChange}
                    onReset={() => onSearch('')}
                    onSearch={onSearch}
                    placeholder={'Search...'}
                    sizeType={'small'}
                    testId="RYNkjND"
                    value={value}
                    variant={'primary'}
                />
            </FieldWrapper>
        </ProfileSearchWrapper>
    );
};
