import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ElineNumbersTableRow, headerData } from './ELineNumbersTable.settings';

import {
    notify,
    SearchTable,
    SecondaryManualTable,
} from '@speeki/global-ui-components';
import { UseModal, useModal } from '@speeki/react-hooks';

import { TableEmptyState } from '@components/molecules/CardTableEmptyState/TableEmptyState';

import {
    ClientELineNumbersDocument,
    ELineNumberDeleteDocument,
} from '@graphql/generated/graphql';

import { countriesOptions } from '@utils/dictionary';

import { useLazyQuery, useMutation } from '@apollo/client';
import { SearchTableWrapper } from '@domains/clients/ions/Table.styled';
import { ELineNumberDeleteModal } from '@domains/clients/organisms/ELineNumbers/ELineNumberDeleteModal/ELineNumberDeleteModal';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';

interface ElineNumbersTableProps {
    deletedItem: ElineNumbersTableRow | undefined;
    setDeletedItem: (data: ElineNumbersTableRow) => void;
    setEditedItem: (data: ElineNumbersTableRow) => void;
    upsertModal: UseModal;
}

export const ELineNumbersTable = ({
    deletedItem,
    setDeletedItem,
    setEditedItem,
    upsertModal,
}: ElineNumbersTableProps) => {
    const { clientId } = useParams<{ clientId: string }>();

    const deleteModal = useModal();

    const [getNumbersList, { called, data, loading }] = useLazyQuery(
        ClientELineNumbersDocument,
        {
            context: {
                notifyOnError: true,
            },
            fetchPolicy: 'cache-and-network',
        },
    );

    const [deleteNumber, { loading: deleteLoading }] = useMutation(
        ELineNumberDeleteDocument,
        {
            context: {
                notifyOnError: true,
            },
            onCompleted: () => {
                notify(SUCCESS_MESSAGES.ELINE_NUMBER_DELETED).INFO();
                deleteModal.setOpen(false);
            },
            onError: () => {},
            update: (cache, { data }) => {
                data?.number.forEach((item) => {
                    const normalizedId = cache.identify({
                        id: item,
                        __typename: 'ElineNumber',
                    });
                    cache.evict({ id: normalizedId });
                });
                cache.gc();
            },
        },
    );

    const transformedData: ElineNumbersTableRow[] =
        useMemo(
            () =>
                data?.numbers.elineNumbers.map((item) => ({
                    id: item.uuid,
                    country:
                        countriesOptions.find((country) => {
                            const selectedCountry =
                                item.assignedCountry ?? item.defaultCountry;
                            return country.value === selectedCountry;
                        })?.label || '',
                    options: true,
                    phoneNumber: item?.phoneNumber as string,
                })),
            [data],
        ) || [];

    const handleDeleteItem = (item?: ElineNumbersTableRow) => {
        if (!item) return;

        !deleteLoading &&
            void deleteNumber({
                variables: {
                    input: {
                        uuids: [item.id],
                    },
                },
            });
    };

    return (
        <SearchTableWrapper>
            <SearchTable
                render={(searchValue) => (
                    <SecondaryManualTable<ElineNumbersTableRow>
                        columns={headerData({
                            onDelete: (row) => {
                                setDeletedItem(row);
                                deleteModal.setOpen(true);
                            },
                            onEdit: (row) => {
                                setEditedItem(row);
                                upsertModal.setOpen(true);
                            },
                        })}
                        data={transformedData}
                        pagination={{
                            options: {
                                totalCount:
                                    data?.numbers.elineNumbers.length ?? 0,
                            },
                        }}
                        tableState={{
                            emptyState: <TableEmptyState marginTop={'-24px'} />,
                            getTableData: (props) => {
                                void getNumbersList({
                                    variables: {
                                        search: props.searchPhrase,
                                        tenantUuid: clientId ?? '',
                                    },
                                });
                            },
                            loading: loading || !called,
                            searchPhrase: searchValue,
                            skeletonVariant: 'default',
                        }}
                        testId="nU8q4J1"
                    />
                )}
                testId="z5UEMUD"
            />

            {deleteModal.open && (
                <ELineNumberDeleteModal
                    {...deleteModal}
                    onConfirm={() => handleDeleteItem(deletedItem)}
                />
            )}
        </SearchTableWrapper>
    );
};
