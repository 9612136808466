import { useController, useFormContext } from 'react-hook-form';

import { SelectField, TextField } from '@speeki/global-ui-components';

import { DividedFieldRow, FormWrapper } from '@components/ions/Form.styled';

import { countriesOptions } from '@utils/dictionary';

import { CompanyInformationFormValues } from '@domains/clients/organisms/CompanyInformationModal/CompanyInformationModal.tsx';

export const CompanyInformationForm = () => {
    const {
        control,
        formState: { errors },
        register,
    } = useFormContext<CompanyInformationFormValues>();

    const { field: countrySelect } = useController({
        control,
        name: 'country',
    });

    return (
        <FormWrapper>
            <DividedFieldRow>
                <TextField
                    {...register('companyName')}
                    error={!!errors.companyName}
                    id={'company-name'}
                    label={'Company name'}
                    message={errors.companyName?.message}
                    placeholder={'Enter company name'}
                    testId="GBBK0PC"
                />
                <SelectField
                    {...countrySelect}
                    error={!!errors.country}
                    id={'country'}
                    isClearable={false}
                    label={'Country'}
                    message={errors.country?.value?.message}
                    options={countriesOptions}
                    placeholder={'Select country'}
                    testId="9G2p155"
                />
            </DividedFieldRow>
        </FormWrapper>
    );
};
