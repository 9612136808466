import { Skeleton } from '@speeki/global-ui-components';

import { Circle } from '@components/svgs/Circle';

import { AuthLayoutStyles } from './AuthLayout.styled';

export const AuthLayoutSkeleton = () => {
    return (
        <AuthLayoutStyles.Wrapper>
            <AuthLayoutStyles.ImageWrapper>
                <Circle />
            </AuthLayoutStyles.ImageWrapper>
            <AuthLayoutStyles.Header>
                <Skeleton height={40} variant={'inverted'} width={150} />
            </AuthLayoutStyles.Header>
        </AuthLayoutStyles.Wrapper>
    );
};
