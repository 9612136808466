import { debounce } from 'lodash-es';
import React, { memo, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { mergeRefs } from 'react-merge-refs';
import rehypeSlug from 'rehype-slug';
import remarkGfm from 'remark-gfm';
import supersub from 'remark-supersub';

import {
    BaseTextareaProps,
    Field,
    FieldLabels,
    FieldWrapper,
    generateId,
    InputMessage,
    Label,
    TextButton,
    Tooltip,
} from '@speeki/global-ui-components';
import { IconManager } from '@speeki/icons';
import { useModal } from '@speeki/react-hooks';

import {
    FieldLabelsWrapper,
    ReactMarkdownInput,
    ReactMarkdownPreview,
    ReactMarkdownWrapper,
    TooltipInfoButton,
} from '@domains/settings/organisms/MarkdownField/MarkdownField.styled';
import { MarkdownSyntaxModal } from '@domains/settings/organisms/MarkdownSyntaxModal/MarkdownSyntaxModal';

interface MarkdownFieldProps extends BaseTextareaProps, Field {
    error?: boolean;
    inputClassName?: string;
    markdown: string;
    name: string;
    placeholder?: string;
    setMarkdown: (markdown: string) => void;
    testId: string;
    tooltip?: string;
}

const WAIT_TIME_MS = 200;

export const MarkdownField = memo(
    React.forwardRef<HTMLTextAreaElement, MarkdownFieldProps>(
        (
            {
                className,
                clickableLabel,
                error,
                inputClassName,
                label,
                markdown,
                message,
                placeholder,
                setMarkdown,
                testId,
                tooltip,
                ...rest
            },
            ref,
        ) => {
            const [inputId, setInputId] = useState(rest.id);
            const textareaRef = useRef<HTMLTextAreaElement>(null);

            const syntaxModal = useModal();

            useEffect(() => {
                if (!inputId) {
                    setInputId(rest.id || generateId(rest.name));
                }
            }, [rest]);

            useEffect(() => {
                const formUpdate = debounce(() => {
                    setMarkdown(textareaRef.current?.value ?? '');
                }, WAIT_TIME_MS);

                if (textareaRef) {
                    textareaRef?.current?.addEventListener('keyup', formUpdate);
                }

                return () => {
                    textareaRef?.current?.removeEventListener(
                        'keyup',
                        formUpdate,
                    );
                };
            }, []);

            return (
                <FieldWrapper className={className} data-testid={testId}>
                    {(label || clickableLabel) && (
                        <FieldLabelsWrapper>
                            <FieldLabels>
                                {label && (
                                    <Label
                                        htmlFor={inputId}
                                        testId={`${testId}-label`}
                                    >
                                        {label}
                                    </Label>
                                )}
                                {tooltip && (
                                    <Tooltip
                                        distance={10}
                                        placement={'bottom'}
                                        testId="C4f0c9g"
                                        text={tooltip}
                                        width={380}
                                    >
                                        <TooltipInfoButton>
                                            <IconManager
                                                icon={'SvgInfo24'}
                                                size={24}
                                                themeColors={{
                                                    default: {
                                                        'color-1':
                                                            'text.primary',
                                                    },
                                                }}
                                            />
                                        </TooltipInfoButton>
                                    </Tooltip>
                                )}
                            </FieldLabels>
                            <TextButton
                                onClick={() => syntaxModal.setOpen(true)}
                                testId="vJDOQZb"
                            >
                                Preview Markdown syntax
                            </TextButton>
                        </FieldLabelsWrapper>
                    )}

                    <ReactMarkdownWrapper
                        className={inputClassName}
                        id={inputId}
                    >
                        <ReactMarkdownInput
                            {...rest}
                            error={!!error}
                            placeholder={placeholder}
                            ref={mergeRefs([ref, textareaRef])}
                        />
                        <ReactMarkdownPreview>
                            <ReactMarkdown
                                children={markdown}
                                components={{
                                    p: (data) => {
                                        return <p>{data.children}</p>;
                                    },
                                    sup: (data) => {
                                        return (
                                            <sup
                                                id={
                                                    // @ts-expect-error wrong types
                                                    data.children?.[0]?.props
                                                        ?.id ?? undefined
                                                }
                                            >
                                                {data.children}
                                            </sup>
                                        );
                                    },
                                }}
                                rehypePlugins={[rehypeSlug]}
                                remarkPlugins={[
                                    [remarkGfm, { singleTilde: false }],
                                    supersub,
                                ]}
                            />
                        </ReactMarkdownPreview>
                    </ReactMarkdownWrapper>

                    {message && (
                        <InputMessage
                            iconProps={
                                error
                                    ? { icon: 'SvgAlert24', size: 16 }
                                    : undefined
                            }
                            testId={`${testId}-message`}
                            variant={error ? 'error' : 'additional'}
                        >
                            {message}
                        </InputMessage>
                    )}

                    <MarkdownSyntaxModal
                        {...syntaxModal}
                        onClose={() => syntaxModal.setOpen(false)}
                    />
                </FieldWrapper>
            );
        },
    ),
);
