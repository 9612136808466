/**
 * Every variable need to have suffix 'Var' and should have description above
 */
// export const exampleNameVar = makeVar<boolean>(false);
import { makeVar } from '@apollo/client';

/**
 * Variable for storing edited group tag id.
 */
export const editedGroupTagIdVar = makeVar<string>('');

/**
 * Variable for storing edited  tag id.
 */
export const editedTagIdVar = makeVar<string>('');
