import { Box } from '@components/atoms/Box/Box';
import { AuthPageWrapper } from '@components/ions/Pages.styled';
import { LoginForm } from '@components/organisms/LoginForm/LoginForm';
import { AuthLayout } from '@components/templates/AuthLayout/AuthLayout';

export const LoginPage = () => {
    return (
        <AuthLayout>
            <AuthPageWrapper>
                <Box title={'Log in'}>
                    <LoginForm />
                </Box>
            </AuthPageWrapper>
        </AuthLayout>
    );
};
