import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

export const FormWrapper = styled.form``;

export const FieldRow = styled.div<{ isLastQuestion?: boolean }>(
    ({ isLastQuestion }) => css`
        margin-bottom: ${isLastQuestion ? 0 : 16}px;
    `,
);

export const ButtonRow = styled.div`
    margin-top: 24px;
`;

export const DividedFieldRow = styled(FieldRow)`
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
`;

export const FieldsDivided = styled.div<{
    alignItems?: CSSProperties['alignItems'];
    gap?: CSSProperties['gap'];
    gridTemplateColumns?: CSSProperties['gridTemplateColumns'];
}>(
    ({ alignItems, gap, gridTemplateColumns }) => css`
        align-items: ${alignItems ?? 'flex-start'};
        display: grid;
        gap: ${gap ?? '16px 32px'};
        grid-template-columns: ${gridTemplateColumns ?? '1fr 1fr'};
        width: 100%;
    `,
);

export const PasswordFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
