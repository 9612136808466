import { ReactNode } from 'react';

import { BoxTitle, BoxWrapper } from './Box.styled';

interface BoxProps {
    children?: ReactNode;
    className?: string;
    title: string;
}

export const Box = ({ children, className, title }: BoxProps) => {
    return (
        <BoxWrapper className={className}>
            <BoxTitle>{title}</BoxTitle>
            {children}
        </BoxWrapper>
    );
};
