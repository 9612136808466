import { blockedDomainsHeaderData } from './BlockedDomainsCard.settings';
import { useBlockedDomainsCard } from './useBlockedDomainsCard';

import {
    AddButton,
    Card,
    CardTableEmptyState,
    SecondaryManualTable,
    Switch,
    TableCell,
} from '@speeki/global-ui-components';
import { useModal } from '@speeki/react-hooks';

import { TableActionButton } from '@components/atoms/TableActionButton/TableActionButton';

import { ActionsWrapper } from '@domains/settings/ions/Table.styled';
import { BlockedDomainsUpsertModal } from '@domains/settings/organisms/BlockedDomains/BlockedDomainsUpsertModal/BlockedDomainsUpsertModal';

export const BlockedDomainsCard = () => {
    const {
        blockedDomainData,
        blockedDomainsField,
        getTableData,
        handleSave,
        isDirty,
        isValid,
        loading,
        noData,
        reset,
        searchPhrase,
        setEditedUuid,
        setSearchPhrase,
        tableData,
        totalCount,
        upsertLoading,
    } = useBlockedDomainsCard();

    const blockedDomainsModal = useModal();
    const handleOpenModal = () => blockedDomainsModal.setOpen(true);

    return (
        <>
            <Card
                footer={{
                    cancelButton: {
                        disable: upsertLoading,
                        testId: '5Kdl-46',
                    },
                    isVisible: isDirty,
                    onCancel: () => reset(),
                    onSave: void handleSave,
                    saveButton: {
                        disable: !isValid,
                        loading: upsertLoading,
                        testId: 'sz7VurA',
                    },
                }}
                header={{
                    actionButton: (
                        <AddButton
                            disabled={loading || upsertLoading}
                            onClick={handleOpenModal}
                            testId="c0xnOUl"
                        />
                    ),
                    description:
                        'Manage a list of restricted email domains. Users attempting to register with email addresses ending with those domains will be unable to access the Speeki platform.',
                    title: 'Blocked domains',
                }}
                isEmpty={noData}
                onSearch={setSearchPhrase}
            >
                <SecondaryManualTable
                    columns={blockedDomainsHeaderData}
                    data={tableData}
                    pagination={{
                        options: { totalCount },
                    }}
                    tableRow={{
                        additionalCells: ({ id }) => {
                            const currentDomain =
                                blockedDomainsField.field.value.find(
                                    ({ id: domainId }) => domainId === id,
                                );

                            const actionButtonDisabled = !tableData?.find(
                                ({ id: domainId }) => domainId === id,
                            )?.domain.enabled;

                            return (
                                <TableCell
                                    key={'actions'}
                                    paddingRight={'0'}
                                    testId="MwQw-7W"
                                    width={'1%'}
                                >
                                    <ActionsWrapper>
                                        <TableActionButton
                                            disabled={actionButtonDisabled}
                                            icon={'SvgEdit24'}
                                            onClick={() => {
                                                setEditedUuid(id);
                                                handleOpenModal();
                                            }}
                                        />
                                        <Switch
                                            checked={!!currentDomain?.enabled}
                                            onChange={() =>
                                                blockedDomainsField.field.onChange(
                                                    blockedDomainsField.field.value?.map(
                                                        (item) =>
                                                            item.id ===
                                                            currentDomain?.id
                                                                ? {
                                                                      ...item,
                                                                      enabled:
                                                                          !currentDomain?.enabled,
                                                                  }
                                                                : item,
                                                    ),
                                                )
                                            }
                                            testId="jCwMoA6"
                                        />
                                    </ActionsWrapper>
                                </TableCell>
                            );
                        },
                        testId: 'iniC72u',
                    }}
                    tableState={{
                        emptySearchState: <CardTableEmptyState />,
                        getTableData,
                        loading,
                        searchPhrase,
                    }}
                    testId="sFCHUOU"
                />
            </Card>

            {blockedDomainsModal.open && (
                <BlockedDomainsUpsertModal
                    blockedDomainData={blockedDomainData}
                    onCancel={() => setEditedUuid(undefined)}
                    {...blockedDomainsModal}
                />
            )}
        </>
    );
};
