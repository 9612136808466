import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const CardDataRowElement = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 204px 1fr;
    margin-bottom: 8px;
`;

export const RowName = styled(Typography.TextBody_16_24)(
    ({ theme: { colors } }) => css`
        color: ${colors.text_secondary};
    `,
);

export const RowValue = styled(Typography.TextBody_16_24)<{ noData?: boolean }>(
    ({ noData, theme: { colors } }) => css`
        color: ${noData ? colors.text_assistive : colors.text_primary};

        span {
            color: ${colors.text_assistive};
        }
    `,
);
