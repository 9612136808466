import { Column } from 'react-table';

import { GroupTag, Tag } from '@speeki/global-ui-components';

import { TagDescription } from './TagsTable.styled';

export interface GroupTagTableRow {
    groupTag: {
        colorPrimary: string;
        colorSecondary: string;
        id: string;
        isPublic: boolean;
        name: string;
    };
    id: string;
    riskArea: string | undefined;
    tag: { color: string; id: string; isPublic: boolean; name: string };
}

interface HeaderDataProps {
    onGroupTagEdit: () => void;
    onTagEdit: () => void;
    setGroupTagEditedId: (id: string) => void;
    setTagEditedId: (id: string) => void;
}

export const getHeaderData = ({
    onGroupTagEdit,
    onTagEdit,
    setGroupTagEditedId,
    setTagEditedId,
}: HeaderDataProps): Column<GroupTagTableRow>[] => [
    { Header: 'Topic', accessor: 'riskArea' },
    {
        Cell: ({ value: { colorPrimary, colorSecondary, id, name } }) => {
            return (
                <GroupTag
                    groupTagBackgroundColor={colorSecondary}
                    groupTagName={name}
                    groupTagNameColor={colorPrimary}
                    onEdit={() => {
                        onGroupTagEdit();
                        setGroupTagEditedId(id);
                    }}
                    testId="Q2dsp2b"
                />
            );
        },
        Header: 'Group tag name',
        accessor: 'groupTag',
    },
    {
        Cell: ({ value: { color, id, name } }) => {
            return name ? (
                <Tag
                    onEdit={() => {
                        onTagEdit();
                        setTagEditedId(id);
                    }}
                    tagColor={color}
                    tagName={name}
                    testId="7BSFib7"
                />
            ) : (
                <TagDescription>No tag added</TagDescription>
            );
        },
        Header: 'Tag',
        accessor: 'tag',
    },
];
