import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { resolveEnv } from '@speeki/frontend-utils';
import {
    Button,
    Modal,
    ModalComponent,
    notify,
} from '@speeki/global-ui-components';
import { UseModal } from '@speeki/react-hooks';

import {
    ChangeTenantStatusDocument,
    TenantDeletionDocument,
} from '@graphql/generated/graphql';

import { useMutation } from '@apollo/client';
import { DeletionChoiceWrapper } from '@domains/clients/organisms/DeactivateTenantModal/DeactivateTenant.modal.styled';
import { INFO_MESSAGES } from '@shared/notifyMessages';
import { internalPaths } from '@shared/paths';

interface DeactivateTenantModalProps extends UseModal {
    tenantName: string;
    tenantUuid: string;
}

export const DeactivateTenantModal = ({
    open,
    setOpen,
    tenantName,
    tenantUuid,
}: DeactivateTenantModalProps) => {
    const navigate = useNavigate();
    const [deletionTenant, setDeletionTenant] = useState(false);

    const [changeTenantStatus, { loading: loadingChange }] = useMutation(
        ChangeTenantStatusDocument,
        {
            context: { notifyOnError: true },
            onCompleted: () =>
                notify(INFO_MESSAGES.TENANT_DEACTIVATION(tenantName)).INFO(),
        },
    );

    const [deleteTenant, { loading: loadingDelete }] = useMutation(
        TenantDeletionDocument,
        {
            context: { notifyOnError: true },
            onCompleted: () => {
                notify(INFO_MESSAGES.TENANT_DELETION(tenantName)).INFO();
                navigate(internalPaths.clients);
            },
        },
    );

    const loading = loadingChange || loadingDelete;

    const tenantDeletionDays =
        resolveEnv('REACT_APP_TENANT_PERMANENT_DELETION_DAYS') ?? 30;

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        if (deletionTenant) {
            void deleteTenant({
                variables: { input: { tenantName, tenantUuid } },
            });
        } else {
            void changeTenantStatus({
                variables: {
                    disableTaskManager: false,
                    input: { isActive: false, tenantUuid },
                },
            });
        }

        handleClose();
    };

    return (
        <ModalComponent onClose={handleClose} open={open} width={688}>
            <Modal.Wrapper>
                <Modal.Content>
                    <Modal.TitleSecondary>Are you sure?</Modal.TitleSecondary>
                    <Modal.SubTitle>
                        Are you sure you want to deactivate this tenant? You can
                        choose whether to have the tenant data deleted in{' '}
                        {tenantDeletionDays} days or delete all data
                        immediately.
                    </Modal.SubTitle>
                    <DeletionChoiceWrapper>
                        <Button
                            active={!deletionTenant}
                            fullWidth
                            onClick={() => setDeletionTenant(false)}
                            size={'small'}
                            testId="lP_YfWk"
                            variant={'radio'}
                        >
                            Delete tenant data in {tenantDeletionDays} days
                        </Button>
                        <Button
                            active={deletionTenant}
                            fullWidth
                            onClick={() => setDeletionTenant(true)}
                            size={'small'}
                            testId="4KISNRF"
                            variant={'radio'}
                        >
                            Delete tenant data immediately
                        </Button>
                    </DeletionChoiceWrapper>
                </Modal.Content>
                <Modal.Footer>
                    <Modal.ButtonsWrapper>
                        <Button
                            onClick={handleClose}
                            size={'small'}
                            testId="EgsrjVG"
                            variant={'secondary'}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={loading}
                            isLoading={loading}
                            onClick={handleSubmit}
                            size={'small'}
                            testId="DXltdBb"
                            variant={'primary'}
                        >
                            Confirm
                        </Button>
                    </Modal.ButtonsWrapper>
                </Modal.Footer>
            </Modal.Wrapper>
        </ModalComponent>
    );
};
