import { css } from 'styled-components';

import GTWProMediumEotBold from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Bold.eot';
import GTWProMediumTtfBold from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Bold.ttf';
import GTWProMediumWoffBold from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Bold.woff';
import GTWProMediumWoff2Bold from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Bold.woff2';
import GTWProMediumEotRegular from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Regular.eot';
import GTWProMediumTtfRegular from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Regular.ttf';
import GTWProMediumWoffRegular from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Regular.woff';
import GTWProMediumWoff2Regular from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Regular.woff2';

import { resolveAssetUrl } from '@utils/helpers';

export const globalFonts = css`
    @font-face {
        font-family: 'GTWalsheimpro';
        font-weight: 400;
        src: url(${resolveAssetUrl(GTWProMediumEotRegular as string)});
        src:
            url('${resolveAssetUrl(GTWProMediumEotRegular as string)}#iefix')
                format('embedded-opentype'),
            url(${resolveAssetUrl(GTWProMediumWoff2Regular as string)})
                format('woff2'),
            url(${resolveAssetUrl(GTWProMediumWoffRegular as string)})
                format('woff'),
            url(${resolveAssetUrl(GTWProMediumTtfRegular as string)})
                format('truetype');
    }

    @font-face {
        font-family: 'GTWalsheimpro';
        font-weight: 700;
        src: url(${resolveAssetUrl(GTWProMediumEotBold as string)});
        src:
            url('${resolveAssetUrl(GTWProMediumEotBold as string)}#iefix')
                format('embedded-opentype'),
            url(${resolveAssetUrl(GTWProMediumWoff2Bold as string)})
                format('woff2'),
            url(${resolveAssetUrl(GTWProMediumWoffBold as string)})
                format('woff'),
            url(${resolveAssetUrl(GTWProMediumTtfBold as string)})
                format('truetype');
    }
`;
