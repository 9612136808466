import { Button, TextField } from '@speeki/global-ui-components';

import { ButtonRow, FieldRow } from '@components/ions/Form.styled';
import { usePasswordResetForm } from '@components/organisms/PasswordResetForm/usePasswordResetForm';

import { ResetPasswordFormWrapper } from './PasswordResetForm.styled';

export const PasswordResetForm = () => {
    const {
        errors,
        handleSubmit,
        isSubmitted,
        isSubmitting,
        isValid,
        loading,
        register,
    } = usePasswordResetForm();

    return (
        <ResetPasswordFormWrapper noValidate onSubmit={handleSubmit}>
            <FieldRow>
                <TextField
                    {...register('email')}
                    className="htmlToImageIgnore"
                    error={!!errors.email}
                    id={'reset-password-email'}
                    label={'Email address'}
                    message={errors.email?.message}
                    placeholder={'Enter business email address'}
                    testId="wBanlAw"
                    type={'email'}
                />
            </FieldRow>
            <ButtonRow>
                <Button
                    disabled={isSubmitting || (!isValid && isSubmitted)}
                    fullWidth
                    isLoading={loading}
                    testId="xezKcNX"
                    type={'submit'}
                    variant={'primary'}
                >
                    send
                </Button>
            </ButtonRow>
        </ResetPasswordFormWrapper>
    );
};
