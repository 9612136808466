import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const FooterTextWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    min-width: 474px;
`;

export const FooterText = styled(Typography.TextBody_14_20)(
    ({ theme: { palette } }) => css`
        color: ${palette.text.secondary};
    `,
);
