import { AdminUsersUpsertFormValues } from '../AdminUsersUpsertModal/useAdminUsersUpsertModal';
import { useMemo, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AdminUsersCardTableRow } from './AdminUsersCard.settings';

import { notify, QueryFilters } from '@speeki/global-ui-components';

import { AdminUserUpsertDocument } from '@graphql/generated/graphql';
import {
    AdminUserDataFragment,
    AdminUserDataFragmentDoc,
    AdminUsersDocument,
} from '@graphql/generated/graphql';

import { useMutation } from '@apollo/client';
import { useFragment, useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { SUCCESS_MESSAGES } from '@shared/notifyMessages';

export const useAdminUsersCard = (handleCloseDeactivationModal: () => void) => {
    const [editedUuid, setEditedUuid] = useState<string | undefined>();
    const [searchPhrase, setSearchPhrase] = useState<string | undefined>();

    const [getAdminUsers, { data: adminsData, loading: adminsLoading }] =
        useLazyQuery(AdminUsersDocument, {
            fetchPolicy: 'cache-and-network',
        });

    const [adminUserUpsert, { loading: upsertLoading }] = useMutation(
        AdminUserUpsertDocument,
        {
            context: {
                notifyOnError: true,
            },
            onCompleted: (data) => {
                const isUserDeactivation =
                    data.adminUserUpsert.status === 'INACTIVE';
                const adminName = `${data.adminUserUpsert.firstName ?? ''}
                    ${data.adminUserUpsert.lastName ?? ''}`;

                isUserDeactivation
                    ? notify(
                          SUCCESS_MESSAGES.ADMIN_USER_DEACTIVATE(adminName),
                      ).INFO()
                    : notify(
                          SUCCESS_MESSAGES.ADMIN_USER_REACTIVATE(adminName),
                      ).SUCCESS();

                handleCloseDeactivationModal();
                setEditedUuid(undefined);
            },
            onError: () => {},
        },
    );

    const { data: editedUser } = useFragment<AdminUserDataFragment>({
        fragment: AdminUserDataFragmentDoc,
        fragmentName: 'AdminUserData',
        from: `AdminUserData:${editedUuid}`,
    });

    const { nodes, totalCount } = adminsData?.adminUsers || {};
    const noData = totalCount === 0 && typeof searchPhrase !== 'string';

    const tableData: AdminUsersCardTableRow[] = useMemo(
        () =>
            nodes?.map(({ email, firstName, lastName, status, uuid }) => ({
                id: uuid,
                email: {
                    enabled: status === 'ACTIVE',
                    text: email,
                },
                firstName: {
                    enabled: status === 'ACTIVE',
                    text: firstName ?? '',
                },
                lastName: {
                    enabled: status === 'ACTIVE',
                    text: lastName ?? '',
                },
                status: status === 'ACTIVE',
            })) || [],
        [adminsData],
    );

    const formValues: AdminUsersCardValues = useMemo(
        () => ({
            adminUsers:
                nodes?.map((user) => ({
                    uuid: user.uuid,
                    email: user.email,
                    enabled: user.status === 'ACTIVE',
                    firstName: user.firstName ?? '',
                    lastName: user.lastName ?? '',
                })) || [],
        }),
        [adminsData],
    );

    const { control } = useForm<AdminUsersCardValues>({
        mode: 'onChange',
        resolver: yupResolver(AdminUsersCardSchema),
        values: formValues,
    });

    const adminUsersField = useController({
        control,
        name: 'adminUsers',
    });

    const editedUserData: AdminUsersUpsertFormValues | undefined =
        useMemo(() => {
            const { email, firstName, lastName, status, uuid } = editedUser;

            return !!editedUuid
                ? {
                      uuid,
                      email: email ?? '',
                      firstName: firstName ?? '',
                      lastName: lastName ?? '',
                      password: undefined,
                      passwordConfirm: undefined,
                      status: status === 'ACTIVE',
                  }
                : undefined;
        }, [editedUuid, editedUser]);

    const getTableData = ({ paging, searchPhrase }: QueryFilters) => {
        void getAdminUsers({
            variables: { input: { freeTextQuery: searchPhrase || '', paging } },
        });
    };

    const handleUserStatusChange = () => {
        if (!editedUser.uuid) return;

        void adminUserUpsert({
            variables: {
                input: {
                    uuid: editedUser.uuid,
                    firstName: editedUser.firstName ?? '',
                    lastName: editedUser.lastName ?? '',
                    status:
                        editedUser.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
                },
            },
        });
    };

    return {
        adminUsersField,
        editedUserData,
        getTableData,
        handleUserStatusChange,
        loading: adminsLoading,
        noData,
        searchPhrase,
        setEditedUuid,
        setSearchPhrase,
        tableData,
        totalCount: totalCount ?? 0,
        upsertLoading,
        userStatus: editedUser.status,
    };
};
const AdminUsersCardSchema = yup.object({
    adminUsers: yup
        .array()
        .of(
            yup.object({
                uuid: yup.string().required(),
                email: yup.string().required(),
                enabled: yup.boolean().required(),
                firstName: yup.string().required(),
                lastName: yup.string().required(),
            }),
        )
        .required(),
});

type AdminUsersCardValues = yup.InferType<typeof AdminUsersCardSchema>;
